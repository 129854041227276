import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { cn } from "../../lib/utils";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => {
  if (!props.min || !props.max || !props.value) {
    throw new Error(
      "Slider component requires min, max, and value props to be defined",
    );
  }
  return (
    <SliderPrimitive.Root
      className={cn(
        "relative flex w-full touch-none select-none items-center",
        className,
      )}
      ref={ref}
      {...props}
    >
      <SliderPrimitive.Track
        className="relative h-3 w-full grow overflow-hidden rounded-full"
        style={{
          backgroundImage: `var(--report-concentration-gradient)`,
        }}
      />
      <SliderPrimitive.Thumb className="border-secondary bg-background ring-offset-background focus-visible:ring-ring block h-4 w-4 rounded-full border-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
      <div className="absolute left-1 top-[50%] flex flex-col items-center">
        <div className="mt-[6px] h-[14px] w-[2px] bg-black" />
        <span>{props.min}</span>
      </div>
      <div className="absolute right-[-1px] top-[50%] flex flex-col items-center">
        <div className="mt-[6px] h-[14px] w-[2px] bg-black" />
        <span>{props.max}</span>
      </div>
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider as ConcentrationSlider };
