import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Badge } from "../../../../../components/ui/badge";
import { SimpleTable } from "../../../../../components/ui/simple-table";
import type { OrganizationProfile } from "../../hooks";

export type Kit = OrganizationProfile["configuration"]["kits"][0];

const columnHelper = createColumnHelper<Kit>();
const columns = [
  columnHelper.accessor("label", {
    cell: (info) => info.getValue(),
    header: "Kit name",
  }),
  columnHelper.accessor("maximumMersError", {
    cell: (info) => info.getValue(),
    header: "Maximum mers error",
  }),
  columnHelper.accessor("maximumMersWarning", {
    cell: (info) => info.getValue(),
    header: "Maximum mers warning",
  }),
  columnHelper.accessor("minimumMersError", {
    cell: (info) => info.getValue(),
    header: "Minimum mers error",
  }),
  columnHelper.accessor("minimumMersWarning", {
    cell: (info) => info.getValue(),
    header: "Minimum mers warning",
  }),
  columnHelper.accessor("maximumModifiersPlate", {
    cell: (info) => info.getValue(),
    header: "Max modifiers per plate",
  }),
  columnHelper.accessor("maximumModifiersWell", {
    cell: (info) => info.getValue(),
    header: "Max modifiers per well",
  }),
  columnHelper.accessor("allowedProcesses", {
    cell: (info) => {
      return (
        <div className="space-x-2 space-y-2">
          {info.getValue().map((process) => (
            <Badge key={process.id} variant={"outline"}>
              {process.label}
            </Badge>
          ))}
        </div>
      );
    },
    header: "Available processes",
  }),
];

export default function OrganizationKitConfiguration({
  kits,
}: {
  kits: Kit[];
}) {
  const table = useReactTable({
    columns,
    data: kits,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <h3>Kits</h3>
      <SimpleTable table={table} />
    </div>
  );
}
