import { Box, Flex, Heading } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

import UserList from "./users-list";

import { Button } from "../../../components/ui/button";
import { useUser } from "../../../containers/hooks";
import { AdminRoutes } from "../adminRoutes";

const NewUserButton = () => {
  const user = useUser();
  const navigate = useNavigate();
  if (!user) {
    return null;
  }
  if (!user.isAdmin) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        navigate(AdminRoutes.NEW_USER);
      }}
    >
      New user
    </Button>
  );
};

export default function Users() {
  return (
    <Flex className="flex flex-col" gap={"2"}>
      <Box className="flex justify-between">
        <Heading>Users</Heading>
        <NewUserButton />
      </Box>
      <UserList />
    </Flex>
  );
}
