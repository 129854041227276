import { Heading } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

import OrganizationList from "./organizations-list";

import { Button } from "../../../components/ui/button";
import { useUser } from "../../../containers/hooks";
import { AdminRoutes } from "../adminRoutes";

const NewOrganizationButton = () => {
  const navigate = useNavigate();
  const user = useUser();
  if (!user) {
    return null;
  }
  if (!user.isAdmin) {
    return null;
  }

  return (
    <Button
      onClick={() => {
        navigate(AdminRoutes.NEW_ORGANIZATION);
      }}
    >
      New organization
    </Button>
  );
};

export default function Organizations() {
  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <Heading>Organizations</Heading>
        <NewOrganizationButton />
      </div>
      <OrganizationList />
    </div>
  );
}
