import { Link } from "@radix-ui/themes";

import { useEnvironmentContext } from "../../containers/contexts";

export default function Footer() {
  const { version, environment } = useEnvironmentContext();

  return (
    <footer className="z-50 flex h-8 w-full items-center justify-center space-x-4 border-b bg-slate-200 text-xs backdrop-blur">
      <p className="space-x-1">
        <Link
          href="https://www.dnascript.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="text-primary">{`© ${new Date().getFullYear()} DNA Script`}</span>
        </Link>
        <span>All Rights Reserved.</span>
      </p>
      <Link
        href="/Terms_and_Conditions.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="text-blue-600 visited:text-purple-600">
          Terms of Use
        </span>
      </Link>
      <Link
        href="/Privacy_Policy.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className="text-blue-600 visited:text-purple-600">
          Privacy Policy
        </span>
      </Link>
      <span className="absolute right-10">{`Version: ${version} | Environment: ${environment}`}</span>
    </footer>
  );
}
