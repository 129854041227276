import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FileEdit, Lock } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { AssayConstructRowActions } from "./assay-construct-actions";

import { SimpleTable } from "../../../../../components/ui/simple-table";
import { trpc } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";
import { CONSTRUCT_TYPE_TO_LABEL } from "../../construct/components/constants";
import type { ConstructListFromTRPC } from "../../construct/constructTypes";

const columnHelper = createColumnHelper<ConstructListFromTRPC>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("constructType", {
    cell: (info) => CONSTRUCT_TYPE_TO_LABEL[info.getValue()],
    header: "Type",
  }),
  columnHelper.display({
    // TODO handle lock of the underlying construct
    cell: () =>
      false ? (
        <p className="flex flex-row items-center space-x-1">
          <Lock />
          <span>Locked</span>
        </p>
      ) : (
        <p className="flex flex-row items-center space-x-1">
          <FileEdit />
          <span>Editable</span>
        </p>
      ),
    header: "Locked ?",
  }),
  columnHelper.display({
    cell: ({ row: { original } }) => (
      <AssayConstructRowActions row={original} />
    ),
    id: "actions",
  }),
];

export const AssayConstructs = ({ assayId }: { assayId: string }) => {
  const navigate = useNavigate();

  const { data: constructs } = trpc.construct.listInAssay.useQuery({
    assayId,
  });

  const table = useReactTable({
    columns,
    data: constructs ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <SimpleTable
      onRowClick={(row) => {
        navigate(
          OrganizationRoutes.CONSTRUCT.replace(":constructId", row.original.id),
        );
      }}
      table={table}
    />
  );
};
