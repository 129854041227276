import { z } from "zod";

export enum ConstructDownloadFormat {
  CSV = "CSV",
  FASTA = "FASTA",
}

export enum ConstructType {
  Gene = "Gene",
  OligoSet = "OligoSet",
}

export const geneSequenceSchema = z.object({
  root: z.object({
    children: z.array(
      z.object({
        children: z.array(
          z.object({
            style: z.string(),
            text: z.string(),
          }),
        ),
      }),
    ),
  }),
});

export type GeneState = z.infer<typeof geneSequenceSchema>;

export function convertSequenceToGeneState(sequence: string): GeneState {
  return geneSequenceSchema.parse({
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: sequence,
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  });
}

const geneSchema = z
  .object({
    sequence: z.string(),
    vector: z
      .object({
        insertPosition: z.number(),
        sequence: z.string(),
      })
      .optional(),
  })
  .refine(
    (data) => {
      const parseResult = geneSequenceSchema.safeParse(
        JSON.parse(data.sequence),
      );
      return parseResult.success;
    },
    {
      message: "Invalid gene sequence",
    },
  );

const oligosSchema = z.array(
  z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    placement: z.string().optional().nullable(),
    sequence: z.string(),
  }),
);

const baseConstructSchemaMutation = z.object({
  description: z.string().default("").optional(),
  gene: geneSchema.optional(),
  name: z.string(),
  oligos: oligosSchema.optional(),
  tags: z.array(z.string()).optional(),
});

export const createConstructSchema = baseConstructSchemaMutation.merge(
  z.object({
    assayId: z.string().optional().nullable(),
  }),
);

export type CreateConstructInput = z.infer<typeof createConstructSchema>;

export const updateConstructMetadataSchema = z.object({
  constructId: z.string(),
  description: z.string().optional(),
  name: z.string().optional(),
  tags: z.array(z.string()).optional(),
});

export type UpdateConstructMetadataInput = z.infer<
  typeof updateConstructMetadataSchema
>;

export const updateConstructOligosSchema = z.object({
  constructId: z.string(),
  oligos: oligosSchema,
});

export type UpdateConstructOligosInput = z.infer<
  typeof updateConstructOligosSchema
>;

export const updateConstructGeneSchema = z.object({
  constructId: z.string(),
  gene: geneSchema,
});

export type UpdateConstructGeneInput = z.infer<
  typeof updateConstructGeneSchema
>;
