import { ModificationStatus } from "@backend/config/db/types";

export const ModificationStatusToLabel: Record<ModificationStatus, string> = {
  [ModificationStatus.Acceptable]: "Acceptable Coupling Efficiency",
  [ModificationStatus.Low]: "Low Coupling Efficiency",
  [ModificationStatus.NonQuantifiable]: "Non Quantifiable",
  [ModificationStatus.None]: "No Modification",
  [ModificationStatus.Successful]: "Successful Coupling Efficiency",
};

export enum ReagentGroups {
  Nucleotides = "NEZ",
  PostSynthesisProcess = "PSP",
  Synthesis = "SYN",
  Unknown = "Unknown",
  Wash = "WSH",
}
export const ReagentGroupToTitle: Record<string, string> = {
  [ReagentGroups.Nucleotides]: "Nucleotides",
  [ReagentGroups.PostSynthesisProcess]: "Post Synthesis Process",
  [ReagentGroups.Synthesis]: "Synthesis",
  [ReagentGroups.Unknown]: "Unknown",
  [ReagentGroups.Wash]: "Wash",
};
