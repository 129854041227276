import { trpc } from "../../../../config/trpc";

export const useStartRun = (runId: number | undefined) => {
  const { mutate: startRunMutation, isPending } =
    trpc.assay.steps.plate.run.start.useMutation();

  const startRun = () => {
    if (!runId) {
      return;
    }
    startRunMutation({ id: runId });
  };

  return { isPending, startRun };
};
