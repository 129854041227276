import { LogOut } from "lucide-react";

import { useLogout } from "./useLogout";

import { MenubarItem } from "../../../components/ui/menubar";

export const LogoutItem = () => {
  const logout = useLogout();

  return (
    <MenubarItem
      className="cursor-pointer justify-between space-x-2"
      onClick={logout}
    >
      <span>Log out</span>
      <LogOut />
    </MenubarItem>
  );
};
