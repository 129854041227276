import { Link, Section, Text } from "@react-email/components";

export default function Signature() {
  return (
    <Section>
      <Text>Thank you.</Text>
      <Text>Empowered by DNA Script</Text>
      <Link href="https://www.dnascript.com/">dnascript.com</Link>
    </Section>
  );
}
