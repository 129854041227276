import { Input } from "@frontend/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@frontend/components/ui/table";
import type {
  ColumnDef,
  Row,
  RowData,
  SortingState,
  TableMeta,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";

import { DataTablePagination } from "./data-table-pagination";
import { DataTableViewOptions } from "./data-table-view-options";
import useStoreDataTableState from "./useStoreDataTableState";

import { cn } from "../../../lib/utils";
import { LoadingSpinner } from "../spinner";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    SelectionComponent?: React.ComponentType<{ row: Row<TData> }>;
    booleanFilter?: boolean;
    defaultFilter?: unknown;
    title?: string;
    uniqueFilter?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    columnsFiltered?: string[];
    isOligoSet?: boolean;
    toggleFilterColumn?: (columnId: string) => void;
  }
}

export function DataTable<TData, TValue>({
  columns,
  data,
  loading,
  defaultSorting,
  disableQueryParams = false,
  onRowClick,
  onSelectionChange,
  tableContainerClassName,
  meta,
  resetSelection,
  enableRowSelection = undefined,
  RowComponent = TableRow,
  useBorders = true,
  usePagination = true,
}: {
  RowComponent?: typeof TableRow;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  defaultSorting?: SortingState;
  disableQueryParams?: boolean;
  enableRowSelection?: boolean | ((row: Row<TData>) => boolean) | undefined;
  loading?: boolean;
  meta?: TableMeta<TData> | undefined;
  onRowClick?: (row: Row<TData>) => void;
  onSelectionChange?: (value: { id: string }[]) => void;
  resetSelection?: number;
  tableContainerClassName?: string;
  useBorders?: boolean;
  usePagination?: boolean;
}) {
  const [rowSelection, setRowSelection] = useState({});
  useEffect(() => {
    if (resetSelection) {
      setRowSelection({});
    }
  }, [resetSelection]);

  const {
    pagination,
    setPaginationFn,
    visibility: columnVisibility,
    setVisibilityFn,
    sorting,
    setSortingFn,
    columnsFiltered,
    setColumnsFilteredFn,
    columnFilters,
    setColumnFiltersFn,
    globalFilter,
    setGlobalFilterFn,
  } = useStoreDataTableState(meta?.isOligoSet ?? false, !disableQueryParams);

  useEffect(() => {
    if (!defaultSorting) {
      return;
    }
    setSortingFn(defaultSorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table = useReactTable({
    columns,
    data,
    enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: usePagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
    meta: {
      columnsFiltered,
      toggleFilterColumn(columnId) {
        const newColumnsFiltered = columnsFiltered.includes(columnId)
          ? columnsFiltered.filter((id) => id !== columnId)
          : [...columnsFiltered, columnId];
        setColumnsFilteredFn(newColumnsFiltered);
      },
      ...(meta || {}),
    },
    onColumnFiltersChange: (value) => {
      const newFilters =
        typeof value === "function" ? value(columnFilters) : value;
      setColumnFiltersFn(newFilters);
    },
    onColumnVisibilityChange: (value) => {
      const newVisibility =
        typeof value === "function" ? value(columnVisibility) : value;
      setVisibilityFn(newVisibility);
    },
    onGlobalFilterChange: (value) => {
      setGlobalFilterFn(value);
    },
    onPaginationChange: (value) => {
      const newPagination =
        typeof value === "function" ? value(pagination) : value;
      setPaginationFn(newPagination);
    },
    onRowSelectionChange: (value) => {
      const newRowSelection =
        typeof value === "function" ? value(rowSelection) : value;
      onSelectionChange?.(
        Object.entries(newRowSelection)
          .filter(([, value]) => value)
          .map(([key]) => ({ id: key })),
      );
      setRowSelection(newRowSelection);
    },
    onSortingChange: (value) => {
      const newSorting = typeof value === "function" ? value(sorting) : value;
      setSortingFn(newSorting);
    },
    state: {
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination,
      rowSelection,
      sorting,
    },
  });

  if (loading) {
    return (
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div
      className={cn("bg-card", useBorders && "rounded-md border p-4 shadow-md")}
    >
      <div className="flex items-center space-x-2 py-4">
        <Input
          className="max-w-sm"
          onChange={(event) => table.setGlobalFilter(event.target.value)}
          placeholder="Filter..."
          value={globalFilter as string}
        />
        <DataTableViewOptions table={table} />
      </div>
      <div className={cn(tableContainerClassName, loading && "opacity-50")}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={
                        header.getSize() !== 150 // default column size
                          ? {
                              width: header.getSize(),
                            }
                          : {}
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <RowComponent
                  className={onRowClick ? "cursor-pointer" : ""}
                  data-state={row.getIsSelected() && "selected"}
                  key={row.id}
                  {...(onRowClick ? { onClick: () => onRowClick(row) } : {})}
                  original={row.original}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </RowComponent>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {usePagination && (
        <div className="py-4">
          <DataTablePagination table={table} />
        </div>
      )}
    </div>
  );
}
