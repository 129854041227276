import { ZoomIn, ZoomOut } from "lucide-react";
import { useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";

import { Button } from "../../../../../components/ui/button";
import { Slider } from "../../../../../components/ui/slider";

const ZOOM_STEP = 0.1;
const DEFAULT_ZOOM = 1;
const MAX_ZOOM = 3;
const MIN_ZOOM = 1;

export default function useZoom() {
  const [localZoom, setLocalZoom] = useState<number>(1);
  const [zoom, setZoom] = useQueryParam("zoom", NumberParam);

  const applyNewZoom = (newZoom: number) => {
    setZoom(newZoom);
    setLocalZoom(newZoom);
  };

  const zoomElement = (
    <div className="flex flex-row items-center space-x-1">
      <Button
        onClick={() => applyNewZoom(Math.max(MIN_ZOOM, localZoom - ZOOM_STEP))}
        size={"icon"}
        variant={"ghost"}
      >
        <ZoomOut />
      </Button>
      <Slider
        className="min-w-[100px]"
        defaultValue={[DEFAULT_ZOOM]}
        max={MAX_ZOOM}
        min={MIN_ZOOM}
        onValueChange={(v) => {
          applyNewZoom(v[0]);
        }}
        onValueCommit={(v) => {
          setLocalZoom(v[0]);
        }}
        step={ZOOM_STEP}
        value={[localZoom]}
      />
      <Button
        onClick={() => {
          applyNewZoom(Math.min(MAX_ZOOM, localZoom + ZOOM_STEP));
        }}
        size={"icon"}
        variant={"ghost"}
      >
        <ZoomIn />
      </Button>
    </div>
  );

  return { zoom, zoomElement };
}
