import { createContext, useEffect, useMemo, useState } from "react";

import { IS_DEVELOPMENT } from "../config";
import {
  deleteAuthToken,
  getIsTokenExpired,
  loadAuthToken,
  saveAuthToken,
} from "../state/localStorage";
import { parseJwt } from "../utils/auth";

export const AuthContext = createContext<Auth>({
  parsedToken: null,
  setToken: () => {},
  token: null,
});

export const tokenName = "DNAS_TOKEN";

export type JWTPayload = {
  email: string;
  flags: Record<string, boolean>;
  isOrganizationMember: boolean;
  mfaEnabled: boolean;
  mfaValidated: boolean;
  organizationId?: string;
  organizations?: { id: string; name: string }[];
  shouldResetPassword: boolean;
  sub: string;
};

export type Auth = {
  parsedToken: JWTPayload | null;
  setToken: (token: string | null) => void;
  token: string | null;
};

const AuthProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const [token, setToken] = useState<string | null>(
    () => loadAuthToken() ?? null,
  );
  const setTokenValue = (token: string | null) => {
    if (token) {
      saveAuthToken(token);
    } else {
      deleteAuthToken();
    }
    setToken(token);
  };

  const isTokenExpired = token && getIsTokenExpired(token);

  useEffect(() => {
    if (isTokenExpired) {
      setToken(null);
      deleteAuthToken();
    }
  }, [isTokenExpired]);

  const parsedToken = useMemo(() => {
    if (!token) return null;
    return parseJwt(token);
  }, [token]);

  const containerValue = useMemo(
    () => ({
      parsedToken,
      setToken: setTokenValue,
      token,
    }),
    [parsedToken, token],
  );

  useEffect(() => {
    if (!IS_DEVELOPMENT) return;
    // eslint-disable-next-line no-console
    console.log(`{"Authorization": "Bearer ${token}"}`);
  }, [token]);

  return (
    <AuthContext.Provider value={containerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
