import { AlertTriangle, CheckCircle, XCircle } from "lucide-react";

export const CheckCircleGreen = () => (
  <CheckCircle className="text-green-500" />
);

export const CrossCircleRed = () => <XCircle className="text-gray-500" />;

export const CheckIfTrueElseCross = ({ value }: { value: boolean }) =>
  value ? <CheckCircleGreen /> : <CrossCircleRed />;

export const CheckIfTrueElseWarn = ({ value }: { value: boolean }) =>
  value ? <CheckCircleGreen /> : <AlertTriangle className="text-orange-500" />;
