import type { Direction } from "@backend/bioinformatics/tools/homology";
import type {
  ScreeningOrderResponse,
  ScreeningSequenceType,
} from "@backend/config/screeningOrder.consumer";
import { Badge } from "@frontend/components/ui/badge";
import { Label } from "@frontend/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@frontend/components/ui/select";
import { Separator } from "@frontend/components/ui/separator";
import { LoadingSpinner } from "@frontend/components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@frontend/components/ui/tooltip";
import { Card } from "@radix-ui/themes";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { Borders, Style } from "exceljs";
import Excel from "exceljs";
import { Download, MoveLeft, MoveRight } from "lucide-react";
import type { SyntheticEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import type { Row } from "read-excel-file";
import readXlsxFile from "read-excel-file";
import { v4 } from "uuid";

import { CheckIfTrueElseCross } from "./admin/organizations/components/ui";
import { PurityFlagToName } from "./organization/pages/build/components/plate/constants";
import { SmallColoredDot } from "./organization/pages/build/components/plate/sidebarSingleWell";
import { getColorsFromPurity } from "./organization/pages/build/components/plate/useGetWellBackgroundColor";

import { Button } from "../components/ui/button";
import { CardHeader, CardTitle, CardContent } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { SimpleTable } from "../components/ui/simple-table";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { cn } from "../lib/utils";

export type ScreenResponse = ScreeningOrderResponse;

const columnHelper = createColumnHelper<
  ScreenResponse[number] & { name: string; sequence: string }
>();

type SequenceRow = {
  name: string;
  sequence: string;
  well: string;
};

export default function Screening() {
  const [sequenceType, setSequenceType] =
    useState<ScreeningSequenceType | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [is384, setIs384] = useState(false);
  const [fileRows, setFileRows] = useState<SequenceRow[]>([]);
  const [response, setResponse] = useState<ScreenResponse | null>(null);
  const [requestId] = useState<string>(() => v4());
  const [isBackgroundJobRunning, setIsBackgroundJobRunning] = useState(false);

  const screeningColumns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Well",
      }),
      columnHelper.accessor("name", {
        header: "Sequence name",
      }),
      columnHelper.accessor("sequence", {
        cell: (info) => info.getValue().length,
        header: "Sequence length",
      }),
      columnHelper.accessor("valid", {
        cell: (info) => <CheckIfTrueElseCross value={info.getValue()} />,
        header: "Biosecurity",
      }),
      columnHelper.accessor("reason", {
        cell: (info) => info.row.original.reason ?? "-",
        header: "Biosecurity reason",
      }),
      ...(sequenceType === "oligos"
        ? [
            columnHelper.accessor("purity.flag", {
              cell: (info) => {
                if (!("purity" in info.row.original)) {
                  return;
                }
                const flag = info.row.original.purity.flag;
                const { background } = getColorsFromPurity(flag);
                return (
                  <p className="col-span-1 flex items-center space-x-1">
                    <SmallColoredDot className={`${background}`} />
                    <span>{PurityFlagToName[flag]}</span>
                  </p>
                );
              },
              header: "Purity flag",
            }),
            columnHelper.accessor("purity.value", {
              cell: (info) => {
                if (!("purity" in info.row.original)) {
                  return;
                }
                return (info.row.original.purity.value * 100).toFixed(2) + "%";
              },
              header: "Purity predicted",
            }),
          ]
        : [
            columnHelper.accessor("geneComplexity", {
              cell: (info) => {
                if (!("geneComplexity" in info.row.original)) {
                  return;
                }
                return (info.row.original.geneComplexity * 100).toFixed(0);
              },
              header: "Complexity score",
            }),
          ]),
    ],
    [sequenceType],
  );

  const handleDownloadResults = async () => {
    if (!file || !response) {
      return;
    }
    const workbook = new Excel.Workbook();
    const fileBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(fileBuffer);

    const sheetWithOligos = workbook.getWorksheet(is384 ? 3 : 2);
    if (!sheetWithOligos) {
      return;
    }
    const rows = sheetWithOligos.getRows(3, (is384 ? 384 : 96) + 2);
    const wellIndexToRowIndex = new Map<string, number>();
    rows?.forEach((row, index) => {
      const wellIndex = row.getCell("B").value;
      wellIndexToRowIndex.set(wellIndex as string, index + 3);
    });

    function fillHeader(sheet: Excel.Worksheet) {
      const sheetRow = sheet.getRow(2);
      sheetRow.getCell("J").value = "Biosecurity check";
      sheetRow.getCell("K").value = "Reason ?";
      if (sequenceType === "oligos") {
        sheetRow.getCell("L").value = "Purity Estimate (%)";
        sheetRow.getCell("M").value = "Difficulty score";
      } else {
        sheetRow.getCell("L").value = "Complexity score";
      }
      sheetRow.commit();
    }
    function fillRows(sheet: Excel.Worksheet, response: ScreenResponse) {
      for (const row of response) {
        const rowIndex = wellIndexToRowIndex.get(row.id);
        if (!rowIndex) {
          continue;
        }
        const sheetRow = sheet.getRow(rowIndex);
        sheetRow.getCell("J").value = row.valid ? "Valid" : "Invalid";
        sheetRow.getCell("K").value = row.reason ?? "";
        if ("purity" in row) {
          sheetRow.getCell("L").value = (row.purity.value * 100).toFixed(1);
          sheetRow.getCell("M").value = PurityFlagToName[row.purity.flag];
        } else if ("geneComplexity" in row) {
          sheetRow.getCell("L").value = (row.geneComplexity * 100).toFixed(1);
        }
        sheetRow.commit();
      }
    }

    function setStyles(sheet: Excel.Worksheet, response: ScreenResponse) {
      const headerStyle = sheet.getRow(2).getCell("G").style;
      const bodyStyle = sheet.getRow(3).getCell("G").style;
      const singleBorderStyleHeader = headerStyle?.border?.bottom;
      if (!singleBorderStyleHeader || !singleBorderStyleHeader) {
        throw new Error("No border style found");
      }
      const headerLeftBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        left: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerRightBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        right: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerMiddleBorder: Partial<Borders> = {
        bottom: singleBorderStyleHeader,
        top: singleBorderStyleHeader,
      };
      const headerLeftStyle: Partial<Style> = {
        ...headerStyle,
        border: headerLeftBorder,
      };
      const headerMiddleStyle: Partial<Style> = {
        ...headerStyle,
        border: headerMiddleBorder,
      };
      const headerRightStyle: Partial<Style> = {
        ...headerStyle,
        border: headerRightBorder,
      };
      const bodyFont = {
        ...bodyStyle.font,
        bold: false,
      };
      const bodyLeftStyle: Partial<Style> = {
        ...bodyStyle,
        border: {
          left: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bodyMiddleStyle: Partial<Style> = {
        ...bodyStyle,
        border: {},
        font: bodyFont,
      };
      const bodyRightStyle: Partial<Style> = {
        ...bodyStyle,
        border: {
          right: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleMiddle: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleLeft: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
          left: singleBorderStyleHeader,
        },
        font: bodyFont,
      };
      const bottomStyleRight: Partial<Style> = {
        ...bodyStyle,
        border: {
          bottom: singleBorderStyleHeader,
          right: singleBorderStyleHeader,
        },
        font: bodyFont,
      };

      sheet.getColumn("L").numFmt = "0.00%";
      const sheetRow = sheet.getRow(2);
      sheetRow.getCell("J").style = headerLeftStyle;
      sheetRow.getCell("K").style = headerMiddleStyle;
      if (sequenceType === "oligos") {
        sheetRow.getCell("L").style = headerMiddleStyle;
        sheetRow.getCell("M").style = headerRightStyle;
      } else {
        sheetRow.getCell("L").style = headerRightStyle;
      }
      sheetRow.commit();
      for (const row of response) {
        const rowIndex = wellIndexToRowIndex.get(row.id);
        if (!rowIndex) {
          continue;
        }
        const sheetRow = sheet.getRow(rowIndex);
        const isEnd = rowIndex === (is384 ? 384 + 2 : 96 + 2);
        const styleMiddle = isEnd ? bottomStyleMiddle : bodyMiddleStyle;
        const styleLeft = isEnd ? bottomStyleLeft : bodyLeftStyle;
        const styleRight = isEnd ? bottomStyleRight : bodyRightStyle;
        sheetRow.getCell("J").style = styleLeft;
        sheetRow.getCell("K").style = styleMiddle;
        if (sequenceType === "oligos") {
          sheetRow.getCell("L").style = styleMiddle;
          sheetRow.getCell("M").style = styleRight;
        } else {
          sheetRow.getCell("L").style = styleRight;
        }
        sheetRow.commit();
      }
    }

    fillHeader(sheetWithOligos);
    fillRows(sheetWithOligos, response);
    setStyles(sheetWithOligos, response);

    sheetWithOligos.columns.forEach((column) => {
      if (!["J", "K", "L", "M"].includes(column?.letter ?? "")) {
        return;
      }
      const lengths = column?.values?.map((v) => v?.toString().length);
      const maxLength = Math.max(
        ...(lengths ?? []).filter((v) => typeof v === "number"),
      );
      column.width = maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${file.name.split(".xlsx")[0]}_screened.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const { toast } = useToast();
  const { mutate: screenMutation, isPending } =
    trpc.screening.screen.useMutation({
      onError(error) {
        toast({
          description: `Invalid sequence. Message: ${error.message}`,
          title: "Screening",
          variant: "destructive",
        });
      },
    });

  trpc.screening.results.useSubscription(requestId, {
    onData(data) {
      setResponse(data);
      setIsBackgroundJobRunning(false);
    },
    onError(error) {
      toast({
        description: `Error: ${error.message}`,
        title: "Screening",
        variant: "destructive",
      });
    },
  });

  const handleDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    setFile(file);
    setResponse(null);
  };

  const handleScreen = async () => {
    if (!file) {
      return;
    }
    const getRowsForPayload = (content: Row[]) =>
      content
        .slice(2, content.length)
        .filter((row) => {
          return row[1] && row[2] && row[3] && row[1] !== "Well";
        })
        .map((row) => {
          return {
            oligoName: row[2] as string,
            sequence: row[3] as string,
            well: row[1] as string,
          };
        })
        .map((row) => ({
          name: row.oligoName,
          sequence: row.sequence,
          well: row.well,
        }));
    const getRowsFromSheet = async (sheetIndex: number) => {
      const content = await readXlsxFile(file, { sheet: sheetIndex });
      return getRowsForPayload(content);
    };
    const getRows = async (): Promise<
      [{ name: string; sequence: string; well: string }[], boolean]
    > => {
      const rows96 = await getRowsFromSheet(2);
      const rows384 = await getRowsFromSheet(3);
      if (rows96.length > 0) {
        return [rows96, false];
      }
      return [rows384, true];
    };
    const [rows, is384] = await getRows();
    setIs384(is384);
    setFileRows(rows);
    if (sequenceType === null) {
      return;
    }
    screenMutation({ requestId, sequences: rows, type: sequenceType });
    setIsBackgroundJobRunning(true);
  };

  const data = useMemo(
    () =>
      response?.map((item) => {
        const row = fileRows.find((row) => row.well === item.id);
        const { name, sequence } = row ?? { name: "", sequence: "" };
        return { name, sequence, ...item };
      }) ?? [],
    [response, fileRows],
  );

  const screeningTable = useReactTable({
    columns: screeningColumns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });
  const isOk = !response || response.every((row) => row.valid);

  return (
    <div
      className={cn(
        "flex h-screen w-full flex-col items-center space-y-8 overflow-scroll px-24 py-8",
        !response && "justify-center",
        isBackgroundJobRunning && "pointer-events-none cursor-wait opacity-50",
      )}
    >
      <Card>
        <CardHeader>
          <CardTitle>Screening</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col space-y-4">
          <Input accept=".xlsx" onChange={handleDrop} type="file" />
          <Select
            onValueChange={(value) => {
              setSequenceType(value as ScreeningSequenceType);
              setResponse(null);
            }}
          >
            <SelectTrigger className="">
              <SelectValue placeholder="Sequence type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="oligos">Oligos</SelectItem>
              <SelectItem value="genes">Genes</SelectItem>
            </SelectContent>
          </Select>
          <Button
            disabled={
              sequenceType === null ||
              isPending ||
              !file ||
              isBackgroundJobRunning
            }
            onClick={handleScreen}
          >
            Screen
            {isBackgroundJobRunning && <LoadingSpinner className="ml-2" />}
          </Button>
          <div className="font-bold">
            {response &&
              (isOk ? (
                <p>All sequences are valid</p>
              ) : (
                <p>Some sequences are invalid !</p>
              ))}
          </div>
        </CardContent>
      </Card>
      {response && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-row items-center justify-between space-x-3">
            <h2 className="text-xl font-bold">Biosecurity & Purity</h2>
            <Button
              className="flex flex-row items-center space-x-1"
              onClick={handleDownloadResults}
            >
              <span>Results</span>
              <Download />
            </Button>
          </div>
          <SimpleTable table={screeningTable} />
          {sequenceType === "oligos" && <Separator />}
          {sequenceType === "oligos" && (
            <HomologyInfo filename={file?.name} rows={fileRows} />
          )}
        </div>
      )}
    </div>
  );
}

function HomologyInfo({
  filename,
  rows,
}: {
  filename?: string;
  rows: SequenceRow[];
}) {
  const [parameters, setParameters] = useState({
    maximumSizeDifferenceInPool: 100,
    minimumComplementarity: 90,
    minimumOverlap: 10,
  });

  const homologyInformation = trpc.screening.getHomologyInformation.useQuery(
    {
      parameters: {
        maximumSizeDifferenceInPool: parameters.maximumSizeDifferenceInPool,
        minimumComplementarity: parameters.minimumComplementarity / 100,
        minimumOverlap: parameters.minimumOverlap,
      },
      sequences: rows.map((row) => row.sequence),
    },
    {
      placeholderData: (prev) => prev,
    },
  );

  type WellHomologyInfo = {
    direction: Direction;
    position: number;
    well: string;
  };

  const interSequenceHomology = useMemo(
    () =>
      Object.entries(homologyInformation.data?.interSequenceHomology ?? {})
        .flatMap(([index, homology]) => {
          return Object.entries(homology).flatMap(([nestedIndex, homology]) => {
            return homology.flatMap((entry) => {
              return {
                complementarity: entry.complementarity,
                length: entry.length,
                lhsWell: {
                  direction: entry.directions[0],
                  position: entry.positions[0],
                  well: rows[parseInt(index)].well,
                } as WellHomologyInfo,
                rhsWell: {
                  direction: entry.directions[1],
                  position: entry.positions[1],
                  well: rows[parseInt(nestedIndex)].well,
                } as WellHomologyInfo,
              };
            });
          });
        })
        .sort((a, b) => b.length - a.length),
    [rows, homologyInformation.data?.interSequenceHomology],
  );

  const getWellIllustration = useCallback(
    (wellHomology: WellHomologyInfo, labelPlacement: "top" | "bottom") => {
      const size = 20;
      const padding =
        wellHomology.direction === "forward"
          ? wellHomology.position > 0
            ? "-ml-2"
            : ""
          : wellHomology.position > 0
            ? "ml-2"
            : "";
      const icon =
        wellHomology.direction === "forward" ? (
          <MoveRight className="my-[-0.2rem]" size={size} />
        ) : (
          <MoveLeft className="my-[-0.2rem]" size={size} />
        );

      const row = rows.find((row) => row.well === wellHomology.well);

      const toolTipContent = (
        <div className="flex flex-col items-start">
          <span className="text-sm font-bold">{wellHomology.well}</span>
          <span className="text-sm text-gray-500">
            {wellHomology.direction}
          </span>
          <span className="text-sm">start {wellHomology.position}</span>
          <span className="text-sm">size {row?.sequence.length}</span>
        </div>
      );

      const label =
        wellHomology.well +
        (wellHomology.direction === "reverse complement" ? "*" : "");

      return (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger>
              <div className={`flex flex-col ${padding}`}>
                {labelPlacement === "top" && <span>{label}</span>}
                {icon}
                {labelPlacement === "bottom" && <span>{label}</span>}
              </div>
              <TooltipContent className="flex flex-col">
                {toolTipContent}
              </TooltipContent>
            </TooltipTrigger>
          </Tooltip>
        </TooltipProvider>
      );
    },
    [rows],
  );

  const homologyColumnHelper =
    createColumnHelper<(typeof interSequenceHomology)[number]>();

  const homologyColumns = useMemo(
    () => [
      homologyColumnHelper.accessor("rhsWell.direction", {
        cell: (info) => {
          const homology = info.row.original;
          return (
            <div className="ml-2 flex flex-col">
              <span>{getWellIllustration(homology.lhsWell, "top")}</span>
              <span>{getWellIllustration(homology.rhsWell, "bottom")}</span>
            </div>
          );
        },
        header: "Homology",
      }),
      homologyColumnHelper.accessor("length", {
        cell: (info) => <span>{info.getValue()} bp</span>,
        header: "Overlap (bp)",
      }),
      homologyColumnHelper.accessor("complementarity", {
        cell: (info) => <span>{(info.getValue() * 100).toFixed(0)}%</span>,
        header: "Complementarity",
      }),
    ],
    [getWellIllustration, homologyColumnHelper],
  );
  const homologyTable = useReactTable({
    columns: homologyColumns,
    data: interSequenceHomology ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const pooling = useMemo(
    () =>
      (homologyInformation.data?.pools ?? []).map((pool, index) => {
        return {
          pool: `${index + 1}`,
          wells: pool.map((wellIndex) => rows[wellIndex]),
        };
      }),
    [rows, homologyInformation.data?.pools],
  );

  const poolingColumnHelper = createColumnHelper<(typeof pooling)[number]>();

  const poolingColumns = useMemo(
    () => [
      poolingColumnHelper.accessor("pool", {
        header: "Pool",
      }),
      poolingColumnHelper.accessor("wells", {
        cell: (info) => (
          <div className="flex flex-row flex-wrap gap-2">
            {info.getValue().map((well) => (
              <Badge
                className="flex flex-row gap-1"
                key={well.well}
                variant={"outline"}
              >
                <p className="text-sm">{well.well}</p>
                <p className="text-xs text-gray-400">{well.sequence.length}</p>
              </Badge>
            ))}
          </div>
        ),
        header: "Wells",
      }),
    ],
    [poolingColumnHelper],
  );

  const poolingTable = useReactTable({
    columns: poolingColumns,
    data: pooling,
    getCoreRowModel: getCoreRowModel(),
  });

  const parameterInputs = [
    {
      id: "minimumOverlap",
      label: "Minimum overlap (bp)",
      max: undefined,
      min: 0,
      value: parameters.minimumOverlap,
    },
    {
      id: "minimumComplementarity",
      label: "Minimum complementarity (%)",
      max: 100,
      min: 0,
      value: parameters.minimumComplementarity,
    },
    {
      id: "maximumSizeDifferenceInPool",
      label: "Maximum size difference in a pool (bp)",
      max: undefined,
      min: 0,
      value: parameters.maximumSizeDifferenceInPool,
    },
  ];

  const onUpdateParameters = (event: SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      maximumSizeDifferenceInPool: { value: string };
      minimumComplementarity: { value: string };
      minimumOverlap: { value: string };
    };

    const newParameters = {
      maximumSizeDifferenceInPool: parseInt(
        target.maximumSizeDifferenceInPool.value,
      ),
      minimumComplementarity: parseInt(target.minimumComplementarity.value),
      minimumOverlap: parseInt(target.minimumOverlap.value),
    };

    setParameters(newParameters);
  };

  const handleDownloadResults = useCallback(async () => {
    const workbook = new Excel.Workbook();
    const ngsPoolingWorksheet = workbook.addWorksheet("NGS Pooling");

    ngsPoolingWorksheet.columns = [
      { header: "Well", key: "well", width: 10 },
      { header: "Sequence name", key: "sequenceName", width: 40 },
      { header: "Sequence length (mers)", key: "sequenceLength", width: 20 },
      { header: "Destination pool (well)", key: "pool", width: 40 },
    ];

    const getPoolWellId = (row: SequenceRow) => {
      const pool =
        pooling.find((pool) =>
          pool.wells.some((well) => well.well === row.well),
        )?.pool ?? "";
      const wellId = `${String.fromCharCode(((+pool - 1) % 8) + "A".charCodeAt(0))}${Math.floor((+pool - 1) / 8) + 1}`;
      return wellId;
    };

    for (const row of rows) {
      ngsPoolingWorksheet.addRow({
        pool: getPoolWellId(row),
        sequenceLength: row.sequence.length,
        sequenceName: row.name,
        well: row.well,
      });
    }

    const homologyWorksheet = workbook.addWorksheet("Homology");
    homologyWorksheet.columns = [
      { header: "Well", key: "well1", width: 10 },
      { header: "Sequence name", key: "sequenceName1", width: 20 },
      { header: "Sequence length (mers)", key: "sequenceLength1", width: 15 },
      { header: "Direction", key: "direction1", width: 20 },
      { header: "Start position (0-based)", key: "overlapStart1", width: 15 },
      { header: "Well", key: "well2", width: 10 },
      { header: "Sequence name", key: "sequenceName2", width: 20 },
      { header: "Sequence length (mers)", key: "sequenceLength2", width: 15 },
      { header: "Direction", key: "direction2", width: 20 },
      { header: "Start position (0-based)", key: "overlapStart2", width: 15 },
      { header: "Overlap (bp)", key: "overlap", width: 20 },
      { header: "Complementarity (%)", key: "complementarity", width: 20 },
    ];

    for (const homology of interSequenceHomology) {
      homologyWorksheet.addRow({
        complementarity: +(homology.complementarity * 100).toFixed(0),
        direction1: homology.lhsWell.direction,
        direction2: homology.rhsWell.direction,
        overlap: homology.length,
        overlapStart1: homology.lhsWell.position,
        overlapStart2: homology.rhsWell.position,
        sequenceLength1: rows.find((row) => row.well === homology.lhsWell.well)
          ?.sequence.length,
        sequenceLength2: rows.find((row) => row.well === homology.rhsWell.well)
          ?.sequence.length,
        sequenceName1: rows.find((row) => row.well === homology.lhsWell.well)
          ?.name,
        sequenceName2: rows.find((row) => row.well === homology.rhsWell.well)
          ?.name,
        well1: homology.lhsWell.well,
        well2: homology.rhsWell.well,
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename?.split(".xlsx")[0]}_ngs_prep.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  }, [rows, filename, interSequenceHomology, pooling]);

  return (
    <div
      className={cn(
        "flex flex-col gap-6",
        homologyInformation.isFetching &&
          "pointer-events-none cursor-wait opacity-50",
      )}
    >
      <div className="flex flex-row items-center justify-between space-x-3">
        <h2 className="flex flex-row items-center gap-2 text-xl font-bold">
          <span>NGS Preparation</span>{" "}
          {homologyInformation.isFetching && <LoadingSpinner />}
        </h2>
        <Button
          className="flex flex-row items-center space-x-1"
          onClick={handleDownloadResults}
        >
          <span>Results</span>
          <Download />
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-bold">Homology Parameters</h3>
        <form
          className="flex flex-row items-end gap-4"
          onSubmit={onUpdateParameters}
        >
          {parameterInputs.map(({ id, label, value, min, max }) => (
            <div className="grid w-full max-w-sm items-center gap-1.5" key={id}>
              <Label htmlFor={id}>{label}</Label>
              <Input
                defaultValue={value}
                id={id}
                max={max}
                min={min}
                type="number"
              />
            </div>
          ))}
          <Button
            disabled={homologyInformation.isFetching}
            type="submit"
            variant={"secondary"}
          >
            Update
          </Button>
        </form>
      </div>
      <div className="flex max-w-4xl flex-col space-x-3">
        <h3 className="text-lg font-bold">Pooling</h3>
        <SimpleTable table={poolingTable} />
      </div>
      <div className="flex flex-col space-x-3">
        <h3 className="text-lg font-bold">Homology</h3>
        <span className="text-sm text-gray-500">
          * denotes the reverse complement of the well sequence
        </span>
        <SimpleTable table={homologyTable} />
      </div>
    </div>
  );
}
