import { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { getRowColumnFromIndex } from "./useEditWells";
import { getColumns, getRows } from "./useGetAllWellsById";

import type { PlateSize } from "../../../../../config/enums";

export const useKeyboardPlateNavigation = (
  size: PlateSize,
  wellSelected: string | null,
  setWellSelected: React.Dispatch<React.SetStateAction<string | null>>,
  isWellSelected: boolean,
) => {
  const { ref: selectedWellRef, inView: isSelectedWellInView } = useInView({
    threshold: 1,
  });

  const columns = getColumns(size);
  const rows = getRows(size);

  const moveUp = useCallback(
    (currentWell: string | null) => {
      if (!currentWell) {
        return null;
      }
      const { row, column } = getRowColumnFromIndex(currentWell);
      const currentRow = rows.indexOf(row);
      if (currentRow === 0) {
        return currentWell;
      }
      const nextRow = rows[currentRow - 1];
      const nextWell = `${nextRow}${column}`;
      return nextWell;
    },
    [rows],
  );

  const moveDown = useCallback(
    (currentWell: string | null) => {
      if (!currentWell) {
        return null;
      }
      const { row, column } = getRowColumnFromIndex(currentWell);
      const currentRow = rows.indexOf(row);
      if (currentRow === rows.length - 1) {
        return currentWell;
      }
      const nextRow = rows[currentRow + 1];
      const nextWell = `${nextRow}${column}`;
      return nextWell;
    },
    [rows],
  );

  const moveRight = useCallback(
    (currentWell: string | null) => {
      if (!currentWell) {
        return null;
      }
      const { row, column } = getRowColumnFromIndex(currentWell);
      const currentColumn = columns.indexOf(column);
      if (currentColumn === columns.length - 1) {
        return currentWell;
      }
      const nextColumn = columns[currentColumn + 1];
      const nextWell = `${row}${nextColumn}`;
      return nextWell;
    },
    [columns],
  );

  const moveLeft = useCallback(
    (currentWell: string | null) => {
      if (!currentWell) {
        return null;
      }
      const { row, column } = getRowColumnFromIndex(currentWell);
      const currentColumn = columns.indexOf(column);
      if (currentColumn === 0) {
        return currentWell;
      }
      const nextColumn = columns[currentColumn - 1];
      const nextWell = `${row}${nextColumn}`;
      return nextWell;
    },
    [columns],
  );

  function scrollWellIntoView(index: string | null) {
    if (!index) {
      return;
    }
    const selectedWellElement = document.getElementById(index);
    if (selectedWellElement) {
      selectedWellElement.scrollIntoView({
        block: "center",
        inline: "center",
      });
    }
  }

  const handleMove = useCallback(
    (ev: KeyboardEvent) => {
      if (
        ev.target instanceof HTMLInputElement ||
        ev.target instanceof HTMLTextAreaElement ||
        (ev.target instanceof HTMLDivElement && ev.target.role === "textbox") ||
        ev.metaKey
      ) {
        return;
      }
      function getNewWell() {
        if (ev.key === "ArrowRight") {
          return moveRight(wellSelected);
        }
        if (ev.key === "ArrowLeft") {
          return moveLeft(wellSelected);
        }
        if (ev.key === "ArrowUp") {
          return moveUp(wellSelected);
        }
        if (ev.key === "ArrowDown") {
          return moveDown(wellSelected);
        }
        return wellSelected;
      }
      const newSelectedWell = getNewWell();
      const moved = wellSelected !== newSelectedWell;
      if (moved) {
        setWellSelected(newSelectedWell);
        if (!isSelectedWellInView) {
          scrollWellIntoView(newSelectedWell);
        }
      }
      if (isWellSelected) {
        ev.preventDefault();
      }
    },
    [
      isSelectedWellInView,
      isWellSelected,
      moveDown,
      moveLeft,
      moveRight,
      moveUp,
      setWellSelected,
      wellSelected,
    ],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleMove);
    return () => {
      document.removeEventListener("keydown", handleMove);
    };
  }, [handleMove]);

  return selectedWellRef;
};
