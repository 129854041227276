import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { Heading } from "@radix-ui/themes";
import { SaveIcon, Undo } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useGetHamiltonInstrument } from "./useGetInstrument";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { trpc } from "../../config/trpc";
import { OrganizationRoutes } from "../organization/organization-routes";

const hamiltonForm = z.object({
  ipAddress: z.string(),
  name: z.string().trim().min(1),
  usbPort: z.string(),
});

export type HamiltonUpdateForm = z.infer<typeof hamiltonForm>;

export default function HamiltonInstrumentEdit() {
  const { data, isPending } = useGetHamiltonInstrument();
  const navigate = useNavigate();

  const form = useForm<HamiltonUpdateForm>({
    defaultValues: {
      ipAddress: data?.ipAddress,
      name: data?.name,
      usbPort: data?.usbPort,
    },
    resolver: zodResolver(hamiltonForm),
  });

  const utils = trpc.useUtils();
  const { mutate: updateInstrument } =
    trpc.instrument.hamilton.update.useMutation({
      onSuccess(_, variables) {
        utils.instrument.hamilton.get.invalidate({
          instrumentId: variables.instrumentId,
        });
      },
    });

  useEffect(() => {
    form.reset({
      ipAddress: data?.ipAddress,
      name: data?.name,
      usbPort: data?.usbPort,
    });
  }, [data, form]);

  if (isPending) return <Heading>Loading...</Heading>;

  if (!data) {
    return <Heading>Instrument not found</Heading>;
  }

  const hamiltonId = data.id;

  const onSubmit = (data: HamiltonUpdateForm) => {
    updateInstrument({
      instrumentId: hamiltonId,
      ipAddress: data.ipAddress,
      name: data.name,
      usbPort: data.usbPort,
    });
    navigate(
      OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
        ":instrumentId",
        hamiltonId,
      ),
    );
  };

  return (
    <div className="flex flex-col space-y-8">
      <Form {...form}>
        <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-between">
            <Heading className="space-x-2">
              <span>Hamilton instrument</span>
              <span className="italic">{data.name}</span>
            </Heading>
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => {
                  navigate(
                    OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
                      ":instrumentId",
                      hamiltonId,
                    ),
                  );
                }}
                type="button"
                variant={"outline"}
              >
                <Undo />
              </Button>
              <Button type="submit" variant={"outline"}>
                <SaveIcon />
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
              <Heading size={"4"}>Details</Heading>
              {data.createdAt && (
                <>
                  <div className="flex flex-row justify-between space-x-2">
                    <p>Created at</p>
                    <p>{new Date(data.createdAt).toLocaleString()}</p>
                  </div>
                  <Separator />
                </>
              )}
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator />
                <FormField
                  control={form.control}
                  name="ipAddress"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>IP Address</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="192.168.0.1" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator />
                <FormField
                  control={form.control}
                  name="usbPort"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>USB port</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="COM4" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
              <Heading size={"4"}>Teach points</Heading>
              <div className="flex flex-row justify-between space-x-2">
                <p>Coming soon...</p>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
