import { useParams } from "react-router-dom";

import { trpc } from "../../../../config/trpc";

export default function useGetAssay() {
  const { assayId } = useParams();

  return useGetAssayWithId(assayId);
}

export const useGetAssayWithId = (assayId: string | undefined) => {
  return trpc.assay.get.useQuery(assayId ?? "", {
    enabled: !!assayId,
  });
};
