import { useAuth } from "../containers/hooks";

const getFilenameFromUrl = (url: string, defaultName: string) => {
  return url.split("/").pop() || defaultName;
};

export function useDownloadFile() {
  const { token } = useAuth();

  return async function (url: string, defaultName: string) {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const statusCode = response.status;
    if (statusCode !== 200) {
      throw new Error(`Request failed with status code ${statusCode}`);
    }
    const fileName = getFilenameFromUrl(url, defaultName);
    const blob = await response.blob();
    downloadBlob(blob, fileName);
  };
}

export function downloadBlob(blob: Blob, fileName: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function base64ToBlob(base64String: string, contentType: string) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}
