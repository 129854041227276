import { Navigate } from "react-router-dom";

import { RootRoutes } from "./routes";

import { useUser } from "../containers/hooks";

const UserPermissionProtectedRoute = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const user = useUser();

  const hasAllPermissions = !user || user.isAdmin;

  if (!hasAllPermissions) {
    return <Navigate replace to={RootRoutes.ORGANIZATION} />;
  }

  return <>{children}</>;
};

export default UserPermissionProtectedRoute;
