import { trpc } from "../../../../config/trpc";

export const useCheckRun = (runId: number | undefined) => {
  const { mutate: checkRunMutation, isPending } =
    trpc.assay.steps.plate.run.check.useMutation();

  const checkRun = () => {
    if (!runId) {
      return;
    }
    checkRunMutation({ id: runId });
  };

  return { checkRun, isPending };
};
