import type { Row } from "@tanstack/react-table";

export const arrIncludesSomeWithEmptyFn = <TData extends Record<string, any>>(
  row: Row<TData>,
  columnId: string,
  filterValue: any,
) => {
  return filterValue.some((val: unknown) =>
    row.getValue<unknown[]>(columnId)?.includes(val),
  );
};
