function drawHelperCircle(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
  color: string,
) {
  context.beginPath();
  context.arc(x, y, radius, 0, 2 * Math.PI);
  context.fillStyle = color;
  context.fill();
}

/**
 * fillPercent - should be from 0 to 1
 */
function drawCircle(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
  lineWidth: number,
  color: string,
  fillPercent: number,
  showDot = false,
) {
  const beginAngle = (135 * Math.PI) / 180;
  const endAngle = beginAngle + (270 * fillPercent * Math.PI) / 180;

  context.beginPath();
  context.arc(x, y, radius, beginAngle, endAngle, false);
  context.lineWidth = lineWidth;
  context.strokeStyle = color;
  context.stroke();

  const startX = Math.cos(beginAngle) * radius + x;
  const startY = Math.sin(beginAngle) * radius + y;

  const endX = Math.cos(endAngle) * radius + x;
  const endY = Math.sin(endAngle) * radius + y;

  drawHelperCircle(context, startX, startY, lineWidth / 2, color);
  drawHelperCircle(context, endX, endY, lineWidth / 2, color);

  if (showDot) {
    drawHelperCircle(context, endX, endY, lineWidth / 4, "#fff");
  }
}

interface DrawRunProgressConfig {
  currentStep: number;
  fullPercent: number;
  mainTextColor?: string;
  mainTextWeight?: "bold" | "normal";
  percent: number;
  progressBgColor?: string;
  showStepProgress: boolean;
  size: number;
  stepName: string;
  stepProgressColor?: string;
  stepTextColor?: string;
  stepsLength: number;
  totalProgressColor?: string;
}

export function drawRunProgress(
  context: CanvasRenderingContext2D,
  config: DrawRunProgressConfig,
) {
  const {
    size,
    fullPercent,
    percent,
    stepName,
    currentStep,
    stepsLength,
    showStepProgress,
    totalProgressColor = "#0668B2",
    stepProgressColor = "#FFCA6C",
    progressBgColor = "#E0E0E0",
    mainTextColor = "#050935",
    stepTextColor = "#888B8D",
    mainTextWeight = "bold",
  } = config;

  const minWidth = size;
  const x = minWidth / 2;
  const y = minWidth / 2;

  // clear canvas
  context.clearRect(0, 0, minWidth, minWidth);

  // Outer circle
  const lineWidth = 30;
  let radius = x - lineWidth;
  if (radius < 0) radius = 0;
  drawCircle(context, x, y, radius, lineWidth, progressBgColor, 1);
  drawCircle(
    context,
    x,
    y,
    radius,
    lineWidth,
    totalProgressColor,
    fullPercent / 100,
    true,
  );

  // Inner circle
  if (showStepProgress) {
    const lineWidth2 = 23;
    radius = x - lineWidth - (lineWidth2 + 2) * 2;
    if (radius < 0) radius = 0;
    drawCircle(context, x, y, radius, lineWidth2, progressBgColor, 1);
    drawCircle(
      context,
      x,
      y,
      radius,
      lineWidth2,
      stepProgressColor,
      (percent || 0) / 100,
    );
  }

  context.fillStyle = mainTextColor;
  context.font = `${mainTextWeight} ${minWidth * 0.075}px sans-serif`;
  context.textAlign = "center";
  context.fillText(
    stepName || "",
    minWidth / 2,
    minWidth * 0.45,
    minWidth * (showStepProgress ? 0.5 : 0.7),
  );

  context.font = `${mainTextWeight} ${minWidth * 0.075}px sans-serif`;
  context.fillText(`${percent}%`, minWidth / 2, minWidth * 0.63, minWidth);

  if (showStepProgress) {
    context.fillStyle = stepTextColor;
    context.font = `${minWidth * 0.04}px sans-serif`;
    context.fillText(
      `STEP ${currentStep + 1} OF ${stepsLength}`,
      minWidth / 2,
      minWidth * 0.7,
      minWidth,
    );
  }
}
