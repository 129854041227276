import type { inferProcedureOutput } from "@trpc/server";
import { useState } from "react";

import { Button } from "../../../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { ConstructType } from "../../../../../config/enums";
import type { AppRouter } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";

export default function WorkflowGenes({ assayId }: { assayId: string }) {
  const { data: constructs } = trpc.construct.listInAssay.useQuery({
    assayId,
  });

  const geneConstructs = (constructs ?? []).filter(
    (c) => c.constructType === ConstructType.Gene,
  );

  if (!geneConstructs.length) {
    return null;
  }

  return (
    <div>
      <h3 className="font-bold">Genes</h3>
      <ul className="max-h-52 space-y-2 divide-y overflow-scroll">
        {geneConstructs.map((construct) => {
          return <GeneItem construct={construct} key={construct.id} />;
        })}
      </ul>
    </div>
  );
}

type ConstructInListOfAssay = inferProcedureOutput<
  AppRouter["construct"]["listInAssay"]
>[number];

function GeneItem({ construct }: { construct: ConstructInListOfAssay }) {
  const [selectedDesign, setSelectedDesign] = useState<string | null>(null);

  const utils = trpc.useUtils();
  const { mutate: assignDesignToAssay, isPending } =
    trpc.assay.steps.assignDesign.useMutation({
      onSuccess() {
        utils.assay.get.invalidate(construct.assayId);
        utils.assay.oligos.invalidate(construct.assayId);
        utils.assay.steps.plates.invalidate(construct.assayId);
      },
    });

  const gene = construct.gene;
  if (!gene || !construct.assayId) {
    return null;
  }
  const designs = gene.designs;
  const noDesign = designs.length === 0;

  const assignDesign = (designId: string) => {
    if (!construct.assayId) {
      return;
    }
    assignDesignToAssay({
      assayId: construct.assayId,
      designId,
    });
  };

  return (
    <li className="grid grid-cols-3 place-items-center " key={construct.id}>
      <p>{construct.name}</p>
      {noDesign ? (
        <p>No designs</p>
      ) : (
        <div className="p-1">
          <Select
            onValueChange={setSelectedDesign}
            value={selectedDesign ?? undefined}
          >
            <SelectTrigger className="h-8 w-[120px]">
              <SelectValue placeholder="Pick design" />
            </SelectTrigger>
            <SelectContent side="top">
              {designs.map((d) => (
                <SelectItem key={d.id} value={d.id}>
                  {d.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {selectedDesign && (
        <Button
          isLoading={isPending}
          onClick={() => assignDesign(selectedDesign)}
          size={"sm"}
        >
          Add
        </Button>
      )}
    </li>
  );
}
