import { Trash2Icon } from "lucide-react";
import { useState } from "react";

import type { PlateKit, WorkflowWell } from "./types";

import { Button } from "../../../../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";
import { Normalization } from "../../../../../../config/enums";
import PickConcentration from "../../../assay/components/pick-concentration";
import { SequenceInput } from "../../../construct/components/sequence-input";

export default function SidebarMultiWells({
  wells,
  isEditable,
  handleChangeWells,
  handleDeleteWells,
  kitProperties,
}: {
  handleChangeWells: (wells: WorkflowWell[]) => void;
  handleDeleteWells: (indices: string[]) => void;
  isEditable: boolean;
  kitProperties: PlateKit;
  wells: WorkflowWell[];
}) {
  const defaultConcentration = kitProperties.concentration.default;
  const [localNormalization, setLocalNormalization] = useState<Normalization>(
    wells[0].normalization,
  );

  const handleApplyConcentrationChange = (
    normalization: Normalization,
    concentration?: number,
  ) => {
    setLocalNormalization(normalization);
    const updatedWells = wells.map((well) => {
      const isTarget = normalization === Normalization.Target;
      const expectedConcentration = isTarget
        ? concentration || defaultConcentration
        : undefined;
      return {
        ...well,
        expectedConcentration,
        normalization,
      };
    });
    handleChangeWells(updatedWells);
  };

  const handleChangeSequence = (newSequence: string) => {
    handleChangeWells(wells.map((w) => ({ ...w, sequence: newSequence })));
  };

  const sequenceValue =
    new Set(wells.map((w) => w.sequence)).size > 1 ? "" : wells[0].sequence;

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex justify-between">
        <p className="font-medium">{`Inspector (${wells.length} selected)`}</p>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              disabled={!isEditable}
              onClick={() => {
                handleDeleteWells(wells.map((w) => w.index));
              }}
              variant={"ghost"}
            >
              <Trash2Icon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Remove wells</TooltipContent>
        </Tooltip>
      </div>
      <div className="space-y-2">
        <p className="italic">Sequence</p>
        <SequenceInput
          disabled={!isEditable}
          onBlur={() => {}}
          onChange={handleChangeSequence}
          value={sequenceValue}
        />
      </div>
      <PickConcentration
        currentConcentration={
          wells[0].expectedConcentration ?? defaultConcentration
        }
        handleApplyConcentrationChange={handleApplyConcentrationChange}
        isEditable={isEditable}
        kitConcentrationMax={kitProperties.concentration.max}
        kitConcentrationMin={kitProperties.concentration.min}
        normalization={localNormalization}
      />
    </div>
  );
}
