import { Switch } from "../../../../../components/ui/switch";

type OrganizationConfigurationProps = {
  bioSecurityEnabled: boolean;
  clicksEnabled: boolean;
  customProcessEnabled: boolean;
  degenerateBasesEnabled: boolean;
  geneEnabled: boolean;
  isActive: boolean;
  mfaMandatory: boolean;
  modsEnabled: boolean;
  notificationsEnabled: boolean;
  remoteRunTriggerEnabled: boolean;
  serviceEnabled: boolean;
};

export default function OrganizationFlagConfiguration({
  bioSecurityEnabled,
  clicksEnabled,
  customProcessEnabled,
  degenerateBasesEnabled,
  geneEnabled,
  isActive,
  mfaMandatory,
  modsEnabled,
  notificationsEnabled,
  remoteRunTriggerEnabled,
  serviceEnabled,
}: OrganizationConfigurationProps) {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={isActive} disabled={true} />
        <span>Active ?</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={clicksEnabled} disabled={true} />
        <span>Clicks enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={customProcessEnabled} disabled={true} />
        <span>Custom processes enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={degenerateBasesEnabled} disabled={true} />
        <span>Degenerate bases enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={geneEnabled} disabled={true} />
        <span>Genes enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={modsEnabled} disabled={true} />
        <span>Modifiers enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={notificationsEnabled} disabled={true} />
        <span>Notifications enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={remoteRunTriggerEnabled} disabled={true} />
        <span>Remote run trigger enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={bioSecurityEnabled} disabled={true} />
        <span>Biosecurity enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={mfaMandatory} disabled={true} />
        <span>MFA enabled</span>
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Switch checked={serviceEnabled} disabled={true} />
        <span>Service enabled</span>
      </div>
    </div>
  );
}
