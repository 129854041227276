import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PageWithWallPaper from "./components/page-with-wallpaper";
import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { MfaCodeInput } from "../components/ui/mfa-code-input";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { useAuth } from "../containers/hooks";
import { getRouteToGoToAfterLogin, parseJwt } from "../utils/auth";

export default function MFA() {
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const { toast } = useToast();

  const { mutate: validateMFACode } =
    trpc.account.user.mfa.validate.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(data) {
        const token = data;
        if (!token) {
          throw new Error("Authentication error");
        }
        setToken(token);
        navigate(getRouteToGoToAfterLogin(parseJwt(token)));
      },
    });

  const onSubmit: SubmitHandler<{ token: string }> = (data) => {
    validateMFACode({
      token: data.token,
    });
  };

  return (
    <PageWithWallPaper>
      <Card>
        <CardHeader>
          <CardTitle>MFA token</CardTitle>
          <CardDescription>Enter your one-time password</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col space-y-2">
          <MfaCodeInput buttonTitle="Login" onSubmit={onSubmit} />
          <Button
            onClick={() => {
              navigate(RootRoutes.MFA_RECOVERY);
            }}
            variant="ghost"
          >
            Use Recovery Code
          </Button>
        </CardContent>
      </Card>
    </PageWithWallPaper>
  );
}
