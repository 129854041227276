import { zodResolver } from "@hookform/resolvers/zod";
import { LogIn } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import PageWithWallPaper from "./components/page-with-wallpaper";
import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { PasswordInput } from "../components/ui/password";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { strongPasswordSchema } from "../utils/string";

const setNewPasswordForm = z
  .object({
    password: strongPasswordSchema,
    passwordConfirmation: strongPasswordSchema,
  })
  .superRefine(({ passwordConfirmation, password }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords do not match",
        path: ["confirm"],
      });
    }
  });

type SetNewPasswordForm = z.infer<typeof setNewPasswordForm>;

export default function SetNewPassword() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const code = searchParams.get("code");
  const userId = searchParams.get("userId");
  const form = useForm<SetNewPasswordForm>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: zodResolver(setNewPasswordForm),
  });

  const errorMessage = (form.formState.errors as any)?.["confirm"]?.message;

  const { mutate: resetPassword } =
    trpc.account.user.setNewPasswordWithVerificationCode.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error when setting new password",
          variant: "destructive",
        });
      },
      onSuccess() {
        navigate(RootRoutes.LOGIN);
      },
    });

  const onSubmit = (data: SetNewPasswordForm) => {
    if (!userId || !code) {
      return;
    }
    resetPassword({
      password: data.password,
      userId,
      verificationCode: code,
    });
  };

  useEffect(() => {
    if (!code || !userId) {
      navigate(RootRoutes.ROOT);
    }
  }, [code, navigate, userId]);

  return (
    <PageWithWallPaper>
      <Card>
        <CardHeader>
          <CardTitle>Set new password</CardTitle>
          <CardDescription>
            Set a new password for your account and submit to continue.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col items-center space-y-4">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <PasswordInput
                          {...field}
                          divClassName="w-96"
                          type="password"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="passwordConfirmation"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Confirm password</FormLabel>
                      <FormControl>
                        <PasswordInput
                          {...field}
                          divClassName="w-96"
                          type="password"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-row justify-center">
                <Button className="space-x-1" type="submit">
                  <span>Submit</span>
                  <LogIn />
                </Button>
              </div>
              <div className="flex flex-row justify-center">
                {errorMessage && (
                  <h3 className="text-xl text-red-600">{errorMessage}</h3>
                )}
              </div>
            </form>
          </Form>
          <Button
            onClick={() => {
              navigate(RootRoutes.LOGIN);
            }}
            variant={"ghost"}
          >
            Back to login
          </Button>
        </CardContent>
      </Card>
    </PageWithWallPaper>
  );
}
