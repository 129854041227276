import { Heading } from "@radix-ui/themes";
import { useParams } from "react-router-dom";

import { EditableText } from "../../../../components/logic/editable-text";
import { trpc } from "../../../../config/trpc";
import type { TeamQuery } from "../team";

export default function TeamName({ team }: { team?: TeamQuery["team"] }) {
  const { teamId } = useParams();
  const { mutate: updateName } = trpc.account.team.updateName.useMutation();
  const handleUpdateName = (newName: string) => {
    if (!teamId || !team) {
      return;
    }
    updateName({
      id: teamId,
      name: newName,
    });
  };

  return (
    <Heading className="flex flex-row items-center space-x-2">
      <span>Team</span>
      <EditableText
        defaultValue={team?.name ?? ""}
        onSubmit={handleUpdateName}
      />
    </Heading>
  );
}
