import type { JWTPayload } from "../containers/AuthContainer";
import { RootRoutes } from "../routes/routes";

export function parseJwt(token: string | undefined): JWTPayload {
  if (!token) {
    throw new Error("No token provided");
  }
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (err) {
    throw new Error(`Could not parse JWT. Error: ${err}`);
  }
}

export const getRouteToGoToAfterLogin = (token: JWTPayload | null) => {
  if (!token) {
    return RootRoutes.LOGIN;
  }
  if (token.mfaEnabled && !token.mfaValidated) {
    return RootRoutes.MFA;
  }
  if (token.shouldResetPassword) {
    return RootRoutes.SET_PASSWORD_SHOULD_RESET;
  }
  if (token.isOrganizationMember) {
    return RootRoutes.ORGANIZATION;
  }
  return RootRoutes.PICK_ORGANIZATION;
};
