import { CommandLoading } from "cmdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";

import { Button } from "../../../components/ui/button";
import {
  CommandDialogWithButton,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import { trpc } from "../../../config/trpc";
import { OrganizationRoutes } from "../../organization/organization-routes";

export default function GlobalSearch() {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [oligoId, setOligoId] = useState<string | null>(null);

  const [debouncedSearch] = useDebounceValue<string>(search, 500);
  const navigate = useNavigate();

  const { isPending, data: oligos } = trpc.construct.search.oligos.useQuery(
    {
      name: debouncedSearch,
    },
    {
      enabled: !!debouncedSearch,
    },
  );

  const { data: oligoData } = trpc.construct.search.getOligo.useQuery(
    { id: oligoId ?? "" },
    {
      enabled: !!oligoId,
      initialData: undefined,
    },
  );

  useEffect(() => {
    if (oligoData) {
      setOpen(false);
      const { constructId, id } = oligoData;
      if (!constructId) {
        return;
      }
      navigate(
        OrganizationRoutes.CONSTRUCT.replace(
          ":constructId",
          constructId,
        ).concat("?oligo=", id),
      );
    }
  }, [navigate, oligoData]);

  const handleClickOnOligo = (oligoId: string) => {
    setOligoId(oligoId);
  };

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <CommandDialogWithButton
      button={<Button variant={"ghost"}>Search</Button>}
      onOpenChange={setOpen}
      open={open}
    >
      <CommandInput
        onValueChange={setSearch}
        placeholder="Search..."
        value={search}
      />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup heading="Oligos">
          <CommandList>
            {isPending && <CommandLoading>Fetching oligos...</CommandLoading>}
          </CommandList>
          {!isPending &&
            oligos?.map((oligo) => (
              <CommandItem
                className="cursor-pointer hover:underline"
                key={oligo.id}
                onSelect={() => {
                  handleClickOnOligo(oligo.id);
                }}
                value={oligo.name}
              >
                <span>{oligo.name}</span>
              </CommandItem>
            ))}
        </CommandGroup>
      </CommandList>
    </CommandDialogWithButton>
  );
}
