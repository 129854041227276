import { zodResolver } from "@hookform/resolvers/zod";
import { getQueryKey } from "@trpc/react-query";
import { SaveIcon, Undo } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import EditProcesses from "./components/edit-processes";
import { useGetMyOrganization } from "./hooks";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { useToast } from "../../../components/ui/use-toast";
import { queryClient, trpc } from "../../../config/trpc";
import type { OrganizationProfile } from "../../admin/organizations/hooks";

const organizationFormSchema = z.object({
  name: z.string().trim().min(2).max(100),
  processes: z.array(
    z.object({
      compatibleKits: z.array(z.string()),
      id: z.string().optional().nullable(),
      isCustom: z.boolean(),
      label: z.string(),
      process: z.string(),
      processContent: z.string().optional(),
    }),
  ),
});

export type OrganizationSettingsForm = z.infer<typeof organizationFormSchema>;

const getDefaultValues = (
  data: OrganizationProfile | undefined,
): OrganizationSettingsForm => {
  const organizationKits = data?.configuration.kits;
  return {
    name: data?.name ?? "",
    processes: (data?.configuration?.customProcesses ?? []).map((process) => ({
      ...process,
      compatibleKits:
        organizationKits
          ?.filter((kit) =>
            kit.allowedProcesses.some((ap) => ap.process === process.process),
          )
          .map((k) => k.kit) ?? [],
    })),
  };
};

export default function OrganizationSettingsDetailsEdit() {
  const organizationData = useGetMyOrganization();
  const { toast } = useToast();
  const navigate = useNavigate();
  const defaultValues = useMemo(
    () => getDefaultValues(organizationData),
    [organizationData],
  );
  const form = useForm<OrganizationSettingsForm>({
    defaultValues,
    resolver: zodResolver(organizationFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "processes",
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const { mutate: updateMyOrganization } =
    trpc.account.organization.updateMyOrganization.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.organization.readMine),
        });
        navigate(OrganizationSettingsRoutes.DETAILS);
      },
    });

  if (!organizationData) {
    return null;
  }

  const onSubmit = (data: OrganizationSettingsForm) => {
    updateMyOrganization({
      customProcesses: {
        added: data.processes
          .filter((p) => p.isCustom && p.processContent)
          .map((p) => ({
            compatibleKits: p.compatibleKits ?? [],
            content: p.processContent ?? "",
            label: p.label,
            process: p.process,
          })),
        removed: (organizationData?.configuration?.customProcesses ?? [])
          .filter((p) => !data.processes.some((p2) => p2.id === p.id))
          .map((p) => p.id ?? ""),
      },
      name: data.name,
    });
  };

  return (
    <div>
      <Form {...form}>
        <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-end space-x-2">
            <Button
              className="space-x-1"
              onClick={() => navigate(OrganizationSettingsRoutes.DETAILS)}
              variant={"secondary"}
            >
              <span>Cancel</span>
              <Undo />
            </Button>
            <Button className="space-x-1" type="submit">
              <span>Save</span>
              <SaveIcon />
            </Button>
          </div>
          <Card>
            <CardHeader>
              <CardTitle>Details</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input placeholder="Corporation" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
          <EditProcesses
            addProcess={append}
            processes={fields}
            removeAtIndex={remove}
          />
        </form>
      </Form>
    </div>
  );
}
