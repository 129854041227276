import type { WorkflowWell } from "./types";

const getSelectedWells = (wellStart: string, wellEnd: string) => {
  const startRow = wellStart[0].charCodeAt(0);
  const endRow = wellEnd[0].charCodeAt(0);
  const startColumn = parseInt(wellStart.slice(1));
  const endColumn = parseInt(wellEnd.slice(1));

  const selectedWells = [];
  for (let i = startRow; i <= endRow; i++) {
    for (let j = startColumn; j <= endColumn; j++) {
      selectedWells.push(`${String.fromCharCode(i)}${j}`);
    }
  }
  return selectedWells;
};

const getEdges = (edge1: string, edge2: string) => {
  const edge1Row = edge1[0].charCodeAt(0);
  const edge2Row = edge2[0].charCodeAt(0);
  const edge1Column = parseInt(edge1.slice(1));
  const edge2Column = parseInt(edge2.slice(1));
  const upperLeftEdge = `${String.fromCharCode(
    Math.min(edge1Row, edge2Row),
  )}${Math.min(edge1Column, edge2Column)}`;
  const lowerRightEdge = `${String.fromCharCode(
    Math.max(edge1Row, edge2Row),
  )}${Math.max(edge1Column, edge2Column)}`;
  return { lowerRightEdge, upperLeftEdge };
};

export const getSelectedWellsFromEdges = (edge1: string, edge2: string) => {
  const { upperLeftEdge, lowerRightEdge } = getEdges(edge1, edge2);
  return getSelectedWells(upperLeftEdge, lowerRightEdge);
};

export const isWellFilteredOut = (
  sequenceSearch: string,
  well: WorkflowWell | undefined,
): boolean => {
  if (!sequenceSearch) {
    return false;
  }
  if (!well) {
    return true;
  }
  const { sequence, name } = well;
  const search = sequenceSearch.toLowerCase();
  return (
    !sequence.toLowerCase().includes(search) &&
    !name.toLowerCase().includes(search)
  );
};
