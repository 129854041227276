import SidebarMultiWells from "./sidebarMultiWells";
import { PlateSidebarWell } from "./sidebarSingleWell";
import type { PlateKit, WorkflowWell } from "./types";

export default function PlateSidebar({
  selectedWell,
  selectedWells,
  handleChangeWell,
  handleChangeWells,
  handleDeleteWell,
  handleDeleteWells,
  handleResetWell,
  isEditable,
  kitProperties,
}: {
  handleChangeWell: (index: string, well: WorkflowWell) => void;
  handleChangeWells: (wells: WorkflowWell[]) => void;
  handleDeleteWell: (index: string) => void;
  handleDeleteWells: (indices: string[]) => void;
  handleResetWell: (index: string) => void;
  isEditable: boolean;
  kitProperties: PlateKit;
  selectedWell: WorkflowWell | undefined;
  selectedWells: WorkflowWell[];
}) {
  if (selectedWells.length > 0) {
    return (
      <SidebarMultiWells
        handleChangeWells={handleChangeWells}
        handleDeleteWells={handleDeleteWells}
        isEditable={isEditable}
        kitProperties={kitProperties}
        wells={selectedWells}
      />
    );
  }
  if (!selectedWell) {
    return "Click on any well to view a sequence.";
  }
  return (
    <PlateSidebarWell
      handleChangeWell={handleChangeWell}
      handleDeleteWell={handleDeleteWell}
      handleResetWell={handleResetWell}
      isEditable={isEditable}
      kitProperties={kitProperties}
      well={selectedWell}
    />
  );
}
