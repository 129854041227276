import { Pencil } from "lucide-react";
import React from "react";

import type { Role } from "../../config/enums";
import { RoleDescriptions } from "../../routes/settings/organization-settings/constants";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";

export const RoleList = ({ roles }: { roles: Role[] }) => {
  const descriptions: string[] = [];
  roles.forEach((role) => {
    descriptions.push(RoleDescriptions[role]);
  });

  if (descriptions.length === 0) {
    return (
      <div className="relative">
        <Badge variant={"outline"}>No roles</Badge>
      </div>
    );
  }
  return (
    <div className="relative space-x-1 space-y-1">
      {descriptions.map((description) => {
        return (
          <Badge className="text-xs" key={description} variant={"outline"}>
            {description}
          </Badge>
        );
      })}
    </div>
  );
};

const RoleTitle = ({ title = "Roles" }: { title?: string }) => {
  return <h3 className="font-bold">{title}</h3>;
};

export default function Roles({
  roles,
  handleEdit,
  editable,
  title,
}: {
  editable: boolean;
  handleEdit?: () => void;
  roles?: Role[];
  title?: string;
}) {
  if (!roles) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between">
        <RoleTitle title={title} />
        {editable && (
          <Button onClick={handleEdit} variant={"secondary"}>
            <Pencil />
          </Button>
        )}
      </div>
      <RoleList roles={roles} />
    </div>
  );
}

export const RolesEditWithChange = ({
  initialRoles,
  possiblesRoles,
  handleChange,
  title,
}: {
  handleChange: (values: Role[]) => void;
  initialRoles?: Role[];
  possiblesRoles: Role[];
  title?: string;
}) => {
  return (
    <div className="flex flex-col">
      <RoleTitle title={title} />
      <ul className="space-y-2">
        {possiblesRoles.map((possibleRole, index) => (
          <React.Fragment key={possibleRole}>
            {index > 0 && <hr />}
            <li className="flex flex-row items-center space-x-1">
              <Label htmlFor={possibleRole}>{possibleRole}</Label>
              <Checkbox
                checked={initialRoles?.includes(possibleRole)}
                className="border-secondary data-[state=checked]:bg-secondary"
                id={possibleRole}
                onCheckedChange={(value) => {
                  const newRoles = value
                    ? [...(initialRoles ?? []), possibleRole]
                    : initialRoles?.filter((role) => role !== possibleRole) ??
                      [];
                  handleChange(newRoles);
                }}
                type="button"
              />
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};
