import { Heading } from "@radix-ui/themes";
import type { RouteObject } from "react-router-dom";
import { Navigate, Outlet, useParams } from "react-router-dom";

import Admin from "./admin";
import AllInstruments from "./instruments/all-instruments";
import NewOrganization from "./organizations/new-organization";
import Organization from "./organizations/organization/organization";
import OrganizationAdminSettings from "./organizations/organization/organization-admin-settings";
import OrganizationMembers from "./organizations/organization/organization-members";
import OrganizationMembersInvite from "./organizations/organization/organization-members-invite";
import OrganizationSettingsEdit from "./organizations/organization/organization-settings-edit";
import OrganizationSupport from "./organizations/organization/organization-support";
import Organizations from "./organizations/organizations";
import NewUser from "./users/new-user";
import User from "./users/user";
import UserUpdate from "./users/user-update";
import Users from "./users/users";

import { RootRoutes } from "../routes";
import UserPermissionProtectedRoute from "../user-permission-protected-route";

export const AdminRoutes = {
  INSTRUMENTS: "/admin/instruments",
  NEW_ORGANIZATION: "/admin/new-organization",
  NEW_USER: "/admin/new-user",
  ORGANIZATION: "/admin/organizations/:organizationId",
  ORGANIZATIONS: "/admin/organizations",
  ORGANIZATION_MEMBERS: "/admin/organizations/:organizationId/members",
  ORGANIZATION_MEMBERS_INVITE: "/admin/organizations/:organizationId/invite",
  ORGANIZATION_SETTINGS: "/admin/organizations/:organizationId/settings",
  ORGANIZATION_SETTINGS_EDIT:
    "/admin/organizations/:organizationId/settings/edit",
  ORGANIZATION_SUPPORT: "/admin/organizations/:organizationId/support",
  SERVICE: "/admin/service",
  USER: "/admin/users/:userId",
  USERS: "/admin/users",
  USER_UPDATE: "/admin/users/:userId/update",
} as const;

export type AdminRoute = (typeof AdminRoutes)[keyof typeof AdminRoutes];

// eslint-disable-next-line react-refresh/only-export-components
export const ADMIN_TABS = [
  {
    id: "organizations",
    link: "Organizations",
    path: AdminRoutes.ORGANIZATIONS,
  },
  {
    id: "users",
    link: "Users",
    path: AdminRoutes.USERS,
  },
  {
    id: "instruments",
    link: "Instruments",
    path: AdminRoutes.INSTRUMENTS,
  },
  {
    id: "service",
    link: "Service",
    path: AdminRoutes.SERVICE,
  },
];

const NavigateToSettings = () => {
  const { organizationId } = useParams();
  const currentTab = window.location.pathname.split("/").pop() ?? "";
  if (currentTab === organizationId) {
    return <Navigate to={window.location.pathname + "/settings"} />;
  }
  return null;
};

// eslint-disable-next-line react-refresh/only-export-components
export const AdminRouter: RouteObject = {
  children: [
    {
      element: <Navigate replace to={AdminRoutes.ORGANIZATIONS} />,
      index: true,
    },
    {
      children: [
        {
          element: <Organizations />,
          index: true,
        },
        {
          children: [
            {
              element: <NavigateToSettings />,
              index: true,
            },
            {
              element: <OrganizationAdminSettings />,
              handle: {
                crumb: "Settings",
              },
              path: AdminRoutes.ORGANIZATION_SETTINGS,
            },
            {
              element: <OrganizationSettingsEdit />,
              handle: {
                crumb: "Edit settings",
              },
              path: AdminRoutes.ORGANIZATION_SETTINGS_EDIT,
            },
            {
              element: <OrganizationMembers />,
              handle: {
                crumb: "Members",
              },
              path: AdminRoutes.ORGANIZATION_MEMBERS,
            },
            {
              element: <OrganizationSupport />,
              handle: {
                crumb: "Support",
              },
              path: AdminRoutes.ORGANIZATION_SUPPORT,
            },
            {
              element: <OrganizationMembersInvite />,
              handle: {
                crumb: "Invite member",
              },
              path: AdminRoutes.ORGANIZATION_MEMBERS_INVITE,
            },
          ],
          element: <Organization />,
          handle: {
            crumb: (data: { organizationId: string }) => data.organizationId,
          },
          path: AdminRoutes.ORGANIZATION,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Organizations",
      },
      path: AdminRoutes.ORGANIZATIONS,
    },
    {
      element: <AllInstruments />,
      handle: {
        crumb: "Instruments dashboard",
      },
      path: AdminRoutes.INSTRUMENTS,
    },
    {
      children: [
        {
          element: <Users />,
          handle: {
            crumb: "Users",
          },
          index: true,
        },
        {
          children: [
            {
              element: <User />,
              handle: {
                crumb: "View",
              },
              index: true,
            },
            {
              element: <UserUpdate />,
              handle: {
                crumb: "Edit",
              },
              path: AdminRoutes.USER_UPDATE,
            },
          ],
          element: <Outlet />,
          handle: {
            crumb: (data: { userId: string }) => data.userId,
          },
          path: AdminRoutes.USER,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Users",
      },
      path: AdminRoutes.USERS,
    },
    {
      element: <Heading>Service</Heading>,
      handle: {
        crumb: "Service",
      },
      path: AdminRoutes.SERVICE,
    },
    {
      element: <NewOrganization />,
      handle: {
        crumb: "New organization",
      },
      path: AdminRoutes.NEW_ORGANIZATION,
    },
    {
      element: <NewUser />,
      handle: {
        crumb: "New user",
      },
      path: AdminRoutes.NEW_USER,
    },
  ],
  element: (
    <UserPermissionProtectedRoute>
      <Admin />
    </UserPermissionProtectedRoute>
  ),
  handle: {
    crumb: "Admin",
  },
  path: RootRoutes.ADMIN,
};
