import { Heading } from "@radix-ui/themes";

import { EditableText } from "../../../../../components/logic/editable-text";
import { trpc } from "../../../../../config/trpc";

export default function WorkflowStepName({
  workflowStepId,
  currentName,
}: {
  currentName: string;
  workflowStepId: string;
}) {
  const utils = trpc.useUtils();
  const { mutate: updateName } = trpc.assay.steps.rename.useMutation({
    onSuccess(_, variables) {
      utils.assay.steps.get.setData(workflowStepId, (prev) => {
        if (!prev) {
          return undefined;
        }
        return {
          ...prev,
          name: variables.name,
        };
      });
    },
  });
  const handleUpdateName = (newName: string) => {
    if (!workflowStepId) {
      return;
    }
    updateName({
      id: workflowStepId,
      name: newName,
    });
  };

  return (
    <Heading className="flex flex-row items-center space-x-2">
      <EditableText
        defaultValue={currentName ?? ""}
        onSubmit={handleUpdateName}
      />
    </Heading>
  );
}
