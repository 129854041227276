import {
  RotateCcw,
  ArrowUpFromLine,
  Trash2Icon,
  Unlock,
  QrCode,
} from "lucide-react";

import { useActivateOrDeactivateOrgMember } from "./useActivateOrDeactivateOrgMember";

import CopyToClipboard from "../../../../components/ui/clipboard";
import { useToast } from "../../../../components/ui/use-toast";
import { trpc } from "../../../../config/trpc";

export const DeactivateMemberButton = ({ id }: { id: string }) => {
  const deactivateMember = useActivateOrDeactivateOrgMember(id, false);

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={deactivateMember}
    >
      <Trash2Icon />
      <span>Deactivate the user</span>
    </div>
  );
};

export const ActivateMemberButton = ({ id }: { id: string }) => {
  const activateMember = useActivateOrDeactivateOrgMember(id, true);

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={activateMember}
    >
      <ArrowUpFromLine />
      <span>Activate the user</span>
    </div>
  );
};

export const DeactivateMFAButton = ({ userId }: { userId: string }) => {
  const { toast } = useToast();

  const { mutate: removeMFA } = trpc.account.user.mfa.remove.useMutation({
    onSuccess() {
      toast({
        title: "MFA deactivated for user",
        variant: "success",
      });
    },
  });

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={() => {
        removeMFA({
          userId,
        });
      }}
    >
      <QrCode />
      <span>Deactivate MFA code</span>
    </div>
  );
};

export const ForcePasswordChangeButton = ({ userId }: { userId: string }) => {
  const { toast } = useToast();
  const { mutate: updateUser } = trpc.account.user.update.useMutation({
    onSuccess() {
      toast({
        title: "Password changed for user",
        variant: "success",
      });
    },
  });

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={() => {
        updateUser({
          id: userId,
          setPasswordAtLogin: true,
        });
      }}
    >
      <RotateCcw />
      <span>Force password change</span>
    </div>
  );
};

export const ResetPasswordButton = ({ email }: { email: string }) => {
  const { toast } = useToast();

  const { mutate: resetUserPassword } =
    trpc.account.user.resetPassword.useMutation({
      onSuccess(newPassword) {
        toast({
          description: (
            <p>
              <p>{`New password for user ${email} is: `}</p>
              <p className="flex flex-row items-center space-x-1">
                <span className="font-['Consolas']">{newPassword}</span>
                <CopyToClipboard value={newPassword} />
              </p>
            </p>
          ),
          duration: 20 * 1000,
          title: "Password reset successfully",
          variant: "success",
        });
      },
    });

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={() => {
        resetUserPassword(email);
      }}
    >
      <Unlock />
      <span>Reset password</span>
    </div>
  );
};
