import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";

import NewOrder from "./components/new-order";

import { DataTable } from "../../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { trpc } from "../../../../config/trpc";
import type { AppRouter } from "../../../../config/trpc";

type OrderFromList = inferProcedureOutput<AppRouter["order"]["list"]>[number];

const columns: ColumnDef<OrderFromList>[] = [
  {
    accessorKey: "id",
    cell: ({ row }) => row.original.id.toString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "id",
    meta: {
      title: "ID",
    },
  },
  {
    accessorKey: "netsuiteWorkOrderId",
    cell: ({ row }) => row.original.netsuiteWorkOrderId.toString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "netsuiteWorkOrderId",
    meta: {
      title: "Netsuite WO ID",
    },
  },
  {
    accessorKey: "expectedCompletionDate",
    cell: ({ row }) =>
      new Date(row.original.expectedCompletionDate).toLocaleDateString(),
    header: "Expected completion date",
  },
];

export default function Orders() {
  const { data: orders } = trpc.order.list.useQuery();

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between">
        <Heading>Orders</Heading>
        <div className="justify-end">
          <NewOrder />
        </div>
      </div>
      <DataTable columns={columns} data={orders ?? []} />
    </div>
  );
}
