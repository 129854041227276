import { useParams } from "react-router-dom";

import { trpc } from "../../../../config/trpc";

export const useGetWorkflowStep = () => {
  const { stepId } = useParams();

  return useGetWorkflowStepWithId(stepId);
};

const useGetWorkflowStepWithId = (stepId?: string) => {
  return trpc.assay.steps.get.useQuery(stepId ?? "", {
    enabled: !!stepId,
  });
};
