import { Heading } from "@radix-ui/themes";
import { Loader2Icon, Redo2Icon, SaveAllIcon, Undo2Icon } from "lucide-react";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";

import Column from "./plate/column";
import { DISPLAY_COLUMNS_AND_ROWS } from "./plate/display";
import PlateSidebar from "./plate/plateSidebar";
import { isWellFilteredOut } from "./plate/selection";
import type { PlateFromWorkflow, WorkflowWell } from "./plate/types";
import { useSelectWell } from "./plate/useSelectWell";
import Well from "./plate/well";
import PlateErrors from "./plate-errors";
import { useDisplaySelector } from "./useDisplaySelector";
import { useEditWells } from "./useEditWells";
import { getColumns, getRows, useGetAllWellsById } from "./useGetAllWellsById";
import { useKeyboardPlateNavigation } from "./useKeyboardPlateNavigation";
import { useSaveWellUpdates } from "./useSaveWellUpdates";
import useZoom from "./useZoom";

import { Button } from "../../../../../components/ui/button";
import { Input } from "../../../../../components/ui/input";
import { Separator } from "../../../../../components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { useToast } from "../../../../../components/ui/use-toast";
import { PlateSize } from "../../../../../config/enums";
import type { AssayTRPC } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";
import { hasModifier } from "../../../../../utils/parser";

export default function Plate({
  isEditable,
  plate,
  assay,
}: {
  assay: AssayTRPC | undefined;
  isEditable: boolean;
  plate: PlateFromWorkflow;
}) {
  const { stepId } = useParams();
  const { toast } = useToast();
  const [plateValidationProgress, setPlateValidationProgress] = useState<{
    progress: number;
    stage: string;
  } | null>(null);
  const [sequenceSearch, setSequenceSearch] = useState<string>("");
  const { size, kitProperties } = plate;

  const { handleClickOnWell, setWellSelected, wellSelected, wellsSelected } =
    useSelectWell();

  const hasResults = plate.wells.some((well) => Boolean(well.result));
  const hasModifiers = plate.wells.some((well) => hasModifier(well.sequence));
  const {
    display,
    node: displaySelector,
    legend,
  } = useDisplaySelector(hasResults, hasModifiers, kitProperties);
  const { zoom, zoomElement } = useZoom();

  const {
    canRedo,
    canUndo,
    deletedWells,
    handleChangeWell,
    handleChangeWells,
    handleDeleteWell,
    handleDeleteWells,
    handleMoveColumn,
    handleMoveWell,
    handleResetWell,
    hasChanges,
    newWells,
    redo,
    undo,
    wells,
    wellsWithChanges,
  } = useEditWells(plate.wells, plate.size);

  trpc.assay.steps.plate.validationProgress.useSubscription(
    { stepId: stepId ?? "" },
    {
      enabled: Boolean(stepId),
      onData(data) {
        setPlateValidationProgress(data);
      },
      onError(error) {
        toast({
          description: `Error: ${error.message}`,
          title: "Screening",
          variant: "destructive",
        });
      },
    },
  );

  const { handleSaveWells, isPending } = useSaveWellUpdates();
  const selectedWellRef = useKeyboardPlateNavigation(
    size,
    wellSelected,
    setWellSelected,
    Boolean(wellSelected),
  );

  const stageOfAsynchronousValidation = plateValidationProgress?.stage;
  const isMakingAsynchronousValidation = Boolean(
    plateValidationProgress &&
      plateValidationProgress.progress >= 0 &&
      stageOfAsynchronousValidation !== "Done",
  );

  const columns = getColumns(size);
  const rows = getRows(size);
  const filledWellsByWellId = useGetAllWellsById(size, wells);
  const selectedWell = wellSelected
    ? filledWellsByWellId.get(wellSelected ?? "")
    : undefined;
  const selectedWells = wellsSelected
    ? wellsSelected
        .map((w) => filledWellsByWellId.get(w))
        .filter((w): w is WorkflowWell => Boolean(w))
    : [];

  return (
    <div className="space-y-4 rounded-lg border bg-white p-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex h-6 flex-row items-center space-x-2">
          <Heading>Plate</Heading>
          <Separator orientation="vertical" />
          {displaySelector}
          <Input
            className="h-[32px] w-[200px]"
            onChange={(e) => setSequenceSearch(e.target.value)}
            placeholder="Search a sequence..."
            value={sequenceSearch}
          />
          <PlateErrors plate={plate} />
          {isMakingAsynchronousValidation && (
            <p className="flex flex-row items-center space-x-1">
              <Loader2Icon className="animate-spin" />
              <span className="font-bold">Validating plate</span>
              <span className="italic">{`step: ${stageOfAsynchronousValidation}`}</span>
            </p>
          )}
          {zoomElement}
        </div>
        <div className="flex h-6 flex-row items-center space-x-2">
          <div>
            <Button disabled={!canUndo} onClick={undo} variant={"ghost"}>
              <Undo2Icon />
            </Button>
            <Button disabled={!canRedo} onClick={redo} variant={"ghost"}>
              <Redo2Icon />
            </Button>
          </div>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                disabled={
                  !hasChanges || isPending || !isEditable || assay?.archived
                }
                isLoading={isPending}
                onClick={() => {
                  handleSaveWells(wells);
                }}
              >
                <SaveAllIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Save all changes</TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-2">
        <DndProvider backend={HTML5Backend}>
          <div className="col-span-2 overflow-auto">
            <div
              className={cn(
                `grid-cols-${DISPLAY_COLUMNS_AND_ROWS[size].columns} grid h-fit auto-cols-fr grid-flow-col gap-1 p-2`,
                size === PlateSize.S384 && "text-sm",
              )}
              style={{
                transform: `scale(${zoom})`,
                transformOrigin: "top left",
                transition: "transform 0.3s ease",
              }}
            >
              <div
                className={`grid-rows-${DISPLAY_COLUMNS_AND_ROWS[size].rows} grid auto-rows-fr gap-1`}
              >
                {legend}
                {rows.map((r) => (
                  <div className="flex items-center justify-center" key={r}>
                    {r}
                  </div>
                ))}
              </div>
              {columns.map((column) => {
                return (
                  <Column
                    column={column}
                    handleMoveColumn={handleMoveColumn}
                    isEditable={isEditable}
                    key={column}
                    size={size}
                  >
                    {rows.map((row) => {
                      const wellIndex = `${row}${column}`;
                      const well = filledWellsByWellId.get(wellIndex);
                      const isSelected = Boolean(
                        wellIndex === wellSelected ||
                          wellsSelected?.includes(wellIndex),
                      );
                      const isNew = newWells.has(wellIndex);
                      const isDeleted = deletedWells.has(wellIndex);
                      const isModified = wellsWithChanges.has(wellIndex);
                      const isFilteredOut = isWellFilteredOut(
                        sequenceSearch,
                        well,
                      );
                      const key = `${wellIndex}${JSON.stringify(well)}`;

                      return (
                        <Well
                          display={display}
                          handleClickOnWell={handleClickOnWell}
                          handleMoveWell={handleMoveWell}
                          isDeleted={isDeleted}
                          isEditable={isEditable}
                          isFilteredOut={isFilteredOut}
                          isModified={isModified}
                          isNew={isNew}
                          isSelected={isSelected}
                          key={key}
                          kitProperties={kitProperties}
                          ref={isSelected ? selectedWellRef : undefined}
                          size={size}
                          well={well}
                          wellIndex={wellIndex}
                        />
                      );
                    })}
                  </Column>
                );
              })}
            </div>
          </div>
        </DndProvider>
        <div className="col-span-1 flex flex-row">
          <Separator orientation="vertical" />
          <div className="w-full p-2">
            <PlateSidebar
              handleChangeWell={handleChangeWell}
              handleChangeWells={handleChangeWells}
              handleDeleteWell={handleDeleteWell}
              handleDeleteWells={handleDeleteWells}
              handleResetWell={handleResetWell}
              isEditable={isEditable}
              kitProperties={kitProperties}
              selectedWell={selectedWell}
              selectedWells={selectedWells}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
