import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PageWithWallPaper from "./components/page-with-wallpaper";
import { RootRoutes } from "./routes";
import { SettingRoutes } from "./settings/setting-routes";

import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import CustomLink from "../components/ui/custom-link";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { useAuth } from "../containers/hooks";
import { getRouteToGoToAfterLogin, parseJwt } from "../utils/auth";

export default function MfaRecovery() {
  const { setToken } = useAuth();
  const { toast } = useToast();
  const form = useForm<{ recoveryCode: string }>({
    defaultValues: {
      recoveryCode: "",
    },
  });

  const navigate = useNavigate();

  const { mutate: recoverMfaCode } =
    trpc.account.user.mfa.recoverToken.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(token) {
        if (!token) {
          throw new Error("Invalid recovery code");
        }
        toast({
          description: (
            <div>
              <span>Please set a new MFA device again, on</span>
              <br />
              <CustomLink to={SettingRoutes.PROFILE}>your profile</CustomLink>
            </div>
          ),
          title: "Authentication with recovery code success",
          variant: "success",
        });
        setToken(token);
        navigate(getRouteToGoToAfterLogin(parseJwt(token)));
      },
    });

  const onSubmit: SubmitHandler<{ recoveryCode: string }> = (data) => {
    recoverMfaCode({
      recoveryCode: data.recoveryCode,
    });
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <PageWithWallPaper>
        <Card>
          <CardHeader>
            <CardTitle>MFA Recovery code</CardTitle>
            <CardDescription className="w-[400px]">
              Enter your recovery code. Please be aware that current MFA
              configuration will be cleared if you continue and you need to
              reset it in your profile after login.
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col space-y-4">
            <div className="space-y-4">
              <div className="grid gap-2">
                <Input
                  type="text"
                  {...form.register("recoveryCode", { required: true })}
                />
              </div>
            </div>
            <Button type="submit">Submit</Button>
            <Button
              onClick={() => {
                navigate(RootRoutes.MFA);
              }}
              variant={"ghost"}
            >
              Back to MFA token
            </Button>
          </CardContent>
        </Card>
      </PageWithWallPaper>
    </form>
  );
}
