import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";

import { Button } from "./button";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "./input-otp";

export const MfaCodeInput = ({
  buttonTitle,
  onSubmit,
}: {
  buttonTitle?: string;
  onSubmit: SubmitHandler<{ token: string }>;
}) => {
  const validationForm = useForm<{ token: string }>({
    defaultValues: {
      token: "",
    },
  });

  const validationToken = validationForm.watch("token");

  return (
    <form onSubmit={validationForm.handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center space-y-4">
        <InputOTP
          maxLength={6}
          onChange={(newValue) => {
            validationForm.setValue("token", newValue);
          }}
          value={validationToken}
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>
        <Button
          className="w-full"
          disabled={validationToken.length < 6}
          size="lg"
          type="submit"
        >
          {buttonTitle ?? "Verify"}
        </Button>
      </div>
    </form>
  );
};
