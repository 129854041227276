import { v4 } from "uuid";

import type { Oligo, Plate, Well } from "./types";

import { PlateSize } from "../../../../../config/enums";
import { PLATE_SIZES } from "../components/useGetAllWellsById";

function generateIndex(index: number, rows: number): string {
  const column = Math.floor(index / rows) + 1;
  const row = String.fromCharCode(65 + Math.floor(index % rows));
  return `${row}${column}`;
}

export const PlateIndices96 = Array.from({ length: 96 }, (_, index) => {
  return generateIndex(index, 8);
});

export const PlateIndices384 = Array.from({ length: 384 }, (_, index) => {
  return generateIndex(index, 16);
});

export const getAuthorizedIndices = (size: PlateSize) =>
  size === PlateSize.S96 ? PlateIndices96 : PlateIndices384;

export function* availableWellIndexGenerator(
  plate: Plate,
  startingIndex: string,
) {
  const plateToUse = getAuthorizedIndices(plate.size);
  const indexInArray = plateToUse.indexOf(startingIndex);
  const plateIndicesUsed = plate.wells
    .filter((w) => w.oligoId)
    .map((w) => w.index);

  const arrayToUse = plateToUse
    .slice(indexInArray)
    .filter((index) => !plateIndicesUsed.includes(index));
  yield* arrayToUse;
}

export function addEmptyWellsToPlate(plate: Plate): Plate {
  const emptyWells = getWells(plate.size);
  return {
    ...plate,
    wells: emptyWells.map((well) => {
      const existingWell = plate.wells.find((w) => w.index === well.index);
      return existingWell ? existingWell : well;
    }),
  };
}

const getWells = (size: PlateSize): Well[] => {
  return Array.from({ length: size === PlateSize.S96 ? 96 : 384 }, (_, i) => {
    const rows = PLATE_SIZES[size].rows;
    const column = Math.floor(i / rows) + 1;
    const row = String.fromCharCode(65 + (i % rows));
    const index = `${row}${column}`;
    return {
      column,
      index,
      oligoId: undefined,
      row,
    };
  });
};

export function createPlate(kit: string, size: PlateSize, name: string): Plate {
  return {
    id: v4(),
    kit: kit,
    locked: false,
    name,
    size,
    wells: getWells(size),
  };
}

export const getWellId = (plateId: string, wellIndex: string) =>
  `${plateId}-${wellIndex}`;

export const getRowColumnFromWellIndex = (wellIndex: string) => {
  const row = wellIndex.slice(0, 1);
  const column = parseInt(wellIndex.slice(1), 10);
  return { column, row };
};

export const preparePlatesForAPI = (plates: Plate[]): Plate[] =>
  plates.map((p) => ({
    ...p,
    wells: p.wells.filter((w) => w.oligoId),
  }));

export const compareIndicesOnPlate = (index1: string, index2: string) => {
  const { column: column1, row: row1 } = getRowColumnFromWellIndex(index1);
  const { column: column2, row: row2 } = getRowColumnFromWellIndex(index2);
  if (column1 < column2) {
    return -1;
  }
  if (column1 === column2) {
    return row1 < row2 ? -1 : 1;
  }
  return 0;
};

export const compareOligosByHintAndIndex = (o1: Oligo, o2: Oligo) => {
  if (o1.platePositioningExpected && !o2.platePositioningExpected) {
    return -1;
  }
  if (!o1.platePositioningExpected && o2.platePositioningExpected) {
    return 1;
  }
  if (o1.platePositioningExpected && o2.platePositioningExpected) {
    return compareIndicesOnPlate(
      o1.platePositioningExpected,
      o2.platePositioningExpected,
    );
  }
  return 0;
};

export const canHintBelongToPlate = (plateSize: PlateSize, hint: string) => {
  const authorizedIndices = getAuthorizedIndices(plateSize);
  return authorizedIndices.includes(hint);
};
