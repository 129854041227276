import { BookPlus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { LoadingSpinner } from "../../../../../components/ui/spinner";
import { PQA } from "../../../../../config/enums";
import { trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";

const PQAData: Record<PQA, { description: string; name: string }> = {
  [PQA.Standard]: {
    description: "96 Standard",
    name: "No labels",
  },
  [PQA.Labels]: {
    description: "96 Standard with labels",
    name: "Labels",
  },
  [PQA.HighPlex]: {
    description: "384 wells",
    name: "High plex",
  },
};

export default function PQAAssay() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { mutate: createPQA, isPending } =
    trpc.assay.createSupportPlate.useMutation({
      onSuccess(path) {
        navigate(path);
      },
    });

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger>
        <Button className="space-x-1">
          <span>PQA</span>
          <BookPlus />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader>
          <DialogTitle>Create PQA assay</DialogTitle>
        </DialogHeader>
        <div
          className={cn(
            "flex flex-col space-y-4",
            isPending ? "pointer-events-none opacity-70" : "",
          )}
        >
          <div className="flex flex-row space-x-8">
            {Object.entries(PQAData).map(([pqa, { name, description }]) => (
              <Button
                className="h-100 flex w-1/2 flex-col items-start justify-between space-y-2 rounded-lg border p-4"
                key={pqa}
                onClick={() => {
                  createPQA(pqa as PQA);
                }}
                variant={"ghost"}
              >
                <span className="text-xl font-bold uppercase">{name}</span>
                <p className="max-w-[400px]">{description}</p>
              </Button>
            ))}
          </div>
          {isPending && (
            <div className="flex flex-row justify-center">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
