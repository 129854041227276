import { type AppRouter } from "@backend/config/trpc/app.router";
import { QueryClient } from "@tanstack/react-query";
import {
  createTRPCReact,
  httpBatchLink,
  splitLink,
  unstable_httpSubscriptionLink,
} from "@trpc/react-query";
import type { inferProcedureOutput } from "@trpc/server";

import { loadAuthToken } from "../state/localStorage";

export const trpc = createTRPCReact<AppRouter>();

export { AppRouter };

export type ConfigTRPC = inferProcedureOutput<AppRouter["config"]["version"]>;
export type AssayTRPC = inferProcedureOutput<AppRouter["assay"]["get"]>;
export type WorkflowTRPC = NonNullable<AssayTRPC["workflow"]>;
export type OwnersTRPC = inferProcedureOutput<
  AppRouter["account"]["authorization"]["owners"]
>;
export type RunOfInstrumentTRPC = inferProcedureOutput<
  AppRouter["instrument"]["syntax"]["listRuns"]
>[number];

const url = "/trpc";
export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    // loggerLink(),
    splitLink({
      condition(op) {
        return op.type === "subscription";
      },
      false: httpBatchLink({
        headers() {
          const token = loadAuthToken();
          return {
            authorization: token ? `Bearer ${token}` : "",
          };
        },
        url,
      }),
      true: unstable_httpSubscriptionLink({
        connectionParams: () => ({
          token: loadAuthToken() ?? "",
        }),
        url,
      }),
    }),
  ],
});
