import { Heading } from "@radix-ui/themes";
import { ArrowRightFromLine, EditIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import Consumables from "./components/consumables";
import InstrumentStatusCard from "./components/instrument-status";
import OwnersBlock from "./components/owners-block";
import { ResetInstrumentPassword } from "./components/reset-password";
import RunsTable from "./components/runs-table";
import { useGetInstrument } from "./useGetInstrument";
import { useGetRuns } from "./useGetRuns";

import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import CopyToClipboard from "../../components/ui/clipboard";
import { Separator } from "../../components/ui/separator";
import { ObjectMembership } from "../../config/enums";
import { trpc } from "../../config/trpc";
import { CheckIfTrueElseCross } from "../admin/organizations/components/ui";
import { Syntax } from "../icons";
import { OrganizationRoutes } from "../organization/organization-routes";

export default function Instrument() {
  const navigate = useNavigate();
  const { data: instrument, isPending } = useGetInstrument();
  const instrumentId = instrument?.id;

  const { data: instrumentOwners } = trpc.account.authorization.owners.useQuery(
    {
      id: instrumentId ?? "",
      subjectType: ObjectMembership.Syntax,
    },
    {
      enabled: !!instrumentId,
    },
  );
  const { data: runs } = useGetRuns(instrumentId);

  if (isPending) return <Heading>Loading...</Heading>;

  if (!instrument) {
    return <Heading>Instrument not found</Heading>;
  }

  return (
    <div className="flex flex-col space-y-8">
      <div className="grid grid-cols-3 gap-1">
        <Heading className="col-span-1 space-x-2 place-self-start">
          <span>Instrument</span>
          <span className="italic">{instrument.name}</span>
        </Heading>
        <Syntax className="bg-card col-span-1 max-h-[100px] max-w-[200px] place-self-center" />
        <div className="col-span-1 flex flex-row justify-end space-x-2">
          <OwnersBlock
            objectId={instrument.id}
            owners={instrumentOwners}
            subject={ObjectMembership.Syntax}
          />
          <Button
            onClick={() => {
              navigate(
                OrganizationRoutes.INSTRUMENT_EDIT.replace(
                  ":instrumentId",
                  instrument.id,
                ),
              );
            }}
            variant={"outline"}
          >
            <EditIcon />
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Details</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            {instrument.createdAt && (
              <>
                <div className="flex flex-row justify-between space-x-2">
                  <p>Created at</p>
                  <p>{new Date(instrument.createdAt).toLocaleString()}</p>
                </div>
                <Separator />
              </>
            )}
            <div className="flex flex-row justify-between space-x-2">
              <p>Name</p>
              <p>{instrument.name}</p>
            </div>
            <Separator />
            <div className="flex flex-row justify-between space-x-2">
              <p>Created by</p>
              <p>{instrument.createdBy}</p>
            </div>
            <Separator />
            <div className="flex flex-row justify-between space-x-2">
              <p>External control</p>
              <CheckIfTrueElseCross
                value={instrument.canBeTriggeredExternally}
              />
            </div>
            <Separator />
            <div className="flex flex-row items-center space-x-4">
              <div className="flex flex-1 flex-row justify-between space-x-2">
                <p>Hardware Version</p>
                <p>{instrument.hardwareVersion}</p>
              </div>
              <Separator className="h-6" orientation="vertical" />
              <div className="flex flex-1 flex-row justify-between space-x-2">
                <p>Software Version</p>
                <p>{instrument.softwareVersion}</p>
              </div>
            </div>
          </CardContent>
        </Card>
        <InstrumentStatusCard instrument={instrument} runs={runs} />
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>
              <div className="flex flex-row items-center justify-between">
                <Heading>Connectivity</Heading>
                <Button
                  className="space-x-1"
                  onClick={() => {
                    navigate(
                      OrganizationRoutes.INSTRUMENT_TROUBLESHOOTING.replace(
                        ":instrumentId",
                        instrument.id,
                      ),
                    );
                  }}
                  variant={"ghost"}
                >
                  <ArrowRightFromLine />
                  <span>Troubleshooting</span>
                </Button>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <div className="flex flex-row justify-between space-x-2">
              <p>Serial number</p>
              <div className="flex flex-row items-center space-x-1">
                <p>{instrument.serialNumber}</p>
                <CopyToClipboard value={instrument.serialNumber} />
              </div>
            </div>
            <Separator />
            <div className="flex flex-row justify-between space-x-2">
              <p>Password</p>
              <div className="flex flex-row items-center space-x-1">
                <p>{instrument.mqttPassword}</p>
                <ResetInstrumentPassword instrumentId={instrument.id} />
                <CopyToClipboard value={instrument.mqttPassword} />
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Reagents</CardTitle>
          </CardHeader>
          <CardContent className="space-y-2">
            <Consumables consumables={instrument.consumables} />
          </CardContent>
        </Card>
      </div>
      <RunsTable instrumentId={instrument.id} runs={runs} />
    </div>
  );
}
