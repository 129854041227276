import { Heading } from "@radix-ui/themes";
import { useNavigate, useRouteError } from "react-router-dom";

import { Button } from "../components/ui/button";

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError() as { message?: string; statusText?: string };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center space-y-2">
      <Heading className="">Oops!</Heading>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <div className="space-x-2">
        <Button onClick={() => window.location.reload()}>Reload</Button>
        <Button onClick={() => navigate(-1)}>Go back</Button>
        <Button onClick={() => navigate("/")}>Home</Button>
      </div>
    </div>
  );
}
