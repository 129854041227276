import { Heading } from "@radix-ui/themes";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { RootRoutes } from "./routes";

import OrganizationProvider from "../containers/OrganizationContainer";
import { useUser } from "../containers/hooks";

export default function OrganizationHome() {
  const userContext = useUser();

  if (!userContext) {
    return <Heading>No user context...</Heading>;
  }

  if (!userContext.membership) {
    return <Navigate to={RootRoutes.PICK_ORGANIZATION} />;
  }

  return (
    <OrganizationProvider>
      <Outlet />
    </OrganizationProvider>
  );
}
