import { Text, Flex, Heading } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

import { Button } from "../components/ui/button";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Flex className="flex h-96 flex-col items-center justify-center" gap={"3"}>
      <Heading>404 Not found</Heading>
      <Text>The page does not exists</Text>
      <div>
        <Button onClick={() => navigate(-1)}>Go back</Button>
      </div>
      <div>
        <Button onClick={() => navigate("/")}>Home</Button>
      </div>
    </Flex>
  );
}
