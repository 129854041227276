import type { Row, Table as TableType } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table";

export type SimpleTableProps<T> = {
  onRowClick?: (row: Row<T>) => void;
  table: TableType<T>;
};

export function SimpleTable<T>({ table, onRowClick }: SimpleTableProps<T>) {
  const rows = table.getRowModel().rows;
  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            className={onRowClick ? "cursor-pointer" : ""}
            key={row.id}
            onClick={() => onRowClick?.(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      {rows.length === 0 && <TableCaption>No data</TableCaption>}
    </Table>
  );
}
