import { Navigate, Outlet } from "react-router-dom";

import NewOrganizationMember from "./organization-settings/new-organization-member";
import NewTeam from "./organization-settings/new-team";
import OrganizationAdmin from "./organization-settings/organization-admin";
import OrganizationMember from "./organization-settings/organization-member";
import OrganizationMembers from "./organization-settings/organization-members";
import OrganizationSettings from "./organization-settings/organization-settings";
import OrganizationSettingsDetails from "./organization-settings/organization-settings-details";
import OrganizationSettingsDetailsEdit from "./organization-settings/organization-settings-details-edit";
import { OrganizationSettingsRoutes } from "./organization-settings/organization-settings-routes";
import OrganizationSupport from "./organization-settings/organization-support";
import Team from "./organization-settings/team";
import Teams from "./organization-settings/teams";

const OrganizationSettingsRouter = {
  children: [
    {
      element: <NewOrganizationMember />,
      handle: {
        crumb: "Invite member",
      },
      path: OrganizationSettingsRoutes.NEW_MEMBER,
    },
    {
      children: [
        {
          element: <OrganizationSettingsDetails />,
          index: true,
        },
        {
          element: <OrganizationSettingsDetailsEdit />,
          handle: {
            crumb: "Edit",
          },
          path: OrganizationSettingsRoutes.DETAILS_EDIT,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Organization",
      },
      path: OrganizationSettingsRoutes.DETAILS,
    },
    {
      children: [
        {
          element: <OrganizationMembers />,
          index: true,
        },
        {
          element: <OrganizationMember />,
          handle: {
            crumb: (params: { userId: string }) => `User ${params.userId}`,
          },
          path: OrganizationSettingsRoutes.USER,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Users",
      },
      path: OrganizationSettingsRoutes.USERS,
    },
    {
      children: [
        {
          element: <Teams />,
          index: true,
        },
        {
          element: <Team />,
          handle: {
            crumb: (params: { teamId: string }) => `Team ${params.teamId}`,
          },
          path: OrganizationSettingsRoutes.TEAM,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Teams",
      },
      path: OrganizationSettingsRoutes.TEAMS,
    },
    {
      element: <NewTeam />,
      handle: {
        crumb: "New team",
      },
      path: OrganizationSettingsRoutes.NEW_TEAM,
    },

    {
      element: <OrganizationSupport />,
      handle: {
        crumb: "Support",
      },
      path: OrganizationSettingsRoutes.SUPPORT,
    },
    {
      element: <OrganizationAdmin />,
      handle: {
        crumb: "Admin",
      },
      path: OrganizationSettingsRoutes.ADMIN,
    },
    {
      element: <Navigate to={OrganizationSettingsRoutes.DETAILS} />,
      index: true,
    },
  ],
  element: <OrganizationSettings />,
  handle: {
    crumb: "Settings",
  },
  path: OrganizationSettingsRoutes.HOME,
};
export default OrganizationSettingsRouter;
