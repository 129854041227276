import { AlertTriangle, CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import QRCode from "react-qr-code";

import { Button } from "../../../components/ui/button";
import CopyToClipboard from "../../../components/ui/clipboard";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { MfaCodeInput } from "../../../components/ui/mfa-code-input";
import { useToast } from "../../../components/ui/use-toast";
import { trpc } from "../../../config/trpc";

const SECRET_REGEX = /secret=([^&]+)/;

export default function AddMFA({ mfaActivated }: { mfaActivated: boolean }) {
  const [open, setOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const { toast } = useToast();
  const utils = trpc.useUtils();

  const { data: mfaUri, refetch: mfaUriRefetch } =
    trpc.account.user.mfa.generateCodeURI.useQuery(undefined, {
      enabled: open,
      initialData: "",
    });

  const mfaCode = (() => {
    if (!mfaUri) return "";
    const matches = mfaUri.match(SECRET_REGEX);
    return matches ? matches[1] : "";
  })();

  const { data: mfaRecoveryCode, refetch: mfaRecoveryCodeRefetch } =
    trpc.account.user.mfa.generateRecoveryCode.useQuery(undefined, {
      enabled: !open,
      initialData: "",
    });

  const { mutate: setMfaCode } = trpc.account.user.mfa.setCode.useMutation({
    onSuccess() {
      toast({
        title: "MFA code added",
        variant: "success",
      });
      utils.account.user.myself.setData(undefined, (prev) =>
        prev
          ? {
              ...prev,
              mfaActivated: true,
            }
          : undefined,
      );
    },
  });

  const handleSubmitMFACode = () => {
    setOpen(false);
    setMfaCode({
      code: mfaCode,
      recoveryCode: mfaRecoveryCode,
    });
  };

  const { mutate: validateNewMFACode } =
    trpc.account.user.mfa.validateNewCode.useMutation({
      onError: (error) => {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(data) {
        setIsValid(data);
        setWasVerified(true);
      },
    });

  const handleValidateMFACode: SubmitHandler<{ token: string }> = (data) => {
    validateNewMFACode({ code: mfaCode, token: data.token });
  };

  const renewPage = () => {
    setIsValid(false);
    setWasVerified(false);
    mfaUriRefetch();
    mfaRecoveryCodeRefetch();
  };

  useEffect(() => {
    renewPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogTrigger asChild>
          <Button
            onClick={() => {
              renewPage();
            }}
            variant={"outline"}
          >
            {mfaActivated ? "Regenerate token" : "Add MFA"}
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-[700px] space-y-4">
          <DialogHeader>
            <DialogTitle>{`Add MFA`}</DialogTitle>
          </DialogHeader>
          {!isValid ? (
            <div className="space-y-4">
              <div className="flex flex-row items-center justify-between space-x-2">
                <p>DNA Script Key</p>
                <div className="flex flex-row items-center space-x-1">
                  <p>{mfaCode}</p>
                  <CopyToClipboard value={mfaCode} />
                </div>
              </div>
              <div>
                <p>Import with QR code</p>
                <QRCode value={mfaUri} />
              </div>
              {wasVerified && (
                <div>
                  <span className="flex text-red-600">
                    Verification failed, please try again
                  </span>
                </div>
              )}
              <div>
                <p>Enter the token from your MFA app</p>
                <MfaCodeInput onSubmit={handleValidateMFACode} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-4">
              <div className="flex flex-col items-center">
                <CheckCircle className="text-green h-20 w-20" />
                <span className="text-green text-lg font-bold">
                  Verification successful
                </span>
              </div>
              <div className="flex items-center space-x-4 rounded-lg p-4">
                <AlertTriangle className="h-10 w-10 text-orange-500" />
                <p className="text-gray-500">
                  Please copy this code and store it wherever you keep other
                  valuable information before submitting. <p /> You can use it
                  to unlock your account in case you lose your MFA device.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-1">
                <p>{mfaRecoveryCode}</p>
                <CopyToClipboard value={mfaRecoveryCode} />
              </div>
              <Button disabled={!isValid} onClick={handleSubmitMFACode}>
                Submit
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
