import * as PopoverPrimitive from "@radix-ui/react-popover";
import type { ChangeEvent } from "react";
import { useMemo, useState } from "react";

import CopyToClipboard from "../../../../../components/ui/clipboard";
import { Input } from "../../../../../components/ui/input";
import { Popover, PopoverContent } from "../../../../../components/ui/popover";
import { Separator } from "../../../../../components/ui/separator";
import type { TextareaProps } from "../../../../../components/ui/textarea";
import {
  TextareaAutoGrow,
  TextareaGrowOnFocus,
} from "../../../../../components/ui/textarea";
import { cn } from "../../../../../lib/utils";
import { getSequenceLength } from "../../../../../utils/parser";
import { useGetModifiers } from "../../../../settings/organization-settings/hooks";

const countSlashes = (input: string) => (input.match(/\//g) || []).length;
const getInsertionIndex = (input1: string, input2: string) => {
  for (let i = 0, l = Math.max(input1.length, input2.length); i < l; i++) {
    if (input1[i] !== input2[i]) return i;
  }
  return -1;
};

export const SequenceInputForCustomers = ({
  value,
  onChange,
  onBlur,
  disabled = false,
}: {
  disabled?: boolean;
  onBlur: () => void;
  onChange: (text: string) => void;
  value?: string;
}) => {
  return (
    <SequenceInput
      TextAreaComponent={TextareaGrowOnFocus}
      disabled={disabled}
      isSmall={true}
      onBlur={onBlur}
      onChange={onChange}
      useModifiers={false}
      value={value as string}
    />
  );
};

export const SequenceInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  useModifiers = true,
  isSmall = false,
  TextAreaComponent = TextareaAutoGrow,
}: {
  TextAreaComponent?: React.ForwardRefExoticComponent<
    TextareaProps & React.RefAttributes<HTMLTextAreaElement>
  >;
  disabled?: boolean;
  isSmall?: boolean;
  onBlur: () => void;
  onChange: (text: string) => void;
  useModifiers?: boolean;
  value?: string;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [insertionIndex, setInsertionIndex] = useState<number | null>(null);
  const modifiers = useGetModifiers(useModifiers);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (useModifiers) {
      const eventValue = e.target.value;
      const eventSlashesCount = countSlashes(eventValue);
      const oldSlashesCount = countSlashes(value ?? "");
      const didJustAddSlash = eventSlashesCount > oldSlashesCount;

      setInsertionIndex(getInsertionIndex(value ?? "", eventValue));
      setShowMenu(didJustAddSlash);
    }

    onChange(e.target.value);
  };

  const handleMenuSelect = (modifier: string) => () => {
    const getNewValue = () => {
      if (insertionIndex === null) return `${value}${modifier}/`;
      const valueBeforeInsertion = value?.slice(0, insertionIndex + 1);
      const valueAfterInsertion = value?.slice(insertionIndex + 1);
      return `${valueBeforeInsertion}${modifier}/${valueAfterInsertion}`;
    };
    const newValue = getNewValue();
    onChange(newValue);
    setShowMenu(false);
  };

  return (
    <div className="group relative space-x-2">
      <TextAreaComponent
        className={cn(
          "h-100 pr-7 font-mono",
          isSmall ? "text-xs" : "text-base",
        )}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        value={value as string}
      />
      <Popover defaultOpen={false} onOpenChange={setShowMenu} open={showMenu}>
        <PopoverPrimitive.Anchor />
        <PopoverContent className="w-[200px] p-0">
          <div>
            <div className="p-2">
              <h3>Choose modifier</h3>
            </div>
            <Separator />
            <ul className="flex flex-col p-0">
              {modifiers.map((mod) => (
                <li key={mod}>
                  <div
                    className="w-full cursor-pointer text-center hover:bg-slate-300"
                    onClick={handleMenuSelect(mod)}
                  >
                    {mod}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </PopoverContent>
      </Popover>

      {value && (
        <span className="absolute right-[0.3rem] top-0">
          {getSequenceLength(value)}
        </span>
      )}
      {value && (
        <div className="absolute bottom-0 right-2 flex items-end opacity-0 group-hover:opacity-100">
          <CopyToClipboard size="xs" value={value} />
        </div>
      )}
    </div>
  );
};

export const PlacementInput = ({
  disabled = false,
  onBlur,
  onChange,
  value,
}: {
  disabled?: boolean;
  onBlur: () => void;
  onChange: (text: string) => void;
  value?: string;
}) => {
  const isValid = useMemo(() => validatePlacement(value), [value]);

  const textAreaStyle = {
    backgroundColor: isValid() ? "white" : "pink",
  };

  return (
    <Input
      className="max-w-[50px]"
      disabled={disabled}
      onBlur={onBlur}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.currentTarget.blur();
        }
      }}
      placeholder="-"
      style={textAreaStyle}
      title={isValid() ? "" : "Placement is not valid"}
      value={value as string}
    />
  );
};

export const BasicInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
}: {
  disabled?: boolean;
  onBlur: () => void;
  onChange: (text: string) => void;
  placeholder?: string;
  value: string;
}) => {
  return (
    <div className="group relative space-x-2">
      <TextareaAutoGrow
        className="pr-7"
        disabled={disabled}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        value={value as string}
      />
      {value && (
        <div className="absolute bottom-0 right-2 flex items-end opacity-0 group-hover:opacity-100">
          <CopyToClipboard size="xs" value={value} />
        </div>
      )}
    </div>
  );
};

const validatePlacement = (placement: string | undefined) => () => {
  if (!placement) {
    return true;
  }
  return /^[A-P]([1-9]|1[0-9]|2[0-4])$/.test(placement);
};
