import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";
import { useInvalidateTags } from "../../../../../containers/user/hooks";
import type { ConstructFromTRPC } from "../constructTypes";
import type { EditConstructForm } from "../edit-construct";

export const useUpdateConstructMetadata = (
  construct: ConstructFromTRPC | undefined,
) => {
  const { toast } = useToast();
  const invalidateTags = useInvalidateTags();

  const constructId = construct?.id;

  const { mutate: updateConstruct } = trpc.construct.updateMetadata.useMutation(
    {
      onError(err) {
        toast({
          description: err.message,
          title: "Error when updating construct",
          variant: "destructive",
        });
      },
      onSuccess(_, input) {
        toast({
          description: "Your construct has been updated",
          title: "Construct updated",
        });
        if (JSON.stringify(input.tags) !== JSON.stringify(construct?.tags)) {
          invalidateTags();
        }
      },
    },
  );

  const handleUpdateConstruct = (data: EditConstructForm) => {
    if (!constructId) {
      return;
    }
    const { description, name, tags } = data;

    return updateConstruct({
      constructId,
      description,

      name,
      tags,
    });
  };

  return handleUpdateConstruct;
};
