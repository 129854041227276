import { ChevronsDown, Dna, EyeOff, Minus } from "lucide-react";

import { PurityFlagToIcon } from "./constants";
import type { Display } from "./display";
import type { WorkflowWell } from "./types";

import {
  ModificationStatus,
  Normalization,
} from "../../../../../../config/enums";
import {
  getSequenceLength,
  parseInitiatorDNASequence,
} from "../../../../../../utils/parser";

const NormalizationNodeInside = ({
  expectedConcentration,
  normalization,
  iconSize,
}: {
  expectedConcentration: number | null | undefined;
  iconSize: number;
  normalization: Normalization;
}) => {
  if (normalization === Normalization.None) {
    return <Minus size={iconSize} />;
  }
  if (normalization === Normalization.Lowest) {
    return <ChevronsDown size={iconSize} />;
  }
  return <span>{expectedConcentration}</span>;
};

const LengthWellInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { initiatorSequence } = parseInitiatorDNASequence(well.sequence);
  return (
    <div className="relative">
      <span>{getSequenceLength(well.sequence)}</span>
      {initiatorSequence && (
        <div className="absolute -right-2 -top-2 text-purple-600">
          <Dna size={(iconSize * 2) / 3} />
        </div>
      )}
    </div>
  );
};

const ConcentrationWellInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { expectedConcentration, normalization } = well;
  return (
    <div className="relative">
      <NormalizationNodeInside
        expectedConcentration={expectedConcentration}
        iconSize={iconSize}
        normalization={normalization}
      />
    </div>
  );
};

const PurityInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { purityFlag } = well;
  const Icon = PurityFlagToIcon[purityFlag];
  return (
    <div className="relative">
      <Icon size={iconSize} />
    </div>
  );
};

const MeasuredConcentrationInside = ({
  well,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { result } = well;
  if (!result) {
    return null;
  }
  return (
    <div className="relative">
      <span>{result.concentration.toFixed(1)}</span>
    </div>
  );
};

const VolumeInside = ({ well }: { well: WorkflowWell }) => {
  const { result } = well;
  if (!result) {
    return null;
  }
  return (
    <div className="relative">
      <span>{Math.floor(result.totalVolume)}</span>
    </div>
  );
};

const YieldInside = ({ well }: { well: WorkflowWell }) => {
  const { result } = well;
  if (!result) {
    return null;
  }
  return (
    <div className="relative">
      <span>{Math.floor(result.yield)}</span>
    </div>
  );
};

const ModificationInside = ({
  well,
  iconSize,
}: {
  iconSize: number;
  well: WorkflowWell;
}) => {
  const { result } = well;
  if (
    !result ||
    result.sequenceModificationStatus !== ModificationStatus.NonQuantifiable
  ) {
    return null;
  }
  return (
    <div className="relative">
      <EyeOff size={iconSize} />
    </div>
  );
};

const DISPLAY_TO_COMPONENT: Record<
  Display,
  React.FC<{ iconSize: number; well: WorkflowWell }>
> = {
  concentration: ConcentrationWellInside,
  length: LengthWellInside,
  measuredConcentration: MeasuredConcentrationInside,
  modification: ModificationInside,
  purity: PurityInside,
  volume: VolumeInside,
  yield: YieldInside,
};

const isWellEmpty = (well: WorkflowWell | undefined) => {
  return !well?.sequence && !well?.name && well?.errors.length === 0;
};

export const WellInside = ({
  well,
  display,
  iconSize = 24,
}: {
  display: Display;
  iconSize?: number;
  well: WorkflowWell | undefined;
}) => {
  if (!well || isWellEmpty(well)) {
    return null;
  }

  const Component = DISPLAY_TO_COMPONENT[display];
  return <Component iconSize={iconSize} well={well} />;
};
