import { Card } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import { noop } from "@tanstack/react-table";
import {
  ArrowDownFromLine,
  FilePlus,
  Info,
  SaveIcon,
  Trash,
  Upload,
} from "lucide-react";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "../../../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardContent,
} from "../../../../components/ui/card";
import Combobox from "../../../../components/ui/combobox";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { readFile } from "../../../../utils/parser";
import { useDownloadFile } from "../../../../utils/useDownload";
import { CheckIfTrueElseCross } from "../../../admin/organizations/components/ui";
import { useGetMyOrganization } from "../hooks";
import type { OrganizationSettingsForm } from "../organization-settings-details-edit";

export default function EditProcesses({
  removeAtIndex,
  processes,
  addProcess,
}: {
  addProcess: (newProcess: OrganizationSettingsForm["processes"][0]) => void;
  processes: OrganizationSettingsForm["processes"];
  removeAtIndex: (index: number) => void;
}) {
  const data = useGetMyOrganization();
  const downloadFile = useDownloadFile();
  const kits = data?.configuration.kits ?? [];
  const [label, setLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [jsonProcess, setJsonProcess] = useState<any | null>();
  const [compatibleKits, setCompatibleKits] = useState<string[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const parseFiles = async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const fileContent = await readFile(file);
      setJsonProcess(JSON.parse(fileContent));
    };
    parseFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/json": [".json"],
    },
    multiple: false,
    noDrag: true,
    onDrop,
  });

  const processColumns: ColumnDef<OrganizationSettingsForm["processes"][0]>[] =
    [
      {
        accessorKey: "label",
        cell: (info) => info.getValue(),
        header: "Label",
        id: "label",
      },
      {
        accessorKey: "isCustom",
        cell: (info) => (
          <CheckIfTrueElseCross value={info.row.original.isCustom} />
        ),
        header: "Is custom ?",
        id: "custom",
      },
      {
        accessorKey: "url",
        cell: (info) => {
          const process = data?.configuration.customProcesses.find(
            (p) => p.process === info.row.original.process,
          );
          if (!process || !process.url) {
            return null;
          }

          return (
            <Button
              onClick={() => {
                if (!process.url) {
                  return;
                }
                downloadFile(process.url, "process-definition.json");
              }}
              variant={"ghost"}
            >
              <ArrowDownFromLine />
            </Button>
          );
        },
        header: "Download",
        id: "custom",
      },
      {
        cell: ({ row }) => {
          return (row.original.compatibleKits ?? [])
            .map((kit) => kits.find((k) => k.kit === kit)?.label || "")
            .join(", ");
        },
        header: "Compatible kits",
        id: "kits",
      },
      {
        cell: ({ row }) => {
          return (
            <TableActions
              items={[
                {
                  alertDialog: {
                    message: "Are you sure you want to remove the process ?",
                    title: "Remove the process",
                  },
                  children: (
                    <div className="flex items-center gap-2">
                      <Trash /> Remove process
                    </div>
                  ),
                  disabled: !row.original.isCustom,
                  id: "remove",
                  onClick: () => removeAtIndex(row.index),
                },
              ]}
            />
          );
        },
        id: "actions",
      },
    ];

  const handleAddProcess = () => {
    addProcess({
      compatibleKits: (compatibleKits ?? []).map((kitLabel) => {
        const kit = kits.find((k) => k.label === kitLabel);
        return kit?.kit ?? "";
      }),
      isCustom: true,
      label,
      process: jsonProcess?.id,
      processContent: JSON.stringify(jsonProcess),
    });
    setOpen(false);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Processes</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-end">
          <Dialog modal={false} onOpenChange={setOpen} open={open}>
            <DialogTrigger>
              <Button aria-label="add-process" variant={"outline"}>
                <div className="flex flex-row items-center space-x-1">
                  <FilePlus />
                  <span>Add a custom process</span>
                </div>
              </Button>
            </DialogTrigger>
            <DialogContent className="min-w-[700px] space-y-4">
              <DialogHeader>
                <DialogTitle>Add process</DialogTitle>
              </DialogHeader>
              <div className="space-y-1">
                <Label htmlFor="label">Label</Label>
                <Input
                  id="label"
                  onChange={(e) => setLabel(e.target.value)}
                  placeholder="My custom process"
                  value={label}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <Label>Compatible kits</Label>
                <Combobox
                  create={false}
                  hasCustomColor={false}
                  options={kits.map((k) => k.label)}
                  setOptions={noop}
                  setValues={setCompatibleKits}
                  values={compatibleKits}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button className="space-x-2" variant={"secondary"}>
                    <span>Upload file</span>
                    <Upload />
                  </Button>
                </div>
                {jsonProcess && (
                  <div>
                    <p className="flex justify-between">
                      <span>Name</span>
                      <span>{jsonProcess?.name}</span>
                    </p>
                    <p className="flex justify-between">
                      <p className="flex flex-row space-x-2">
                        <span>Process id</span>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info />
                          </TooltipTrigger>
                          <TooltipContent>
                            <span>The process id should be unique.</span>
                          </TooltipContent>
                        </Tooltip>
                      </p>
                      <span>{jsonProcess?.id}</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <Button
                  className="flex items-center space-x-1"
                  disabled={!label || !jsonProcess}
                  onClick={handleAddProcess}
                  variant={"outline"}
                >
                  <span>Add</span>
                  <SaveIcon />
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <DataTable columns={processColumns} data={processes} />
      </CardContent>
    </Card>
  );
}
