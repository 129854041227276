import { AlertTriangle } from "lucide-react";
import { useEffect } from "react";

import type { PlateFromWorkflow } from "./plate/types";

import { Label } from "../../../../../components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useGetMyOrganization } from "../../../../settings/organization-settings/hooks";

export const useSelectSyntaxProcess = (
  plate: PlateFromWorkflow,
  selectProcess: (process: string) => void,
) => {
  const data = useGetMyOrganization();
  const organizationKitConfiguration = data?.configuration.kits.filter(
    (k) => k.kit === plate.kit,
  );
  const authorizedProcesses =
    organizationKitConfiguration?.[0]?.allowedProcesses ?? [];
  const defaultOption = authorizedProcesses[0];
  const onlyOneProcess = authorizedProcesses?.length === 1;

  useEffect(() => {
    if (defaultOption) selectProcess(defaultOption.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption]);

  if (authorizedProcesses?.length === 0) {
    return (
      <div className="flex flex-row items-center space-x-2">
        <AlertTriangle />
        <p className="text-sm">No processes set for high plex kit. </p>
      </div>
    );
  }

  return (
    <div>
      <Label htmlFor="process-selection">Choose the process to use</Label>
      <Select
        defaultValue={defaultOption.id}
        disabled={onlyOneProcess}
        onValueChange={selectProcess}
        required
      >
        <SelectTrigger id="process-selection">
          <SelectValue placeholder="Choose process" />
        </SelectTrigger>
        <SelectContent>
          {authorizedProcesses?.map((process) => (
            <SelectItem key={process.process} value={process.id}>
              {process.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
