import { Trash2Icon } from "lucide-react";

import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { trpc } from "../../../../config/trpc";

export const RemoveFromTeamButton = ({ id }: { id: string }) => {
  const { mutate: deleteTeamMember } =
    trpc.account.team.removeFromTeam.useMutation();

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message: "Are you sure you want to deactivate the user ?",
            title: "Deactivate the user",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash2Icon /> Deactivate the user
            </div>
          ),
          id: "deactivate",
          onClick: () => deleteTeamMember({ memberId: id }),
        },
      ]}
    />
  );
};
