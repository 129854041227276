import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { initializeDatadog } from "./datadog";
import { queryClient, trpc, trpcClient } from "./trpc";

import { Button } from "../components/ui/button";
import { useToast } from "../components/ui/use-toast";
import {
  EnvironmentContext,
  useEnvironmentContext,
} from "../containers/contexts";

function ClientProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}

const useInitializeDatadog = () => {
  const environmentContext = useEnvironmentContext();
  useEffect(() => {
    if (!environmentContext.config.isDatadogActivated) {
      return;
    }
    initializeDatadog(environmentContext);
  }, [environmentContext]);
};

function EnvironmentProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  const [previousVersion, setPreviousVersion] = useState<string | undefined>(
    undefined,
  );
  const { toast } = useToast();
  const { data: environmentData, isPending } = trpc.config.version.useQuery(
    undefined,
    {
      refetchInterval: 60 * 1000,
    },
  );

  useEffect(() => {
    if (previousVersion && previousVersion !== environmentData?.version) {
      const { dismiss } = toast({
        className: "w-full",
        description: (
          <div className="flex justify-end">
            <Button
              onClick={() => {
                window.location.reload();
                dismiss();
              }}
              variant={"outline"}
            >
              Click here to refresh
            </Button>
          </div>
        ),
        duration: 24 * 3600 * 1000,
        title: `New version ${environmentData?.version} available`,
        type: "foreground",
        variant: "default",
      });
    }
    setPreviousVersion(environmentData?.version);
  }, [environmentData?.version, previousVersion, toast]);

  if (isPending || !environmentData) {
    return null;
  }

  return (
    <EnvironmentContext.Provider value={environmentData}>
      {children}
    </EnvironmentContext.Provider>
  );
}

function DatadogProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  useInitializeDatadog();

  return <>{children}</>;
}

export default function ConfigInitializer({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  return (
    <ClientProvider>
      <EnvironmentProvider>
        <DatadogProvider>{children}</DatadogProvider>
      </EnvironmentProvider>
    </ClientProvider>
  );
}
