import { SaveIcon, Undo } from "lucide-react";
import { useEffect, useState } from "react";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

type EditableTextProps = {
  defaultValue: string;
  disabled?: boolean;
  onSubmit: (value: string) => void;
};

export const EditableText = ({
  defaultValue,
  onSubmit,
  disabled,
}: EditableTextProps) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = () => {
    onSubmit(value);
    setEditing(false);
  };

  const handleCancel = () => {
    setValue(defaultValue);
    setEditing(false);
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-row items-center space-x-2">
      {editing ? (
        <>
          <Input
            className="w-[400px]"
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={submitOnEnter}
            value={value}
          />
          <Button onClick={handleCancel} size={"sm"} variant={"secondary"}>
            <Undo size={"20"} />
          </Button>
          <Button onClick={handleSubmit} size={"sm"}>
            <SaveIcon size={"20"} />
          </Button>
        </>
      ) : (
        <span
          className={cn(
            "rounded-lg p-2",
            disabled ? "" : "cursor-pointer hover:bg-slate-100",
          )}
          onClick={() => {
            if (disabled) {
              return;
            }
            setEditing(true);
          }}
        >
          {defaultValue}
        </span>
      )}
    </div>
  );
};
