import { RocketIcon } from "lucide-react";
import { useState } from "react";

import type { GeneWorkflowStep } from "./plate/types";

import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function ChooseHamiltonInstrument({
  children,
  step,
}: React.PropsWithChildren<{ step: GeneWorkflowStep }>) {
  const [open, setOpen] = useState(false);
  const [selectedInstrument, setSelectedInstrument] = useState("");
  const { data: hamiltonData } = trpc.instrument.hamilton.list.useQuery();
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: createGeneSynthesis } =
    trpc.assay.steps.gene.assignInstrument.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        utils.assay.steps.get.invalidate(step.id);
        setOpen(false);
      },
    });

  const availableInstruments = hamiltonData ?? [];
  const noAvailableInstruments = availableInstruments.length === 0;

  const handleSubmitForm = () => {
    createGeneSynthesis({
      instrumentId: selectedInstrument,
      stepId: step.id,
    });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Pick hamilton</DialogTitle>
          <DialogDescription>
            Choose a hamilton to run the gene synthesis
          </DialogDescription>
        </DialogHeader>
        {noAvailableInstruments && (
          <p className="text-destructive">There are no instruments available</p>
        )}
        <Select
          disabled={noAvailableInstruments}
          onValueChange={(value) => {
            setSelectedInstrument(value);
          }}
          value={selectedInstrument}
        >
          <SelectTrigger>
            <SelectValue placeholder={"Choose the instrument"} />
          </SelectTrigger>
          <SelectContent>
            {availableInstruments?.map((instrument) => (
              <SelectItem
                className="cursor-pointer"
                key={instrument.id}
                value={instrument.id}
              >
                {instrument.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <DialogFooter>
          <Button
            aria-label="Validate hamilton"
            disabled={noAvailableInstruments || !selectedInstrument}
            onClick={handleSubmitForm}
            type="submit"
          >
            <RocketIcon />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
