import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

import { useEnvironmentContext } from "../containers/contexts";
import { useUser } from "../containers/hooks";

const shouldUseDatadog = () => {
  return import.meta.env.PROD;
};

export const initializeDatadog = ({
  environment,
  version,
}: {
  environment: string;
  version: string;
}) => {
  if (!shouldUseDatadog()) {
    return;
  }
  datadogRum.init({
    allowedTracingUrls: [
      (url) =>
        url.includes("dnascript.com") &&
        (url.endsWith("/trpc") || url.includes("/api")),
    ],
    applicationId: "88aec7b5-6472-46b7-908f-e59edb2d9700",
    clientToken: "pubf2f0ea497512090f033db2c1face34ac",
    defaultPrivacyLevel: "mask-user-input",
    env: environment,
    service: "console",
    sessionReplaySampleRate: 0,
    sessionSampleRate: 100,
    site: "us3.datadoghq.com",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version,
  });

  datadogRum.startSessionReplayRecording();
};

export const useDatadogTracking = () => {
  const user = useUser();
  const environment = useEnvironmentContext();

  const me = user;
  const name = `${me?.firstname ?? ""} ${me?.lastname ?? ""}`;

  useEffect(() => {
    if (
      !shouldUseDatadog() ||
      !name ||
      !environment.config.isDatadogActivated
    ) {
      return;
    }
    datadogRum.setUser({
      email: me?.email,
      id: me?.id,
      name,
    });
  }, [environment.config.isDatadogActivated, me?.email, me?.id, name]);
};
