import { useContext } from "react";

import { trpc } from "../../config/trpc";
import { UserContext } from "../contexts";

export const useGetUIAuthorizedCreations = () => {
  const userContext = useContext(UserContext);

  const { canCreateUser, canCreateOrganization } =
    userContext?.authorizations ?? {};

  return {
    assay: true,
    construct: true,
    hamiltonInstrument: true,
    instrument: true,
    organization: true,
    organizationMember: !!canCreateOrganization,
    plate: true,
    team: true,
    user: !!canCreateUser,
    workflow: true,
  };
};

export const useInvalidateTags = () => {
  const utils = trpc.useUtils();
  return utils.account.user.tags.invalidate;
};
