export function getPercentage(duration: number, elapsed: number) {
  if (duration <= 0) {
    return 0;
  }

  const percentage = Math.floor((100 * elapsed) / duration);
  return percentage > 100 ? 100 : percentage;
}

export function roundNumber(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}
