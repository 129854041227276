import { useNavigate, useParams } from "react-router-dom";

import type { PlateMetrics } from "./plate/types";

import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";
import { Button } from "../../../../../components/ui/button";
import { Separator } from "../../../../../components/ui/separator";
import { trpc } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";

function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes,
  ).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;

  return formattedTime;
}

export default function ReportMetrics({ metrics }: { metrics: PlateMetrics }) {
  const {
    measuredDuration,
    quantificationIssues: yieldError,
    startTime,
    endTime,
    successRate,
  } = metrics;

  const successPercent = (successRate * 100).toFixed(2);
  const navigate = useNavigate();
  const { assayId, stepId } = useParams();

  const { mutate: createFromFailedWells } =
    trpc.assay.steps.plate.createFromFailed.useMutation({
      onSuccess(newStepId) {
        if (!assayId) {
          return;
        }
        navigate(
          OrganizationRoutes.ASSAY_WORKFLOW_STEP.replace(
            ":assayId",
            assayId ?? "",
          ).replace(":stepId", newStepId),
        );
      },
    });

  const handleCreatePlateFromFailedWells = () => {
    if (!stepId) {
      return;
    }
    createFromFailedWells(stepId);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-center space-x-4">
        <div>
          <p className="italic">Passing oligos</p>
          <p className="font-bold text-green-500">{successPercent}%</p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Duration</p>
          <p>{formatTime(measuredDuration)}s</p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Start</p>
          <p>{new Date(startTime).toLocaleString()}</p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">End</p>
          <p>{new Date(endTime).toLocaleString()}</p>
        </div>
      </div>
      <div className="flex flex-row items-center space-x-4">
        <h3>Quantification issues</h3>
        <div>{yieldError}</div>
        {yieldError > 0 && (
          <AlertDialogWrapper
            description="Are you sure you want to create a plate from wells with quantification issues ? This action cannot be undone."
            onConfirm={handleCreatePlateFromFailedWells}
            title="Create plate from wells with issues"
          >
            <Button variant={"outline"}>
              Generate plate from wells with issues
            </Button>
          </AlertDialogWrapper>
        )}
      </div>
    </div>
  );
}
