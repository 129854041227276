import type { inferProcedureOutput } from "@trpc/server";
import { useEffect, useState } from "react";

import type { AppRouter } from "../../config/trpc";
import { useUser } from "../../containers/hooks";
import Combobox, { ComboboxBadge } from "../ui/combobox";

export type TagsData = inferProcedureOutput<
  AppRouter["account"]["user"]["tags"]
>;
type TagKey = keyof TagsData;

const useGetOptions = (type: TagKey) => {
  const tags = useUser()?.tags;
  const [options, setOptions] = useState(tags?.[type] ?? []);

  useEffect(() => {
    setOptions(tags?.[type] ?? []);
  }, [tags, type]);

  return { options, setOptions };
};

export default function TagsSelection({
  values,
  setValues,
  type,
}: {
  setValues: (newValues: string[]) => void;
  type: TagKey;
  values: string[];
}) {
  const { options, setOptions } = useGetOptions(type);

  return (
    <Combobox
      options={options}
      setOptions={setOptions}
      setValues={setValues}
      values={values}
    />
  );
}

export const TagsDisplay = ({ tags }: { tags: string[] }) => {
  return (
    <div className="flex max-w-[300px] flex-wrap">
      {tags.length > 0
        ? tags.map((tag) => (
            <div className="m-1" key={tag}>
              <ComboboxBadge label={tag} />
            </div>
          ))
        : "No tags"}
    </div>
  );
};
