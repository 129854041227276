import type { RequestFormType } from "@backend/config/clientRequest/requestFormSchema";
import { SequenceShippingFormat } from "@backend/config/clientRequest/requestFormSchema";
import { Trash } from "lucide-react";
import { useFormContext, useFieldArray } from "react-hook-form";

import { ShippingFormatComponent } from "./shipping-format";

import { Button } from "../../components/ui/button";
import { FormField, FormItem, FormMessage } from "../../components/ui/form";

export const ShippingFormat = ({
  handleChangeFormat,
  format,
}: {
  format: SequenceShippingFormat;
  handleChangeFormat: (format: SequenceShippingFormat) => void;
  index: number;
  useGene: boolean;
}) => {
  const changeFormat = () => {
    const newFormat =
      format === SequenceShippingFormat.Plate
        ? SequenceShippingFormat.Tube
        : SequenceShippingFormat.Plate;
    handleChangeFormat?.(newFormat);
  };

  return (
    <div className="flex flex-col items-center">
      {ShippingFormatComponent[format]}
      <Button onClick={changeFormat} size={"sm"} variant={"ghost"}>
        Switch
      </Button>
    </div>
  );
};

export const SequenceInfos = ({
  index,
  useGene,
}: {
  index: number;
  useGene: boolean;
}) => {
  const form = useFormContext<RequestFormType>();
  const prefix = useGene ? "geneFragments" : "oligos";

  return (
    <div>
      <FormField
        control={form.control}
        name={`${prefix}.${index}.sequence`}
        render={() => (
          <FormItem>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`${prefix}.${index}.name`}
        render={() => (
          <FormItem>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`${prefix}.${index}.format`}
        render={() => (
          <FormItem>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export const RemoveSequenceFromForm = ({
  index,
  useGene,
}: {
  index: number;
  useGene: boolean;
}) => {
  const form = useFormContext();
  const { remove: removeOligo } = useFieldArray({
    control: form.control,
    name: "oligos",
  });
  const { remove: removeGeneFragment } = useFieldArray({
    control: form.control,
    name: "geneFragments",
  });

  return (
    <Button
      className="space-x-2"
      onClick={() => (useGene ? removeGeneFragment(index) : removeOligo(index))}
      type="button"
      variant={"outline"}
    >
      <Trash />
    </Button>
  );
};
