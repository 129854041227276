import { zodResolver } from "@hookform/resolvers/zod";
import { Heading, Text } from "@radix-ui/themes";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { PasswordInput } from "../components/ui/password";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { useAuth, useUser } from "../containers/hooks";
import { getRouteToGoToAfterLogin, parseJwt } from "../utils/auth";

const setPasswordForm = z
  .object({
    password: z.string().min(8),
    passwordConfirmation: z.string().min(8),
  })
  .superRefine(({ passwordConfirmation, password }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: "custom",
        message: "The passwords do not match",
        path: ["confirm"],
      });
    }
  });

type SetPasswordForm = z.infer<typeof setPasswordForm>;

export default function SetPassword() {
  const { parsedToken, setToken } = useAuth();
  const { toast } = useToast();
  const user = useUser();
  const navigate = useNavigate();
  const form = useForm<SetPasswordForm>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: zodResolver(setPasswordForm),
  });

  if (!parsedToken?.shouldResetPassword) {
    navigate(RootRoutes.ROOT);
  }

  const errorMessage = (form.formState.errors as any)?.["confirm"]?.message;

  const { mutate: setPassword } = trpc.account.user.setPassword.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    onSuccess(data) {
      navigate(RootRoutes.ADMIN);
      const newToken = data.token;
      setToken(newToken);
      navigate(getRouteToGoToAfterLogin(parseJwt(newToken)));
    },
  });

  function onSubmit(values: SetPasswordForm) {
    if (!user?.id) {
      return;
    }
    setPassword({
      id: user?.id ?? "",
      password: values.password,
    });
  }

  return (
    <div className="flex flex-col items-center space-y-8">
      <Heading>Set your password</Heading>
      <Form {...form}>
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center space-y-4">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      {...field}
                      divClassName="w-96"
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="passwordConfirmation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      {...field}
                      divClassName="w-96"
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-row justify-center">
            <Button type="submit">Submit</Button>
          </div>
          <div className="flex flex-row justify-center">
            {errorMessage && (
              <Text className="text-xl text-red-600">{errorMessage}</Text>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
}
