import { IterationCcw } from "lucide-react";

import { AlertDialogWrapper } from "../../../components/ui/alert-dialog";
import { Button } from "../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { useToast } from "../../../components/ui/use-toast";
import { trpc } from "../../../config/trpc";

export const ResetInstrumentPassword = ({
  instrumentId,
}: {
  instrumentId: string;
}) => {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: updateInstrumentPassword } =
    trpc.instrument.syntax.updatePassword.useMutation({
      onSuccess() {
        toast({
          description:
            "The password of the instrument has been reset. Change it in the instrument settings.",
          title: "Password reset",
          variant: "success",
        });
        utils.instrument.syntax.get.invalidate({ instrumentId });
      },
    });

  const resetInstrumentPassword = () => {
    updateInstrumentPassword({
      instrumentId,
    });
  };

  return (
    <AlertDialogWrapper
      description={
        "Are you sure you want to reset the instrument's connection password ?"
      }
      onConfirm={resetInstrumentPassword}
      title={"Reset password"}
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <Button size={"icon"} variant={"ghost"}>
            <IterationCcw />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Reset mqtt password</TooltipContent>
      </Tooltip>
    </AlertDialogWrapper>
  );
};
