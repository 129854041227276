import { getQueryKey } from "@trpc/react-query";
import { useNavigate } from "react-router-dom";

import { OrganizationEditConfiguration } from "./organization/components/organization-edit-configuration";

import { useToast } from "../../../components/ui/use-toast";
import { OrganizationStatus } from "../../../config/enums";
import { queryClient, trpc } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";

export default function NewOrganization() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutate: createOrganization } =
    trpc.account.organization.create.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error creating organization",
          variant: "destructive",
        });
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.organization.list),
        });
        toast({
          description: "The organization has been created successfully",
          title: "Organization created",
          variant: "success",
        });
        navigate(AdminRoutes.ORGANIZATIONS);
      },
    });

  return (
    <OrganizationEditConfiguration
      defaultValues={{
        isActive: true,
      }}
      handleSubmit={(values) => {
        createOrganization({
          bioSecurityEnabled: values.bioSecurityEnabled,
          clicksEnabled: values.clicksEnabled,
          customProcessEnabled: values.customProcessEnabled,
          degenerateBasesEnabled: values.degenerateBasesEnabled,
          geneEnabled: values.geneEnabled,
          kits: values.kitConfiguration,
          mfaMandatory: values.mfaMandatory,
          modsEnabled: values.modsEnabled,
          name: values.name,
          notificationsEnabled: values.notificationsEnabled,
          remoteRunTriggerEnabled: values.remoteRunTriggerEnabled,
          serviceEnabled: values.serviceEnabled,
          status: values.isActive
            ? OrganizationStatus.ACTIVE
            : OrganizationStatus.INACTIVE,
        });
      }}
      onCancel={() => navigate(AdminRoutes.ORGANIZATIONS)}
    />
  );
}
