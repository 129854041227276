import type { inferProcedureOutput } from "@trpc/server";

import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";

export type UserDataTRPC = inferProcedureOutput<
  AppRouter["account"]["user"]["get"]
>;

export const useUserData = (userId: string | undefined) => {
  return trpc.account.user.get.useQuery(
    { id: userId ?? "" },
    {
      enabled: !!userId,
    },
  );
};

export const useGetUsers = () => {
  return trpc.account.user.list.useQuery();
};

export const useGetSupportUsers = () => {
  const { data } = useGetUsers();
  return data?.filter((user: any) => user.isSupport) ?? [];
};
