import { ClipboardCheck, ClipboardCopy } from "lucide-react";
import { useState } from "react";

import { Button } from "./button";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export default function CopyToClipboard({
  value,
  size = "icon",
}: {
  size?: "xs" | "icon";
  value: string;
}) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied((prev) => !prev);
  };
  const message = isCopied ? "Copied" : "Copy to clipboard";

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={handleCopy}
          size={size}
          tabIndex={-1}
          variant={"ghost"}
        >
          {isCopied ? <ClipboardCheck /> : <ClipboardCopy />}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{message}</TooltipContent>
    </Tooltip>
  );
}
