import { zodResolver } from "@hookform/resolvers/zod";
import { ShoppingBasket, UndoIcon, SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../../../components/ui/button";
import { DatePicker } from "../../../../../components/ui/datepicker";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

const createOrderFormSchema = z.object({
  expectedCompletionDate: z.date(),
  netsuiteId: z.string(),
});

type CreateOrderForm = z.infer<typeof createOrderFormSchema>;

export default function NewOrder() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const form = useForm<CreateOrderForm>({
    defaultValues: {
      expectedCompletionDate: new Date(),
      netsuiteId: "",
    },
    resolver: zodResolver(createOrderFormSchema),
  });

  const utils = trpc.useUtils();
  const { mutate: createOrder } = trpc.order.create.useMutation({
    onSuccess() {
      toast({
        description: "The order was successfully created",
        title: "Order created",
        variant: "success",
      });
      setOpen(false);
      utils.order.list.invalidate();
    },
  });

  const handleSubmit = (data: CreateOrderForm) => {
    createOrder({
      expectedCompletionDate: data.expectedCompletionDate.toISOString(),
      items: [],
      netsuiteWorkOrderId: data.netsuiteId,
    });
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button className="flex flex-row items-center space-x-1">
          <span>New order</span>
          <ShoppingBasket />
        </Button>
      </DialogTrigger>
      <Form {...form}>
        <form
          className="flex max-h-[90vh] flex-col space-y-4"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <DialogContent className="min-w-[700px]">
            <DialogHeader>
              <DialogTitle>Import new order</DialogTitle>
            </DialogHeader>
            <FormField
              control={form.control}
              name="netsuiteId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Netsuite work order id</FormLabel>
                  <FormControl>
                    <Input placeholder="WO_32" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="expectedCompletionDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Expected shipped date</FormLabel>
                  <FormControl>
                    <DatePicker
                      date={field.value}
                      options={{ disabled: { before: new Date() } }}
                      placeholder="Pick end date"
                      setDate={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row justify-end space-x-2">
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant={"outline"}
              >
                <UndoIcon />
              </Button>
              <Button
                onClick={() => {
                  form.handleSubmit(handleSubmit)();
                }}
              >
                <SaveIcon />
              </Button>
            </div>
          </DialogContent>
        </form>
      </Form>
    </Dialog>
  );
}
