import { PlayCircle } from "lucide-react";

import ChooseHamiltonInstrument from "./choose-hamilton-instrument";
import ChooseSyntaxInstrument from "./choose-syntax-instrument";
import type { WorkflowStepTRPC } from "./plate/types";
import { getPlateProblems } from "./plateErrors";

import { Button } from "../../../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { StepType, WellErrorSeverity } from "../../../../../config/enums";
import type { AssayTRPC } from "../../../../../config/trpc";

export default function ChooseInstrument({
  step,
  assay,
}: {
  assay: AssayTRPC | undefined;
  step: WorkflowStepTRPC;
}) {
  if (step.stepType === StepType.HamiltonAssembly) {
    const gene = step.gene;

    const allOligosAreSynthesized =
      gene.oligosSuccessfullySynthesized === gene.oligoCount;

    if (!allOligosAreSynthesized) {
      return <h3 className="font-bold">Not all oligos are synthesized</h3>;
    }

    return (
      <ChooseHamiltonInstrument step={step}>
        <Button className="flex flex-row items-center space-x-2">
          <PlayCircle />
          <span>Assign to hamilton</span>
        </Button>
      </ChooseHamiltonInstrument>
    );
  }

  const plate = step.plate;
  const isPlate = Boolean(plate);
  const plateProblems = plate ? getPlateProblems(plate) : [];
  const plateErrors = plateProblems.filter(
    (p) => p.severity === WellErrorSeverity.Error,
  );
  const plateHasError = Boolean(isPlate && plateErrors.length > 0);
  const isUploadToInstrumentDisabled = plateHasError || assay?.archived;

  if (step.stepType === StepType.OligoSynthesis) {
    return (
      <ChooseSyntaxInstrument
        disabled={isUploadToInstrumentDisabled}
        plate={step.plate}
      >
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className="flex flex-row items-center space-x-2"
              disabled={isUploadToInstrumentDisabled}
            >
              <PlayCircle />
              <span>Upload to instrument</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {plateHasError ? (
              <div>
                <h3 className="font-bold">Cannot upload to instrument</h3>
                <ul className="list-disc pl-4">
                  {plateErrors.map((error, i) => (
                    <li key={i}>{error.message}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>
                <h3 className="font-bold">Upload to instrument</h3>
                <p>Upload this plate to an instrument to continue</p>
              </div>
            )}
          </TooltipContent>
        </Tooltip>
      </ChooseSyntaxInstrument>
    );
  }
  return null;
}
