import type { ColumnDef } from "@tanstack/react-table";
import { getQueryKey } from "@trpc/react-query";
import type { inferProcedureOutput } from "@trpc/server";
import { UserPlus2 } from "lucide-react";
import { useState } from "react";

import { Button } from "../../../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardContent,
  Card,
} from "../../../../components/ui/card";
import Combobox from "../../../../components/ui/combobox";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import type { AppRouter } from "../../../../config/trpc";
import { queryClient, trpc } from "../../../../config/trpc";
import { useGetSupportUsers } from "../../users/hooks";
import {
  useGetOrganizationProfile,
  useGetSupportUsersOfOrganization,
} from "../hooks";

export type SupportUser = inferProcedureOutput<
  AppRouter["account"]["organization"]["listSupportUsers"]
>[0];

export default function OrganizationSupport() {
  const { data: organizationProfile } = useGetOrganizationProfile();
  const organizationId = organizationProfile?.id ?? "";
  const [usersToAdd, setUsersToAdd] = useState<string[]>([]);
  const allSupportUsers = useGetSupportUsers();
  const { data: potentialSupportUsers } =
    useGetSupportUsersOfOrganization(organizationId);
  const supportUsersNotInOrganization = allSupportUsers.filter(
    (user) =>
      !potentialSupportUsers?.some((supportUser) => supportUser.id === user.id),
  );
  const currentSupportUserIds =
    potentialSupportUsers.map((user) => user.id) ?? [];

  const { mutate: updateAssignedSupportUsers } =
    trpc.account.organization.updateAssignedSupportUsers.useMutation({
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.organization.read, {
            id: organizationId,
          }),
        });
      },
    });

  const handleAddUsers = () => {
    setUsersToAdd([]);
    const newUserIds =
      usersToAdd.map(
        (email) =>
          allSupportUsers.find((user) => user.email === email)?.id ?? "",
      ) ?? [];
    const currentSupportUserIds =
      potentialSupportUsers.map((user) => user.id) ?? [];
    const userIds = currentSupportUserIds.concat(newUserIds);
    updateAssignedSupportUsers({
      organizationId,
      userIds,
    });
  };

  const handleRemoveUser = (idToRemove: string) => {
    const userIds = currentSupportUserIds.filter((id) => id !== idToRemove);
    updateAssignedSupportUsers({
      organizationId,
      userIds,
    });
  };

  const columns: ColumnDef<SupportUser>[] = [
    {
      accessorKey: "email",
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "email",
      meta: {
        title: "Email",
      },
    },
    {
      accessorFn: (row) => row.name,
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "name",
      meta: {
        title: "Name",
      },
    },
    {
      cell: ({ row }) => {
        return (
          <TableActions
            items={[
              {
                children: "Delete",
                id: "Delete",
                onClick: () => handleRemoveUser(row.original.id),
              },
            ]}
          />
        );
      },
      enableHiding: false,
      id: "actions",
    },
  ];

  if (!organizationProfile) {
    return null;
  }

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle>Support members</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-4">
        <DataTable columns={columns} data={potentialSupportUsers} />
        <div className="flex justify-end space-x-2">
          <Combobox
            create={false}
            hasCustomColor={false}
            inputTitle="Select support users"
            options={supportUsersNotInOrganization.map((user) => user.email)}
            setOptions={() => {}}
            setValues={setUsersToAdd}
            values={usersToAdd}
          />
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={usersToAdd.length === 0}
            onClick={handleAddUsers}
          >
            <span>{`Add user${usersToAdd.length > 1 ? "s" : ""}`}</span>
            <UserPlus2 />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
