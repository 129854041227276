import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "@shared-ui/globals.css";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import ConfigInitializer from "./config/config";
import AuthProvider from "./containers/AuthContainer";
import router from "./routes/router";

export const RouterWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Theme
      accentColor="bronze"
      className="flex min-h-screen flex-col"
      grayColor="gray"
      radius={"large"}
    >
      <ConfigInitializer>
        <AuthProvider>
          <TooltipProvider delayDuration={500}>
            {children}
            <Toaster />
          </TooltipProvider>
        </AuthProvider>
      </ConfigInitializer>
    </Theme>
  );
};

function App() {
  return (
    <RouterWrapper>
      <RouterProvider router={router} />
    </RouterWrapper>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
