export const OrganizationRoutes = {
  ASSAY: "/organization/assays/:assayId",
  ASSAYS: "/organization/assays",
  ASSAY_NEW: "/organization/new-assay",
  ASSAY_PREPARE: "/organization/assays/:assayId/prepare",
  ASSAY_WORKFLOW_STEP: "/organization/assays/:assayId/step/:stepId",
  CONSTRUCT: "/organization/constructs/:constructId",
  CONSTRUCT_NEW: "/organization/new-construct",
  HAMILTON_INSTRUMENT_NEW: "/organization/new-instrument/hamilton",
  INSTRUMENT: "/organization/instruments/:instrumentId",
  INSTRUMENTS: "/organization/instruments",
  INSTRUMENT_EDIT: "/organization/instruments/:instrumentId/edit",
  INSTRUMENT_HAMILTON: "/organization/hamilton/:instrumentId",
  INSTRUMENT_HAMILTON_EDIT: "/organization/hamilton/:instrumentId/edit",
  INSTRUMENT_NEW: "/organization/new-instrument",
  INSTRUMENT_SYNTAX_NEW: "/organization/new-instrument/syntax",
  INSTRUMENT_TROUBLESHOOTING:
    "/organization/instruments/:instrumentId/troubleshooting",
  LIBRARY: "/organization/library",
  ORDERS: "/organization/orders",
  QUICK_PLATE: "/organization/new-quick-plate",
  RUNS: "/organization/runs",
} as const;

export type OrganizationRoute =
  (typeof OrganizationRoutes)[keyof typeof OrganizationRoutes];

type Tab = {
  id: string;
  link: string;
  path: OrganizationRoute;
};

const ORGANIZATION_TABS: Tab[] = [
  {
    id: "constructs",
    link: "Constructs",
    path: OrganizationRoutes.LIBRARY,
  },
  {
    id: "assays",
    link: "Assays",
    path: OrganizationRoutes.ASSAYS,
  },
  {
    id: "instruments",
    link: "Instruments",
    path: OrganizationRoutes.INSTRUMENTS,
  },
  {
    id: "runs",
    link: "Runs",
    path: OrganizationRoutes.RUNS,
  },
];

export const useGetOrganizationTabs = (serviceEnabled: boolean) => {
  if (!serviceEnabled) {
    return ORGANIZATION_TABS;
  }
  return ORGANIZATION_TABS.concat([
    {
      id: "orders",
      link: "Orders",
      path: OrganizationRoutes.ORDERS,
    },
  ]);
};
