import { useDatadogTracking } from "../config/datadog";

const Tracker = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  useDatadogTracking();

  return children;
};

export default Tracker;
