import { SequenceShippingFormat } from "@backend/config/clientRequest/requestFormSchema";
import readXlsxFile from "read-excel-file";
import type { SafeParseReturnType } from "zod";
import { z } from "zod";

const sequenceForm = z.object({
  Format: z.nativeEnum(SequenceShippingFormat).optional(),
  Name: z.string(),
  Sequence: z.string(),
  Well: z.string().optional(),
});

export type SequenceFileRow = z.infer<typeof sequenceForm>;

const sequenceFileForm = z.array(sequenceForm);

export const parseInitiatorDNASequence = (
  text: string,
): { initiatorSequence: string | null; sequence: string } => {
  if (text.includes("+")) {
    const [initiatorSequence, sequence] = text.split("+");
    return {
      initiatorSequence:
        initiatorSequence.length > 0 ? initiatorSequence : null,
      sequence,
    };
  }
  return { initiatorSequence: null, sequence: text };
};

export const removeModifiers = (sequence: string): string => {
  return sequence.replace(/\/.*?\//g, "");
};

export const hasModifier = (sequence: string): boolean => {
  return sequence.includes("/");
};

export const getSequenceLength = (sequence: string): number => {
  return removeModifiers(parseInitiatorDNASequence(sequence).sequence).length;
};

export async function parseSequencesFile(
  file: File,
): Promise<SafeParseReturnType<unknown, SequenceFileRow[]>> {
  const fileName = file.name;
  if (fileName.endsWith(".fasta")) {
    const parsedFastaFile = await parseFastaFile(file);
    return sequenceFileForm.safeParse(parsedFastaFile);
  }
  if (fileName.endsWith(".csv")) {
    const parsedCSVFile = await parseCSVFile(file);
    return sequenceFileForm.safeParse(parsedCSVFile);
  }
  if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
    const parsedXLSXFile = await parseXLSXFile(file);
    return sequenceFileForm.safeParse(parsedXLSXFile);
  }
  throw new Error("Unsupported file type");
}

export function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      resolve(result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
}

async function parseFastaFile(file: File): Promise<SequenceFileRow[]> {
  const content = await readFile(file);
  return parseFastaString(content);
}

function parseFastaString(fasta: string): SequenceFileRow[] {
  const rows = fasta.split("\n").map((line) => line.trim());
  const sequences: SequenceFileRow[] = [];
  let sequenceName = "";
  for (const row of rows) {
    if (!row || row.startsWith(";")) continue;
    if (row.startsWith(">")) {
      sequenceName = row.slice(1);
    } else {
      const sequence = row.endsWith("*") ? row.slice(0, -1) : row;
      sequences.push({ Name: sequenceName, Sequence: sequence });
    }
  }
  return sequences;
}

async function parseCSVFile(file: File): Promise<Record<string, any>[]> {
  const content = await readFile(file);
  return parseCSVString(content);
}

// Define a function to parse CSV content into an array of objects
function parseCSVString(csv: string): object[] {
  const lines = csv
    .split("\n")
    .filter((line) => line)
    .map((line) => line.replace(/(\r\n|\n|\r)/gm, ""));
  const headers = lines[0].split(",");
  const data = [];

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");
    const row: Record<string, any> = {};

    for (let j = 0; j < headers.length; j++) {
      row[headers[j]] = values[j];
    }

    data.push(row);
  }

  return data;
}

async function parseXLSXFile(file: File): Promise<Record<string, any>[]> {
  const schema = {
    Format: {
      prop: "Format",
      type: String,
    },
    Name: {
      prop: "Name",
      required: true,
      type: String,
    },
    Sequence: {
      prop: "Sequence",
      required: true,
      type: String,
    },
    Well: {
      prop: "Well",
      type: String,
    },
  };
  const content = await readXlsxFile(file, { schema });
  return content.rows;
}
