import { ModificationStatusToPosition } from "./constants";
import type { Display } from "./display";
import type { PlateKit, WorkflowWell } from "./types";

import { ModificationStatus, Purity } from "../../../../../../config/enums";
import { getSequenceLength } from "../../../../../../utils/parser";

export const DefaultBackgroundColor = "bg-background";
const defaultText = "text-slate-800";
const textWhite = "text-white";

const defaultColors = {
  background: DefaultBackgroundColor,
  text: defaultText,
};

const getLengthColors = (
  well: WorkflowWell,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!well.sequence) {
    return defaultColors;
  }
  const sequenceLength = getSequenceLength(well.sequence);
  const percentage = Math.min(
    100,
    Math.max(0, ((sequenceLength ?? 0) - 0) / 120) * 100,
  );
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-sequence-gradient)",
      position: percentage,
    },
  };
};

const getVolumeColors = (
  well: WorkflowWell,
  volume: PlateKit["volume"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!well.result) {
    return defaultColors;
  }
  const { totalVolume } = well.result;
  const volumeRange = volume.max - volume.min;
  const percentage = Math.min(
    100,
    Math.max(0, ((totalVolume ?? 0) - volume.min) / volumeRange) * 100,
  );

  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-volume-gradient)",
      position: percentage,
    },
  };
};

const getConcentrationColors = (
  concentration: number | undefined | null,
  kitConcentration: PlateKit["concentration"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!concentration) {
    return defaultColors;
  }
  const concentrationRange = kitConcentration.max - kitConcentration.min;
  const percentage = Math.min(
    100,
    Math.max(
      0,
      ((concentration ?? 0) - kitConcentration.min) / concentrationRange,
    ) * 100,
  );
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-concentration-gradient)",
      position: percentage,
    },
  };
};

const getYieldColors = (
  well: WorkflowWell,
  yields: PlateKit["yields"],
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!well.result) {
    return defaultColors;
  }
  const { yield: yieldResult } = well.result;
  const percentage = Math.min(
    100,
    Math.max(
      0,
      (yieldResult - yields.medium.min) / (yields.high.min - yields.low.max),
    ) * 100,
  );
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-yield-gradient)",
      position: percentage,
    },
  };
};

export const getColorsFromPurity = (purityFlag: Purity) => {
  if (purityFlag === Purity.Unknown) {
    return defaultColors;
  }
  if (purityFlag === Purity.Extreme) {
    return {
      background: "bg-red-500",
      text: textWhite,
    };
  }
  if (purityFlag === Purity.Difficult) {
    return {
      background: "bg-orange-500",
      text: textWhite,
    };
  }
  return {
    background: "bg-green-500",
    text: textWhite,
  };
};

const getScoreColors = (well: WorkflowWell) => {
  const purityFlag = well.purityFlag;
  return getColorsFromPurity(purityFlag);
};

const getModificationColors = (well: WorkflowWell) => {
  if (!well.result) {
    return defaultColors;
  }
  const percentage =
    ModificationStatusToPosition[
      well.result?.sequenceModificationStatus ?? ModificationStatus.None
    ];
  return {
    background: DefaultBackgroundColor,
    text: percentage > 40 ? textWhite : defaultText,
    useGradient: {
      gradient: "var(--report-modification-gradient)",
      position: percentage,
    },
  };
};

export const getWellColors = (
  well: WorkflowWell | undefined,
  display: Display,
  kitProperties: PlateKit,
): {
  background: string;
  text: string;
  useGradient?: {
    gradient: string;
    position: number;
  };
} => {
  if (!well) {
    return defaultColors;
  }
  const { volume, yields, concentration } = kitProperties;
  if (display === "purity") {
    return getScoreColors(well);
  }
  if (display === "length") {
    return getLengthColors(well);
  }
  if (display === "volume") {
    return getVolumeColors(well, volume);
  }
  if (display === "yield") {
    return getYieldColors(well, yields);
  }
  if (display === "measuredConcentration") {
    return getConcentrationColors(well.result?.concentration, concentration);
  }
  if (display === "concentration") {
    return getConcentrationColors(well.expectedConcentration, concentration);
  }
  if (display === "modification") {
    return getModificationColors(well);
  }

  return defaultColors;
};
