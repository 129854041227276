export const OrganizationSettingsRoutes = {
  ADMIN: "/organization/settings/admin",
  DETAILS: "/organization/settings/details",
  DETAILS_EDIT: "/organization/settings/details/edit",
  HOME: "/organization/settings",
  NEW_MEMBER: "/organization/settings/new-member",
  NEW_TEAM: "/organization/settings/new-team",
  SUPPORT: "/organization/settings/support",
  TEAM: "/organization/settings/teams/:teamId",
  TEAMS: "/organization/settings/teams",
  USER: "/organization/settings/users/:userId",
  USERS: "/organization/settings/users",
} as const;

export const ORGANIZATION_SETTINGS_TABS = [
  {
    id: "details",
    link: "Details",
    path: OrganizationSettingsRoutes.DETAILS,
  },
  {
    id: "users",
    link: "Users",
    path: OrganizationSettingsRoutes.USERS,
  },
  {
    id: "teams",
    link: "Teams",
    path: OrganizationSettingsRoutes.TEAMS,
  },
  {
    id: "support",
    link: "Support",
    path: OrganizationSettingsRoutes.SUPPORT,
  },
  {
    id: "admin",
    link: "Admin",
    path: OrganizationSettingsRoutes.ADMIN,
  },
];
