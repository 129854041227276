import { BellRing } from "lucide-react";

import NotificationsList from "./notificationsList";

import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { trpc } from "../../../config/trpc";

export default function Notifications() {
  const { data: unreadMessagesCount } = trpc.notification.getNewCount.useQuery(
    undefined,
    {
      initialData: 0,
    },
  );

  const utils = trpc.useUtils();
  trpc.notification.subscribe.useSubscription(undefined, {
    onData(newCount) {
      utils.notification.getNewCount.setData(undefined, newCount);
    },
  });

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="relative">
          <Button variant="secondary">
            <BellRing fill="white" size={19} />
          </Button>
          {unreadMessagesCount > 0 && (
            <Badge className="absolute right-0 top-0 px-1.5">
              {unreadMessagesCount > 9 ? "9+" : unreadMessagesCount}
            </Badge>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-1 drop-shadow-2xl">
        <NotificationsList />
      </PopoverContent>
    </Popover>
  );
}
