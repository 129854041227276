import { Heading } from "@radix-ui/themes";
import { Save } from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import EditConstruct from "./edit-construct";
import { useCreateConstruct } from "./hooks/useCreateConstruct";
import useLocationAssayId from "./hooks/useLocationAssayId";

import { Button } from "../../../../components/ui/button";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../organization-routes";
import { useGetAssayWithId } from "../assay/useGetAssay";

export default function NewConstruct() {
  const navigate = useNavigate();
  const onSave = useCreateConstruct();
  const { construct } = useGetUIAuthorizedCreations();
  const assayId = useLocationAssayId();
  const { data: assay } = useGetAssayWithId(assayId);

  useEffect(() => {
    if (!construct) {
      navigate(OrganizationRoutes.LIBRARY);
    }
  }, [construct, navigate]);

  return (
    <EditConstruct onSave={onSave}>
      <div className="flex flex-row items-center justify-between">
        <div>
          <Heading>New construct</Heading>
          {assayId && (
            <div className="flex flex-row items-center space-x-1">
              <p>Creating for assay</p>
              <Button
                onClick={() => {
                  navigate(
                    OrganizationRoutes.ASSAY.replace(":assayId", assayId ?? ""),
                  );
                }}
                variant={"ghost"}
              >
                <p className="italic">{assay?.name}</p>
              </Button>
            </div>
          )}
        </div>
        <Button aria-label="Save construct" type="submit">
          <Save />
        </Button>
      </div>
    </EditConstruct>
  );
}
