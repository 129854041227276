import { z } from "zod";

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const passwordRegex = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&,]{8,32}$/,
);

export const strongPasswordSchema = z.string().regex(passwordRegex, {
  message: `Password must be between 8 and 32 characters long, include at least one uppercase letter\
     one lowercase letter, and one number, and can only contain alphanumeric values and the \
     following special characters: @$!%*#?&,`,
});
