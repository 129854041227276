import { Download } from "lucide-react";

import { Button } from "../../components/ui/button";

export default function Templates({
  templates,
}: {
  templates: { downloadName: string; file: string; label: string }[];
}) {
  return (
    <div className="bg-card flex flex-row items-center space-x-2 rounded-lg border px-2">
      <p className="text-sm">Templates</p>
      {templates.map((template) => (
        <a
          className="cursor-pointer"
          download={template.downloadName}
          href={template.file}
          key={template.downloadName}
          rel="noreferrer"
          target="_blank"
        >
          <Button className="space-x-1 px-1" variant={"ghost"}>
            <span>{template.label}</span>
            <Download />
          </Button>
        </a>
      ))}
    </div>
  );
}
