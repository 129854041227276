import * as React from "react";

import { cn } from "../../lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "border-input bg-card ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex min-h-[80px] w-full rounded-md border px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

const TextareaAutoGrow = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const localRef = React.useRef<HTMLTextAreaElement>(null);
    const refToUse = ref || localRef;

    React.useEffect(() => {
      if (!localRef.current) {
        return;
      }
      localRef.current.style.height = "auto";
      localRef.current.style.height = `${localRef.current.scrollHeight + 2}px`;
    }, [props.value]);

    return (
      <textarea
        className={cn(
          "transition-height border-input bg-card ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex w-full rounded-md border px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        {...props}
        ref={refToUse}
        rows={1}
      />
    );
  },
);
TextareaAutoGrow.displayName = "Textarea";

const TextareaGrowOnFocus = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(({ className, ...props }, ref) => {
  const localRef = React.useRef<HTMLTextAreaElement>(null);
  const refToUse = ref || localRef;

  function resize() {
    if (!localRef.current) {
      return;
    }
    localRef.current.style.height = "auto";
    localRef.current.style.height = `${localRef.current.scrollHeight + 2}px`;
  }

  function reset() {
    if (!localRef.current) {
      return;
    }
    localRef.current.style.height = "auto";
  }

  return (
    <textarea
      className={cn(
        "transition-height border-input bg-card ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring focus:height-auto flex w-full resize-none overflow-hidden rounded-md border px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      onBlur={(e) => {
        reset();
        props.onBlur?.(e);
      }}
      onFocus={(e) => {
        resize();
        props.onFocus?.(e);
      }}
      onInput={(e) => {
        resize();
        props.onInput?.(e);
      }}
      {...props}
      ref={refToUse}
      rows={1}
    />
  );
});
TextareaGrowOnFocus.displayName = "Textarea";

export { Textarea, TextareaAutoGrow, TextareaGrowOnFocus };
