import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import type { Oligo } from "./types";

export const useScrollRowAndFocusName = (data: Oligo[]) => {
  const [searchParams] = useSearchParams();
  const oligoIdToScrollTo = searchParams.get("oligo");
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (hasScrolled) {
      return;
    }
    const rows = document.getElementsByTagName("tr");
    const rowIndex = data.findIndex((row) => row.id === oligoIdToScrollTo) + 1;
    if (rowIndex !== 0 && rowIndex < rows.length) {
      const row = rows[rowIndex];
      row.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
      const textArea = row.getElementsByTagName("textarea");
      textArea[0]?.focus();
      setHasScrolled(true);
    }
  }, [data, hasScrolled, oligoIdToScrollTo]);
};
