import { Box, Flex, Text } from "@radix-ui/themes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useResetCache } from "./components/header/useLogout";
import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { AccountStatus } from "../config/enums";
import { trpc } from "../config/trpc";
import { useAuth, useUser } from "../containers/hooks";

export default function ChooseOrganization() {
  const { setToken } = useAuth();
  const user = useUser();
  const organizations =
    user?.memberships
      .filter((membership) => membership.status === AccountStatus.ACTIVE)
      .map((m) => m.organization)
      .filter((org) => org.status === "ACTIVE") ?? [];
  const [organizationId, setOrganizationId] = useState<string>("");
  const navigate = useNavigate();
  const resetCache = useResetCache();

  const { mutate: connectToOrganization } =
    trpc.account.organization.connect.useMutation({
      onSuccess(data) {
        resetCache();
        setToken(data.token);
        navigate(RootRoutes.ORGANIZATION);
      },
    });

  if (organizations.length === 0) {
    return (
      <Flex className="flex flex-col items-center" gap={"4"}>
        <Text>You are not a member of any active organization</Text>
        <Text>Ask an admin to add you in an organization</Text>
      </Flex>
    );
  }

  return (
    <Flex className="flex flex-col items-center" gap={"4"}>
      <Flex className="items-center" gap={"2"}>
        <Text>Organization</Text>
        <Select
          defaultValue={organizations?.[0]?.id}
          onValueChange={(value) => {
            setOrganizationId(value);
          }}
          value={organizationId}
        >
          <SelectTrigger>
            <SelectValue placeholder="Change organization" />
          </SelectTrigger>
          <SelectContent>
            {organizations.map((organization) => (
              <SelectItem key={organization.id} value={organization.id}>
                {organization.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </Flex>
      <Box>
        <Button
          disabled={!organizationId}
          onClick={() => connectToOrganization(organizationId)}
        >
          Validate
        </Button>
      </Box>
    </Flex>
  );
}
