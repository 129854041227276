import { zodResolver } from "@hookform/resolvers/zod";
import { Heading } from "@radix-ui/themes";
import { EditIcon, SaveIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import TagsSelection, {
  TagsDisplay,
} from "../../../../../components/logic/tags";
import { Button } from "../../../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { Separator } from "../../../../../components/ui/separator";
import { trpc, type AssayTRPC } from "../../../../../config/trpc";
import { useInvalidateTags } from "../../../../../containers/user/hooks";

const AssayDetailsDisplay = ({
  onEdit,
  assay,
}: {
  assay: AssayTRPC;
  onEdit: () => void;
}) => {
  if (!assay) {
    return null;
  }
  const { name, tags, createdAt, updatedAt } = assay;

  return (
    <div className="bg-card space-y-2 rounded-lg border p-4">
      <div className="flex flex-row items-center justify-between">
        <Heading size="4">Details</Heading>
        <Button onClick={onEdit} variant={"outline"}>
          <EditIcon />
        </Button>
      </div>
      <div className="flex h-10 flex-row items-center space-x-2">
        <div>
          <p className="text-sm font-medium">Name</p>
          <p>{name}</p>
        </div>
        <Separator orientation="vertical" />
        <div>
          <p className="text-sm font-medium">Created at</p>
          <p>{new Date(createdAt).toLocaleDateString()}</p>
        </div>
        <Separator orientation="vertical" />
        <div>
          <p className="text-sm font-medium">Last update</p>
          <p>{new Date(updatedAt).toLocaleString()}</p>
        </div>
        <Separator orientation="vertical" />
        <div className="max-w-[200px]">
          <p className="text-sm font-medium">Tags</p>
          <TagsDisplay tags={tags} />
        </div>
      </div>
    </div>
  );
};

const assayUpdateForm = z.object({
  name: z.string().trim().min(1),
  tags: z.array(z.string()),
});

type AssayUpdateForm = z.infer<typeof assayUpdateForm>;

const AssayDetailsEdit = ({
  assay,
  onSave,
}: {
  assay: AssayTRPC;
  onSave: () => void;
}) => {
  const form = useForm<AssayUpdateForm>({
    defaultValues: {
      name: assay?.name ?? "",
      tags: assay?.tags ?? [],
    },
    resolver: zodResolver(assayUpdateForm),
  });
  const invalidateTags = useInvalidateTags();
  const { mutate: updateAssay } = trpc.assay.update.useMutation({
    onSuccess(_, input) {
      onSave();
      if (JSON.stringify(input.tags) !== JSON.stringify(assay.tags)) {
        invalidateTags();
      }
    },
  });

  const handleUpdateAssay = (data: AssayUpdateForm) => {
    if (!assay?.id) {
      return;
    }
    updateAssay({
      id: assay.id,
      name: data.name,
      tags: data.tags,
    });
  };

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(handleUpdateAssay)}
      >
        <div className="bg-card space-y-2 rounded-lg border p-4">
          <div className="flex flex-row items-center justify-between">
            <Heading size="4">Details</Heading>
            <Button type="submit" variant={"outline"}>
              <SaveIcon />
            </Button>
          </div>
          <div className="flex min-h-10 flex-row items-center space-x-2">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="h-12" orientation="vertical" />
            <FormField
              control={form.control}
              name="tags"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Tags</FormLabel>
                  <FormControl>
                    <TagsSelection
                      setValues={(newTags: string[]) =>
                        form.setValue("tags", newTags)
                      }
                      type="assay"
                      values={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default function AssayDetails({ assay }: { assay: AssayTRPC }) {
  const [isEditing, setIsEditing] = useState(false);
  const utils = trpc.useUtils();

  if (!assay) {
    return null;
  }

  if (isEditing) {
    return (
      <AssayDetailsEdit
        assay={assay}
        onSave={() => {
          setIsEditing(false);
          utils.assay.get.invalidate(assay.id);
        }}
      />
    );
  }
  return (
    <AssayDetailsDisplay assay={assay} onEdit={() => setIsEditing(true)} />
  );
}
