import { Heading } from "@radix-ui/themes";
import { Dna } from "lucide-react";
import { useNavigate } from "react-router-dom";

import Constructs from "./components/constructs";

import { Button } from "../../../../components/ui/button";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../organization-routes";

export default function Library() {
  const navigate = useNavigate();
  const { construct: canCreateConstruct } = useGetUIAuthorizedCreations();

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <Heading>Construct library</Heading>
        <Button
          className="space-x-1"
          disabled={!canCreateConstruct}
          onClick={() => {
            navigate(OrganizationRoutes.CONSTRUCT_NEW);
          }}
        >
          <span>New Construct</span>
          <Dna />
        </Button>
      </div>
      <Constructs />
    </div>
  );
}
