import ForgotPasswordEmail from "@backend/notifications/emails/templates/forgot-password";
import InviteUserEmail from "@backend/notifications/emails/templates/invite-user";
import NewUserEmail from "@backend/notifications/emails/templates/new-user";
import { render } from "@react-email/render";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { RootRoutes } from "./routes";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { useIsDevelopment } from "../containers/contexts";

const RENDERED_EMAILS = [
  {
    id: "new-account_without_password",
    name: "New account without password",
    page: render(
      <NewUserEmail
        body='You have been added to DNA Script Console. Please click on this 
             <a href="http://localhost:5173/set-new-password?code=nzefofnzefi&userId=user-1" style="color:darkblue;text-decoration:underline">link</a>
             to set up your password. 
             If you have any questions or issues with the account setup, please contact 
             John Smith at john.smith@dnascript.co directly.'
        firstName="thomas"
        lastName="dupont"
        rootUrl="http://localhost:5173"
      />,
      { pretty: true },
    ),
  },
  {
    id: "new-account_with_password",
    name: "New account with password",
    page: render(
      <NewUserEmail
        body='You have been added to DNA Script Console. Please click on this 
             <a href="http://localhost:5173/set-new-password?code=nzefofnzefi&userId=user-1" style="color:darkblue;text-decoration:underline">link</a>
             and use your email and password: "password" to log into the system. 
             If you have any questions or issues with the account setup, please contact 
             John Smith at john.smith@dnascript.co directly.'
        firstName="thomas"
        lastName="dupont"
        rootUrl="http://localhost:5173"
      />,
      { pretty: true },
    ),
  },
  {
    id: "invitation",
    name: "Invitation to organization",
    page: render(
      <InviteUserEmail
        body='You have been invited by John Smith to join
           Dnascript! Please click on this 
           <a href="http://localhost:5173" style="color:darkblue;text-decoration:underline">link</a> 
           to set up your own account and log into 
           the system. If you have any questions or issues with the account setup, please
           contact John Smith at john.smith@dnascript.co directly.'
        firstName="thomas"
        lastName="dupont"
        rootUrl="http://localhost:5173"
      />,
      { pretty: true },
    ),
  },
  {
    id: "forgot-password",
    name: "Forgot password",
    page: render(
      <ForgotPasswordEmail
        body={`There was a request to change the password on your account. If you requested this password change, please click the  
             <a href="http://localhost:5173" style="color:darkblue;text-decoration:underline">link</a>
             to set a new password within 24 hours. 
             If you did not initiate this request or if you don't want to change your password, just ignore this message.`}
        firstName="thomas"
        lastName="dupont"
        rootUrl="http://localhost:5173"
      />,
      { pretty: true },
    ),
  },
  {
    id: "reset-password",
    name: "Reset password",
    page: "",
  },
] as const;

const DEFAULT_EMAIL_PATH = RootRoutes.EMAILS + "/" + RENDERED_EMAILS[0].id;

export const Emails = () => {
  return <Navigate to={DEFAULT_EMAIL_PATH} />;
};

export default function Email() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isDevelopment = useIsDevelopment();
  if (!isDevelopment) {
    return <Navigate to={RootRoutes.ROOT} />;
  }
  if (!id) {
    navigate(DEFAULT_EMAIL_PATH);
  }

  return (
    <div>
      <Tabs
        className="flex"
        defaultValue="account"
        onValueChange={(newId) =>
          navigate(RootRoutes.EMAIL.replace(":id", newId))
        }
        orientation="vertical"
        value={id}
      >
        <TabsList
          className="grid h-fit grid-rows-2"
          defaultValue={RENDERED_EMAILS[0].id}
        >
          {RENDERED_EMAILS.map((email) => {
            return (
              <TabsTrigger key={email.name} value={email.id}>
                {email.name}
              </TabsTrigger>
            );
          })}
        </TabsList>
        {RENDERED_EMAILS.map((email) => {
          return (
            <TabsContent
              className="relative h-[calc(100vh_-_70px)] overflow-auto p-4"
              key={email.id}
              value={email.id}
            >
              <iframe className="h-[800px] w-[500px]" srcDoc={email.page} />
            </TabsContent>
          );
        })}
      </Tabs>
    </div>
  );
}
