import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";

import { SimpleTable } from "../../../../components/ui/simple-table";
import { AdminRoutes } from "../../adminRoutes";
import type { UserDataTRPC } from "../hooks";

const columnHelper = createColumnHelper<UserDataTRPC["memberships"][0]>();

const columns = [
  columnHelper.accessor("organization.name", {
    cell: (info) => info.getValue(),
    header: "Organization name",
  }),
];

export default function MemberShipList({
  memberships,
}: {
  memberships: UserDataTRPC["memberships"];
}) {
  const table = useReactTable({
    columns,
    data: memberships ?? [],
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();

  return (
    <SimpleTable
      onRowClick={(row) =>
        navigate(
          AdminRoutes.ORGANIZATION.replace(
            ":organizationId",
            row.original.organizationId,
          ),
        )
      }
      table={table}
    />
  );
}
