import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteAssay } from "./components/assay-actions";
import PQAAssay from "./components/pqa-assay";
import { QuickPlateUploadButton } from "./quick-plate-upload";

import { TagsDisplay } from "../../../../components/logic/tags";
import {
  ArchiveButton,
  RestoreButton,
} from "../../../../components/table/archive";
import { Button } from "../../../../components/ui/button";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { useToast } from "../../../../components/ui/use-toast";
import { ObjectMembership } from "../../../../config/enums";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { CheckIfTrueElseCross } from "../../../admin/organizations/components/ui";
import { useIsActiveSupportOfOrganization } from "../../../settings/organization-settings/hooks";
import { OrganizationRoutes } from "../../organization-routes";

type AssayRow = inferProcedureOutput<AppRouter["assay"]["list"]>[number];

const columns: ColumnDef<AssayRow>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.getValue("name"),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "createdAt",
    cell: ({ row }) => new Date(row.getValue("createdAt")).toLocaleDateString(),
    enableColumnFilter: false,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Created At",
    },
    sortingFn: "datetime",
  },
  {
    accessorKey: "createdBy.name",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Owner",
    },
  },
  {
    accessorFn: (row) => new Date(row.updatedAt),
    cell: ({ row }) => new Date(row.getValue("updatedAt")).toLocaleString(),
    enableColumnFilter: false,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "updatedAt",
    meta: {
      title: "Last update",
    },
    sortingFn: "datetime",
  },
  {
    accessorFn: (row) => !row.archived,
    cell: ({ row }) => <CheckIfTrueElseCross value={!row.original.archived} />,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "active",
    meta: {
      booleanFilter: true,
      defaultFilter: true,
      title: "Active",
    },
  },
  {
    accessorFn: (row) => row.tags,
    cell: ({ row }) => <TagsDisplay tags={row.original.tags} />,
    filterFn: "arrIncludesSome",
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "tags",
    meta: {
      title: "Tags",
    },
  },
  {
    cell: ({ row }) => {
      const assay = row.original;
      const isArchived = assay.archived;
      const canEditAssay = assay.canEdit;
      return (
        <TableActions
          items={[
            {
              children: "Copy assay ID",
              id: "Copy ID",
              onClick: () => navigator.clipboard.writeText(assay.id),
            },
            {
              children: (
                <RestoreButton
                  id={row.original.id}
                  subjectType={ObjectMembership.Assay}
                />
              ),
              disabled: !canEditAssay,
              id: "Restore",
              skip: !isArchived,
            },
            {
              children: <DeleteAssay id={row.original.id} key="delete" />,
              disabled: !canEditAssay,
              id: "Delete",
              onClick: (e) => e?.preventDefault(),
              skip: !isArchived,
            },
            {
              children: (
                <ArchiveButton
                  id={row.original.id}
                  key="archive"
                  subjectType={ObjectMembership.Assay}
                />
              ),
              id: "Archive",
              onClick: (e) => e?.preventDefault(),
              skip: isArchived,
            },
          ]}
        />
      );
    },
    enableHiding: false,
    id: "actions",
  },
];

export default function Assays() {
  const navigate = useNavigate();
  const { assay: canCreateAssay } = useGetUIAuthorizedCreations();
  const { toast } = useToast();
  const isSupport = useIsActiveSupportOfOrganization();

  const { data, isPending, error } = trpc.assay.list.useQuery(undefined);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message,
        title: "Error getting assays",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  const assays = data ?? [];

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <Heading>Assays</Heading>
        <div className="flex flex-row items-center space-x-2">
          <QuickPlateUploadButton />
          {isSupport && <PQAAssay />}
          <Button
            className="space-x-1"
            disabled={!canCreateAssay}
            onClick={() => {
              navigate(OrganizationRoutes.ASSAY_NEW);
            }}
          >
            <span>New Assay</span>
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={assays}
        defaultSorting={[{ desc: true, id: "updatedAt" }]}
        loading={isPending}
        onRowClick={(row) => {
          navigate(
            OrganizationRoutes.ASSAY.replace(":assayId", row.original.id),
          );
        }}
      />
    </div>
  );
}
