import { produce } from "immer";

import type { Oligo, Plate, Well, Workflow } from "./types";
import {
  addEmptyWellsToPlate,
  availableWellIndexGenerator,
  canHintBelongToPlate,
  compareOligosByHintAndIndex,
  createPlate,
} from "./utils";

import type { PlateSize } from "../../../../../config/enums";

export enum WorkflowActions {
  "ADD_PLATE" = "ADD_PLATE",
  "ASSIGN_OLIGOS_TO_WELL" = "ASSIGN_OLIGOS_TO_WELL",
  "AUTO_ASSIGN" = "AUTO_ASSIGN",
  "MOVE_WELL" = "MOVE_WELL",
  "REMOVE_PLATE" = "REMOVE_PLATE",
  "RENAME_PLATE" = "RENAME_PLATE",
  "RESET_OLIGOS" = "RESET_OLIGOS",
  "RESET_PLATES" = "RESET_PLATES",
  "RESET_WELL" = "RESET_WELL",
}
export type WorkflowReducerActions =
  | {
      payload: { oligoIds: string[]; plateIndex: number; wellIndex: string };
      type: WorkflowActions.ASSIGN_OLIGOS_TO_WELL;
    }
  | {
      payload: { kit: string; name: string; size: PlateSize };
      type: WorkflowActions.ADD_PLATE;
    }
  | {
      payload: { plateIndex: number };
      type: WorkflowActions.REMOVE_PLATE;
    }
  | {
      payload: { name: string; plateIndex: number };
      type: WorkflowActions.RENAME_PLATE;
    }
  | {
      payload: { defaultKit: string; defaultSize: PlateSize };
      type: WorkflowActions.AUTO_ASSIGN;
    }
  | {
      payload: { plateIndex: number; wellIndex: string };
      type: WorkflowActions.RESET_WELL;
    }
  | {
      payload: { oligos: Oligo[] };
      type: WorkflowActions.RESET_OLIGOS;
    }
  | {
      payload: { plates: Plate[] };
      type: WorkflowActions.RESET_PLATES;
    }
  | {
      payload: {
        from: {
          plateId: string;
          wellIndex: string;
        };
        to: {
          plateId: string;
          wellIndex: string;
        };
      };
      type: WorkflowActions.MOVE_WELL;
    };

export function workflowReducer(
  workflow: Workflow,
  action: WorkflowReducerActions,
) {
  switch (action.type) {
    case WorkflowActions.ASSIGN_OLIGOS_TO_WELL: {
      return {
        ...workflow,
        plates: workflow.plates.map((plate, plateIndex) => {
          if (plateIndex !== action.payload.plateIndex) {
            return plate;
          }
          const indexGenerator = availableWellIndexGenerator(
            plate,
            action.payload.wellIndex,
          );
          const mapOfIndexToOligoId = new Map<string, string>();

          for (const oligoId of action.payload.oligoIds) {
            const wellIndex = indexGenerator.next();
            if (wellIndex.done) {
              break;
            }
            mapOfIndexToOligoId.set(wellIndex.value, oligoId);
          }

          return {
            ...plate,
            wells: plate.wells.map((well) => {
              if (mapOfIndexToOligoId.has(well.index)) {
                return {
                  ...well,
                  oligoId: mapOfIndexToOligoId.get(well.index),
                };
              }
              return well;
            }),
          };
        }),
      };
    }
    case WorkflowActions.ADD_PLATE: {
      return {
        ...workflow,
        plates: [
          ...workflow.plates,
          createPlate(
            action.payload.kit,
            action.payload.size,
            action.payload.name,
          ),
        ],
      };
    }
    case WorkflowActions.REMOVE_PLATE: {
      return {
        ...workflow,
        plates: workflow.plates.filter(
          (_, i) => i !== action.payload.plateIndex,
        ),
      };
    }
    case WorkflowActions.RENAME_PLATE: {
      return {
        ...workflow,
        plates: workflow.plates.map((plate, i) =>
          i === action.payload.plateIndex
            ? { ...plate, name: action.payload.name }
            : plate,
        ),
      };
    }
    case WorkflowActions.MOVE_WELL: {
      const { from, to } = action.payload;
      const wellFrom = (
        workflow.plates.find((p) => p.id === from.plateId) ??
        ({
          wells: [],
        } as {
          wells: Well[];
        })
      ).wells.find((w) => w.index === from.wellIndex);
      const wellTo = (
        workflow.plates.find((p) => p.id === to.plateId) ??
        ({
          wells: [],
        } as {
          wells: Well[];
        })
      ).wells.find((w) => w.index === to.wellIndex);
      const wellTargetHasOligo = Boolean(wellTo?.oligoId);
      if (!wellFrom) {
        return workflow;
      }
      const onSamePlate = from.plateId === to.plateId;
      const newWorkflow: Workflow = {
        ...workflow,
        plates: workflow.plates.map((plate) => {
          if (onSamePlate) {
            if (plate.id === from.plateId) {
              return {
                ...plate,
                wells: plate.wells.map((w) => {
                  if (w.index === from.wellIndex) {
                    return {
                      ...w,
                      oligoId: wellTargetHasOligo ? wellTo?.oligoId : undefined,
                    };
                  }
                  if (w.index === to.wellIndex) {
                    return {
                      ...w,
                      oligoId: wellFrom.oligoId,
                    };
                  }
                  return w;
                }),
              };
            }
            return plate;
          }
          if (plate.id === from.plateId) {
            return {
              ...plate,
              wells: plate.wells.map((w) => {
                if (w.index === from.wellIndex) {
                  return {
                    ...w,
                    oligoId: wellTargetHasOligo ? wellTo?.oligoId : undefined,
                  };
                }
                return w;
              }),
            };
          }
          if (plate.id === to.plateId) {
            return {
              ...plate,
              wells: plate.wells.map((w) => {
                if (w.index === to.wellIndex) {
                  return {
                    ...w,
                    oligoId: wellFrom.oligoId,
                  };
                }
                return w;
              }),
            };
          }
          return plate;
        }),
      };
      return newWorkflow;
    }
    case WorkflowActions.AUTO_ASSIGN: {
      const { defaultKit, defaultSize } = action.payload;
      const alreadyAssignedOligos = new Set();
      workflow.plates.forEach((p) => {
        p.wells.forEach((w) => {
          if (w.oligoId) {
            alreadyAssignedOligos.add(w.oligoId);
          }
        });
      });

      const oligosToAssign = workflow.oligos.filter(
        (o) => !alreadyAssignedOligos.has(o.id),
      );

      oligosToAssign.sort(compareOligosByHintAndIndex);
      const newPlates = produce(workflow.plates, (oldPlates) => {
        let countOfPlatesCreated = 0;
        for (const oligo of oligosToAssign) {
          const placement = oligo.platePositioningExpected;
          const findAvailableWell = () => {
            for (const [plateIndex, plate] of oldPlates.entries()) {
              if (plate.locked) {
                continue;
              }
              const hintCanBelongToPlate = canHintBelongToPlate(
                plate.size,
                placement,
              );
              const wellIndex = plate.wells.findIndex(
                (w) =>
                  (hintCanBelongToPlate ? w.index === placement : true) &&
                  !w.oligoId,
              );
              if (wellIndex >= 0) {
                return { plateIndex, wellIndex };
              }
            }
            return null;
          };
          const availableWell = findAvailableWell();
          if (availableWell === null) {
            const newPlate = createPlate(
              defaultKit,
              defaultSize,
              `Plate ${countOfPlatesCreated + 1}`,
            );
            const hintCanBelongToPlate = canHintBelongToPlate(
              defaultSize,
              placement,
            );
            const wellToPutOligo = hintCanBelongToPlate ? placement : "A1";
            newPlate.wells = newPlate.wells.map((w) => {
              if (w.index === wellToPutOligo) {
                return {
                  ...w,
                  oligoId: oligo.id,
                };
              }
              return w;
            });
            countOfPlatesCreated += 1;
            oldPlates.push(newPlate);
            continue;
          }

          const { plateIndex, wellIndex } = availableWell;
          oldPlates[plateIndex].wells[wellIndex].oligoId = oligo.id;
        }
      });

      return {
        ...workflow,
        plates: newPlates,
      };
    }
    case WorkflowActions.RESET_WELL: {
      const { wellIndex, plateIndex } = action.payload;
      return {
        ...workflow,
        plates: workflow.plates.map((p, pIndex) => {
          if (plateIndex !== pIndex) {
            return p;
          }
          return {
            ...p,
            wells: p.wells.map((w) => {
              if (w.index !== wellIndex) {
                return w;
              }
              return {
                ...w,
                oligoId: undefined,
              };
            }),
          };
        }),
      };
    }
    case WorkflowActions.RESET_OLIGOS: {
      return {
        oligos: action.payload.oligos,
        plates: workflow.plates,
      };
    }
    case WorkflowActions.RESET_PLATES: {
      const platesWithEmptyWells =
        action.payload.plates.map(addEmptyWellsToPlate);
      return {
        oligos: workflow.oligos,
        plates: platesWithEmptyWells,
      };
    }
    default:
      return workflow;
  }
}
