import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { RefreshCcw, Trash } from "lucide-react";
import { Link } from "react-router-dom";

import { Button } from "../../../components/ui/button";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";

const DeleteInstrument = ({ serialNumber }: { serialNumber: string }) => {
  const utils = trpc.useUtils();
  const { mutate: deleteInstrument } =
    trpc.instrument.syntax.delete.useMutation({
      onSuccess() {
        utils.instrument.syntax.listAll.invalidate();
      },
    });

  const handleDeleteInstrument = () => {
    deleteInstrument({
      serialNumber,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message:
              "Are you sure you want to delete this instrument ? This action cannot be undone.",
            title: "Delete instrument",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash /> Delete
            </div>
          ),
          id: "Delete instrument",
          onClick: handleDeleteInstrument,
        },
      ]}
    />
  );
};

type SyntaxInstrument = inferProcedureOutput<
  AppRouter["instrument"]["syntax"]["listAll"]
>[number];

const columns: ColumnDef<SyntaxInstrument>[] = [
  {
    accessorFn: (row) => row.serialNumber,
    cell: ({ row }) => row.original.serialNumber,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "serialNumber",
    meta: {
      title: "Serial number",
    },
  },
  {
    accessorKey: "organization.name",
    cell: (info) => {
      const { name, id } = info.row.original.organization;
      return (
        <Link
          className="text-blue-500 hover:underline"
          to={AdminRoutes.ORGANIZATION_SETTINGS.replace(":organizationId", id)}
        >
          {name}
        </Link>
      );
    },
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "organization",
    meta: {
      title: "Organization",
    },
  },
  {
    accessorFn: (row) => row.softwareVersion,
    cell: ({ row }) => row.original.softwareVersion,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "softwareVersion",
    meta: {
      title: "Software running",
    },
  },
  {
    cell: (info) => (
      <TableActions
        items={[
          {
            children: (
              <DeleteInstrument serialNumber={info.row.original.serialNumber} />
            ),
            id: "Delete",
          },
        ]}
      />
    ),
    id: "actions",
  },
];

export default function AllInstruments() {
  const { data, refetch, isPending } =
    trpc.instrument.syntax.listAll.useQuery();

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="p-2">
      <div className="m-2 flex items-center justify-between">
        <Heading>Instruments dashboard</Heading>
        <Button
          className="space-x-1"
          disabled={isPending}
          onClick={handleRefetch}
          type="button"
        >
          <RefreshCcw />
          <span>Refresh</span>
        </Button>
      </div>
      <DataTable columns={columns} data={data ?? []} />
    </div>
  );
}
