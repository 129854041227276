import { trpc } from "../../config/trpc";

export const useGetRuns = (instrumentId: string | undefined) => {
  const { data, ...queryResult } = trpc.instrument.syntax.listRuns.useQuery(
    {
      instrumentId: instrumentId ?? "",
    },
    { enabled: !!instrumentId, initialData: [] },
  );
  const transformedData = data
    ? data.map((run) => ({
        ...run,
        createdAt: new Date(run.createdAt),
        updatedAt: new Date(run.updatedAt),
      }))
    : [];
  return { data: transformedData, ...queryResult };
};
