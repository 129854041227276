import {
  Text,
  Html,
  Font,
  Section,
  Preview,
  Heading,
  Img,
  Container,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

import Signature from "./signature";
import { NotificationEmailProps } from "../emails";

const CommonEmailTemplate = ({
  body,
  firstName,
  lastName,
  rootUrl,
  title,
}: NotificationEmailProps & { title: string }) => (
  <Html>
    <Tailwind
      config={{
        theme: {
          extend: {
            colors: {
              primary: "rgb(255 88 67)",
              secondary: "rgb(5 7 52)",
            },
          },
        },
      }}
    >
      <Font
        fontFamily="Roboto"
        fallbackFontFamily="Verdana"
        webFont={{
          format: "woff2",
          url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
        }}
        fontWeight={400}
        fontStyle="normal"
      />
      <Preview>{`[DNA Script] ${title}`}</Preview>
      <Container className="p-4">
        <Section className="bg-secondary flex flex-row justify-between p-4">
          <Img
            src={`${rootUrl}/logo_red.png`}
            alt="Logo"
            width="311"
            height="31"
          />
          <Heading className="text-white" as="h2">
            {title}
          </Heading>
        </Section>
        <Section>
          <Text>{`Dear ${firstName} ${lastName}`},</Text>
        </Section>
        <Section>
          <Text dangerouslySetInnerHTML={{ __html: body }} />
        </Section>
        <Signature />
      </Container>
    </Tailwind>
  </Html>
);

export default CommonEmailTemplate;
