import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DatePicker } from "../ui/datepicker";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form";

const dateRangeSchema = z.object({
  range: z
    .object({
      end: z.date().optional(),
      start: z.date().optional(),
    })
    .refine(
      (data) => (data.start && data.end ? data.start <= data.end : true),
      {
        message: "Start date must be before end date",
      },
    ),
});

type DateRange = z.infer<typeof dateRangeSchema>;

export default function DateRangePicker({
  onSubmit,
  children,
}: React.PropsWithChildren<{
  onSubmit: (data: DateRange) => void;
}>) {
  const form = useForm<DateRange>({
    resolver: zodResolver(dateRangeSchema),
  });

  const errorMessage = form.formState.errors.range?.root?.message;

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex items-center space-x-4">
          <FormField
            control={form.control}
            name="range.start"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormControl>
                  <DatePicker
                    date={field.value}
                    placeholder="Pick start date"
                    setDate={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="range.end"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormControl>
                  <DatePicker
                    date={field.value}
                    placeholder="Pick end date"
                    setDate={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {errorMessage && (
            <p className="text-destructive text-sm font-medium">
              {errorMessage}
            </p>
          )}
          {children}
        </div>
      </form>
    </Form>
  );
}
