import type { RequestFormType } from "@backend/config/clientRequest/requestFormSchema";
import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { v4 } from "uuid";

import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";

export default function useScreenCustomerSequences(
  form: UseFormReturn<RequestFormType>,
) {
  const [isVerified, setIsVerified] = useState(false);
  const { toast } = useToast();
  const [requestId] = useState<string>(() => v4());
  const [progress, setProgress] = useState(0);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const { mutate: screenMutation } =
    trpc.screening.approveCustomerSequencesRequest.useMutation({
      onError(error) {
        toast({
          description: `Problem validating your sequences. Message: ${error.message}`,
          title: "Screening error",
          variant: "destructive",
        });
      },
    });

  const handleCheck = (request: RequestFormType) => {
    screenMutation({
      geneFragments: request.geneFragments.map((g) => g.sequence),
      oligos: request.oligos.map((o) => o.sequence),
      requestId,
    });
    setIsProcessingRequest(true);
  };

  trpc.screening.approveCustomerSequencesProgress.useSubscription(requestId, {
    onData(data) {
      setProgress(data);
    },
  });

  trpc.screening.approveCustomerSequencesResults.useSubscription(requestId, {
    onData(data) {
      const formValue = form.getValues();
      if (!data.length) {
        setIsVerified(true);
      }
      data.forEach((result) => {
        const correspondingOligos = formValue.oligos
          .map((o, index) =>
            o.sequence === result.sequence ? { index, o } : null,
          )
          .filter(Boolean);
        const correspondingGeneFragments = formValue.geneFragments
          .map((g, index) =>
            g.sequence === result.sequence ? { g, index } : null,
          )
          .filter(Boolean);
        correspondingOligos.forEach((oligo) => {
          if (!oligo) return;
          result.errors.forEach((error) => {
            form.setError(`oligos.${oligo.index}.sequence`, {
              message: error,
            });
          });
        });
        correspondingGeneFragments.forEach((gene) => {
          if (!gene) return;
          result.errors.forEach((error) => {
            form.setError(`geneFragments.${gene.index}.sequence`, {
              message: error,
            });
          });
        });
      });

      setProgress(0);
      setIsProcessingRequest(false);
    },
    onError(error) {
      setProgress(0);
      setIsProcessingRequest(false);
      toast({
        description: `Error: ${error.message}`,
        title: "Screening",
        variant: "destructive",
      });
    },
  });

  return {
    handleCheck,
    isProcessingRequest,
    isVerified,
    progress,
    setIsVerified,
  };
}
