import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Link, useNavigate } from "react-router-dom";

import { DataTable } from "../../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../components/ui/data-table/filters";
import { LoadingSpinner } from "../../../../components/ui/spinner";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { RunStateToBadge } from "../../../instrument/components/runs-table";
import { OrganizationRoutes } from "../../organization-routes";

type RunInList = inferProcedureOutput<
  AppRouter["assay"]["steps"]["plate"]["run"]["list"]
>[number];

const columns: ColumnDef<RunInList>[] = [
  {
    accessorKey: "plate.name",
    cell: ({ row }) => row.original.plate.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "id",
    cell: ({ row }) => row.original.id,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Run ID",
    },
  },
  {
    accessorKey: "instrument.name",
    cell: ({ row }) => (
      <Link
        className="text-blue-500 hover:underline"
        onClick={(e) => e.stopPropagation()}
        to={OrganizationRoutes.INSTRUMENT.replace(
          ":instrumentId",
          row.original.instrument.id,
        )}
      >
        {row.original.instrument.name}
      </Link>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Instrument",
    },
  },
  {
    accessorKey: "kitLabel",
    cell: ({ row }) => row.original.kitLabel,
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Kit",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "assay.name",
    cell: ({ row }) => (
      <Link
        className="text-blue-500 hover:underline"
        onClick={(e) => e.stopPropagation()}
        to={OrganizationRoutes.ASSAY.replace(":assayId", row.original.assay.id)}
      >
        {row.original.assay.name}
      </Link>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Assay",
    },
  },
  {
    accessorFn: (row) => new Date(row.updatedAt),
    cell: ({ row }) => row.original.updatedAt.toLocaleString(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "updatedAt",
    meta: {
      title: "UpdatedAt",
    },
  },
  {
    accessorKey: "state",
    cell: ({ row }) => RunStateToBadge[row.original.state],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "state",
    meta: {
      title: "State",
      uniqueFilter: true,
    },
  },
];

const RunsTable = () => {
  const navigate = useNavigate();
  const { data: runs, isPending } = trpc.assay.steps.plate.run.list.useQuery();

  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <DataTable
      columns={columns}
      data={runs ?? []}
      defaultSorting={[{ desc: true, id: "updatedAt" }]}
      loading={isPending}
      onRowClick={(row) =>
        navigate(
          OrganizationRoutes.ASSAY_WORKFLOW_STEP.replace(
            ":assayId",
            row.original.assay.id,
          ).replace(":stepId", row.original.plate.stepId),
        )
      }
    />
  );
};

export default function Runs() {
  return (
    <div className="space-y-4">
      <Heading>Runs</Heading>
      <RunsTable />
    </div>
  );
}
