import type { InstrumentTRPC } from "../useGetInstrument";

type BottleGroup = NonNullable<
  InstrumentTRPC["consumables"]
>["bottleGroups"][number];

export const getBottleColors = (
  bottleGroup: BottleGroup,
): { bg: string; color: string } => {
  if (bottleGroup.group === "SYN") {
    return { bg: "bg-[#fa6400]/30", color: "bg-[#fa6400]" };
  }
  if (bottleGroup.group === "NEZ") {
    return { bg: "bg-[#0168b3]/30", color: "bg-[#0168b3]" };
  }
  if (bottleGroup.group === "PSP") {
    return { bg: "bg-[#4ab767]/30", color: "bg-[#4ab767]" };
  }
  return { bg: "bg-slate-500/30", color: "bg-slate-500" };
};
