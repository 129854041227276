import { zodResolver } from "@hookform/resolvers/zod";
import type { inferProcedureInput } from "@trpc/server";
import { Brain } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../components/ui/accordion";
import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import { Label } from "../../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio";
import { RangeSlider } from "../../../../../components/ui/slider";
import { useToast } from "../../../../../components/ui/use-toast";
import type { AppRouter } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";

type CutGeneParameters = Omit<
  inferProcedureInput<AppRouter["assay"]["steps"]["gene"]["newDesign"]>,
  "constructId"
>;

enum PriorityMode {
  LENGTH = "length",
  TM = "tm",
}

const PriorityModeLabel = {
  [PriorityMode.TM]: "Melting temperature",
  [PriorityMode.LENGTH]: "Length",
};

const defaultUpperTM = 60;
const defaultLowerTM = 40;

const cutGeneParametersSchema = z.object({
  advanced: z.object({
    add_stubs: z.string().nullable().default(null),
    n_2_design: z.boolean().default(false),
    nb_primers: z.number().min(1).default(1),
    primer_first: z.boolean().default(false),
    smart_block_design: z.boolean().default(false),
    smart_oligo_design: z.boolean().default(false),
    use_error_correction: z.boolean().default(false),
  }),
  lower_tm_threshold: z.number().default(defaultLowerTM),
  name: z.string(),
  oligo_size: z.number().default(30),
  one_block_design: z.boolean().default(false),
  overlap_subblocks: z.number().default(50),
  priority: z.nativeEnum(PriorityMode).default(PriorityMode.TM),
  subblock_size: z.number().default(300),
  upper_tm_threshold: z.number().default(defaultUpperTM),
});

export type CutGeneAssemblyParameters = z.infer<typeof cutGeneParametersSchema>;

// eslint-disable-next-line react-refresh/only-export-components
export const DefaultAssemblyParameters: CutGeneAssemblyParameters = {
  advanced: {
    add_stubs: null,
    n_2_design: false,
    nb_primers: 1,
    primer_first: false,
    smart_block_design: false,
    smart_oligo_design: false,
    use_error_correction: false,
  },
  lower_tm_threshold: defaultLowerTM,
  name: "Design 1",
  oligo_size: 30,
  one_block_design: false,
  overlap_subblocks: 50,
  priority: PriorityMode.TM,
  subblock_size: 300,
  upper_tm_threshold: defaultUpperTM,
};

export default function NewDesign({
  constructId,
  geneId,
  assayId,
}: {
  assayId?: string;
  constructId: string;
  geneId: string;
}) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const utils = trpc.useUtils();
  const { mutate: cutGeneMutation, isPending } =
    trpc.assay.steps.gene.newDesign.useMutation({
      onError(error) {
        setOpen(false);
        toast({
          description: error.message,
          title: "Design failed",
          variant: "warning",
        });
      },
      onSuccess() {
        utils.assay.get.invalidate(assayId);
        utils.construct.get.invalidate({ id: constructId });
        utils.assay.steps.gene.listDesigns.invalidate({ geneId });
        setOpen(false);
        toast({
          description: "The oligos have been designed",
          title: "Design successful",
          variant: "success",
        });
      },
    });

  const designGeneOligos = (parameters: CutGeneParameters) => {
    if (!constructId) {
      return;
    }
    cutGeneMutation({ constructId, ...parameters });
  };

  const form = useForm<CutGeneAssemblyParameters>({
    defaultValues: DefaultAssemblyParameters,
    resolver: zodResolver(cutGeneParametersSchema),
  });

  function onSubmit(values: CutGeneAssemblyParameters) {
    designGeneOligos({ geneId, ...values });
  }

  const currentUpperTM = form.watch("upper_tm_threshold");
  const currentLowerTM = form.watch("lower_tm_threshold");

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          className="space-x-1"
          id="design-oligos"
          type="button"
          variant={"outline"}
        >
          <span>New design</span>
          <Brain />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form
            className="flex max-h-[90vh] flex-col space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <DialogTitle>
              <DialogHeader>New Design</DialogHeader>
            </DialogTitle>
            <div className="flex flex-col space-y-4 overflow-scroll px-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Name of the design</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder={DefaultAssemblyParameters.name.toString()}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subblock_size"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Total size of each block (bp)</FormLabel>
                    <FormControl>
                      <Input
                        onChange={(e) =>
                          field.onChange(parseInt(e.currentTarget.value))
                        }
                        placeholder={DefaultAssemblyParameters.subblock_size.toString()}
                        type="number"
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="one_block_design"
                render={({ field }) => {
                  const checked = field.value;
                  return (
                    <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          checked={checked}
                          onCheckedChange={(checked) => {
                            field.onChange(checked);
                          }}
                        />
                      </FormControl>
                      <FormLabel>Design one block</FormLabel>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="overlap_subblocks"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>
                      Desired overlap for block sequence (bp)
                    </FormLabel>
                    <FormControl>
                      <Input
                        onChange={(e) =>
                          field.onChange(parseInt(e.currentTarget.value))
                        }
                        placeholder={DefaultAssemblyParameters.overlap_subblocks.toString()}
                        type="number"
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="oligo_size"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Desired size of oligonucleotides (bp)</FormLabel>
                    <FormControl>
                      <Input
                        onChange={(e) =>
                          field.onChange(parseInt(e.currentTarget.value))
                        }
                        placeholder={DefaultAssemblyParameters.oligo_size.toString()}
                        type="number"
                        value={field.value}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem className="flex flex-col">
                <FormLabel>Desired melting temperature range (°C)</FormLabel>
                <div className="flex flex-row space-x-4">
                  <p className="flex space-x-1">
                    <span>Min</span>
                    <span>{currentLowerTM}°C</span>
                  </p>
                  <p className="flex space-x-1">
                    <span>Max</span>
                    <span>{currentUpperTM}°C</span>
                  </p>
                </div>
                <FormControl>
                  <RangeSlider
                    defaultValue={[defaultLowerTM, defaultUpperTM]}
                    max={80}
                    min={20}
                    minStepsBetweenThumbs={10}
                    onValueChange={(values) => {
                      form.setValue("lower_tm_threshold", values[0]);
                      form.setValue("upper_tm_threshold", values[1]);
                    }}
                    step={1}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormField
                control={form.control}
                name="priority"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Desired priority mode</FormLabel>
                    <FormControl>
                      <RadioGroup
                        className="flex flex-row gap-2"
                        onValueChange={(v) => {
                          field.onChange(v);
                        }}
                        value={field.value}
                      >
                        <div
                          className="flex items-center space-x-1"
                          key={PriorityMode.TM}
                        >
                          <RadioGroupItem
                            id={"tm-radio"}
                            value={PriorityMode.TM}
                          />
                          <Label htmlFor={"tm-radio"}>
                            {PriorityModeLabel[PriorityMode.TM]}
                          </Label>
                        </div>
                        <div
                          className="flex items-center space-x-1"
                          key={PriorityMode.LENGTH}
                        >
                          <RadioGroupItem
                            id={"tm-radio"}
                            value={PriorityMode.LENGTH}
                          />
                          <Label htmlFor={"tm-radio"}>
                            {PriorityModeLabel[PriorityMode.LENGTH]}
                          </Label>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Accordion collapsible type="single">
                <AccordionItem value="item-1">
                  <AccordionTrigger>Show advanced options</AccordionTrigger>
                  <AccordionContent className="flex flex-col space-y-4">
                    <FormField
                      control={form.control}
                      name="advanced.n_2_design"
                      render={({ field }) => {
                        const checked = field.value;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Do n-2 design for Error Correction step.
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.add_stubs"
                      render={({ field }) => {
                        const checked = field.value !== null;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked ? "auto" : null);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Use stubs at edge for the primer design.
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.use_error_correction"
                      render={({ field }) => {
                        const checked = field.value;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Design primers for the Error Correction step.
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.nb_primers"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel>
                            Number of Step 2 primers set you want to use.
                          </FormLabel>
                          <FormControl>
                            <Input
                              placeholder={DefaultAssemblyParameters.advanced.nb_primers.toString()}
                              {...field}
                              type="number"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.primer_first"
                      render={({ field }) => {
                        const checked = field.value;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Design primers before designing blocks
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.smart_block_design"
                      render={({ field }) => {
                        const checked = field.value;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Design smart block by using complexity score to
                              avoid difficult regions
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <FormField
                      control={form.control}
                      name="advanced.smart_oligo_design"
                      render={({ field }) => {
                        const checked = field.value;
                        return (
                          <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                            <FormControl>
                              <Checkbox
                                checked={checked}
                                onCheckedChange={(checked) => {
                                  field.onChange(checked);
                                }}
                              />
                            </FormControl>
                            <FormLabel>
                              Design smart oligos by using complexity score to
                              avoid difficult patterns
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <div className="flex flex-row justify-end">
                <Button isLoading={isPending} type="submit">
                  Design
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
