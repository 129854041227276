import { useSearchParams } from "react-router-dom";

export const useBooleanSearchParam = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get(paramName);

  const setSwitchValue = (checked: boolean) => {
    setSearchParams({ [paramName]: checked.toString() });
  };

  const isTrue = code === "true";

  return { isTrue, setSwitchValue };
};
