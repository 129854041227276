export default function CustomLink({
  to,
  children,
}: React.PropsWithChildren<{ to: string }>) {
  return (
    <a className="cursor-pointer underline" href={to}>
      {children}
    </a>
  );
}
