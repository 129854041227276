// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v4.24.4
// source: definitions/messages/process/Status.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "api.messages.process";

export interface Status {
  printJob: Status_PrintJob | undefined;
  machineWash: Status_MachineWash | undefined;
  progress: Status_Progress | undefined;
  errors: Status_Error[];
  warnings: Status_Warning[];
}

export interface Status_PrintJob {
  id: number;
  name: string;
}

export interface Status_MachineWash {
  type: Status_MachineWash_Type;
}

export enum Status_MachineWash_Type {
  EndOfKit = 0,
  Prewash = 1,
  Clean = 2,
  Rinse = 3,
}

export function status_MachineWash_TypeFromJSON(object: any): Status_MachineWash_Type {
  switch (object) {
    case 0:
    case "EndOfKit":
      return Status_MachineWash_Type.EndOfKit;
    case 1:
    case "Prewash":
      return Status_MachineWash_Type.Prewash;
    case 2:
    case "Clean":
      return Status_MachineWash_Type.Clean;
    case 3:
    case "Rinse":
      return Status_MachineWash_Type.Rinse;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Status_MachineWash_Type");
  }
}

export function status_MachineWash_TypeToJSON(object: Status_MachineWash_Type): string {
  switch (object) {
    case Status_MachineWash_Type.EndOfKit:
      return "EndOfKit";
    case Status_MachineWash_Type.Prewash:
      return "Prewash";
    case Status_MachineWash_Type.Clean:
      return "Clean";
    case Status_MachineWash_Type.Rinse:
      return "Rinse";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Status_MachineWash_Type");
  }
}

export interface Status_Phase {
  label: string;
  description: string;
  estimatedRemainingTime: Status_Phase_EstimatedRemainingTime | undefined;
  startTime: Date | undefined;
  timeToNextTouchpoint?: Status_Phase_EstimatedRemainingTime | undefined;
}

export interface Status_Phase_EstimatedRemainingTime {
  lastUpdate: Date | undefined;
  seconds: number;
}

export interface Status_Progress {
  startTime: Date | undefined;
  phases: Status_Phase[];
  currentPhaseIndex: number;
}

export interface Status_Error {
  description: string;
  location: string;
  type: string;
  unit: string;
  mitigation: string;
}

export interface Status_Warning {
  description: string;
}

function createBaseStatus(): Status {
  return { printJob: undefined, machineWash: undefined, progress: undefined, errors: [], warnings: [] };
}

export const Status = {
  encode(message: Status, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.printJob !== undefined) {
      Status_PrintJob.encode(message.printJob, writer.uint32(10).fork()).ldelim();
    }
    if (message.machineWash !== undefined) {
      Status_MachineWash.encode(message.machineWash, writer.uint32(18).fork()).ldelim();
    }
    if (message.progress !== undefined) {
      Status_Progress.encode(message.progress, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.errors) {
      Status_Error.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.warnings) {
      Status_Warning.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.printJob = Status_PrintJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.machineWash = Status_MachineWash.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.progress = Status_Progress.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errors.push(Status_Error.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.warnings.push(Status_Warning.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status {
    return {
      printJob: isSet(object.printJob) ? Status_PrintJob.fromJSON(object.printJob) : undefined,
      machineWash: isSet(object.machineWash) ? Status_MachineWash.fromJSON(object.machineWash) : undefined,
      progress: isSet(object.progress) ? Status_Progress.fromJSON(object.progress) : undefined,
      errors: globalThis.Array.isArray(object?.errors) ? object.errors.map((e: any) => Status_Error.fromJSON(e)) : [],
      warnings: globalThis.Array.isArray(object?.warnings)
        ? object.warnings.map((e: any) => Status_Warning.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Status): unknown {
    const obj: any = {};
    if (message.printJob !== undefined) {
      obj.printJob = Status_PrintJob.toJSON(message.printJob);
    }
    if (message.machineWash !== undefined) {
      obj.machineWash = Status_MachineWash.toJSON(message.machineWash);
    }
    if (message.progress !== undefined) {
      obj.progress = Status_Progress.toJSON(message.progress);
    }
    if (message.errors?.length) {
      obj.errors = message.errors.map((e) => Status_Error.toJSON(e));
    }
    if (message.warnings?.length) {
      obj.warnings = message.warnings.map((e) => Status_Warning.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status>, I>>(base?: I): Status {
    return Status.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status>, I>>(object: I): Status {
    const message = createBaseStatus();
    message.printJob = (object.printJob !== undefined && object.printJob !== null)
      ? Status_PrintJob.fromPartial(object.printJob)
      : undefined;
    message.machineWash = (object.machineWash !== undefined && object.machineWash !== null)
      ? Status_MachineWash.fromPartial(object.machineWash)
      : undefined;
    message.progress = (object.progress !== undefined && object.progress !== null)
      ? Status_Progress.fromPartial(object.progress)
      : undefined;
    message.errors = object.errors?.map((e) => Status_Error.fromPartial(e)) || [];
    message.warnings = object.warnings?.map((e) => Status_Warning.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStatus_PrintJob(): Status_PrintJob {
  return { id: 0, name: "" };
}

export const Status_PrintJob = {
  encode(message: Status_PrintJob, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_PrintJob {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_PrintJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_PrintJob {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: Status_PrintJob): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_PrintJob>, I>>(base?: I): Status_PrintJob {
    return Status_PrintJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_PrintJob>, I>>(object: I): Status_PrintJob {
    const message = createBaseStatus_PrintJob();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseStatus_MachineWash(): Status_MachineWash {
  return { type: 0 };
}

export const Status_MachineWash = {
  encode(message: Status_MachineWash, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_MachineWash {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_MachineWash();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_MachineWash {
    return { type: isSet(object.type) ? status_MachineWash_TypeFromJSON(object.type) : 0 };
  },

  toJSON(message: Status_MachineWash): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = status_MachineWash_TypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_MachineWash>, I>>(base?: I): Status_MachineWash {
    return Status_MachineWash.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_MachineWash>, I>>(object: I): Status_MachineWash {
    const message = createBaseStatus_MachineWash();
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseStatus_Phase(): Status_Phase {
  return {
    label: "",
    description: "",
    estimatedRemainingTime: undefined,
    startTime: undefined,
    timeToNextTouchpoint: undefined,
  };
}

export const Status_Phase = {
  encode(message: Status_Phase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.estimatedRemainingTime !== undefined) {
      Status_Phase_EstimatedRemainingTime.encode(message.estimatedRemainingTime, writer.uint32(26).fork()).ldelim();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.timeToNextTouchpoint !== undefined) {
      Status_Phase_EstimatedRemainingTime.encode(message.timeToNextTouchpoint, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_Phase {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_Phase();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.estimatedRemainingTime = Status_Phase_EstimatedRemainingTime.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.timeToNextTouchpoint = Status_Phase_EstimatedRemainingTime.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_Phase {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      estimatedRemainingTime: isSet(object.estimatedRemainingTime)
        ? Status_Phase_EstimatedRemainingTime.fromJSON(object.estimatedRemainingTime)
        : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      timeToNextTouchpoint: isSet(object.timeToNextTouchpoint)
        ? Status_Phase_EstimatedRemainingTime.fromJSON(object.timeToNextTouchpoint)
        : undefined,
    };
  },

  toJSON(message: Status_Phase): unknown {
    const obj: any = {};
    if (message.label !== "") {
      obj.label = message.label;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.estimatedRemainingTime !== undefined) {
      obj.estimatedRemainingTime = Status_Phase_EstimatedRemainingTime.toJSON(message.estimatedRemainingTime);
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.timeToNextTouchpoint !== undefined) {
      obj.timeToNextTouchpoint = Status_Phase_EstimatedRemainingTime.toJSON(message.timeToNextTouchpoint);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_Phase>, I>>(base?: I): Status_Phase {
    return Status_Phase.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_Phase>, I>>(object: I): Status_Phase {
    const message = createBaseStatus_Phase();
    message.label = object.label ?? "";
    message.description = object.description ?? "";
    message.estimatedRemainingTime =
      (object.estimatedRemainingTime !== undefined && object.estimatedRemainingTime !== null)
        ? Status_Phase_EstimatedRemainingTime.fromPartial(object.estimatedRemainingTime)
        : undefined;
    message.startTime = object.startTime ?? undefined;
    message.timeToNextTouchpoint = (object.timeToNextTouchpoint !== undefined && object.timeToNextTouchpoint !== null)
      ? Status_Phase_EstimatedRemainingTime.fromPartial(object.timeToNextTouchpoint)
      : undefined;
    return message;
  },
};

function createBaseStatus_Phase_EstimatedRemainingTime(): Status_Phase_EstimatedRemainingTime {
  return { lastUpdate: undefined, seconds: 0 };
}

export const Status_Phase_EstimatedRemainingTime = {
  encode(message: Status_Phase_EstimatedRemainingTime, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastUpdate !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdate), writer.uint32(10).fork()).ldelim();
    }
    if (message.seconds !== 0) {
      writer.uint32(16).int64(message.seconds);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_Phase_EstimatedRemainingTime {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_Phase_EstimatedRemainingTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lastUpdate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.seconds = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_Phase_EstimatedRemainingTime {
    return {
      lastUpdate: isSet(object.lastUpdate) ? fromJsonTimestamp(object.lastUpdate) : undefined,
      seconds: isSet(object.seconds) ? globalThis.Number(object.seconds) : 0,
    };
  },

  toJSON(message: Status_Phase_EstimatedRemainingTime): unknown {
    const obj: any = {};
    if (message.lastUpdate !== undefined) {
      obj.lastUpdate = message.lastUpdate.toISOString();
    }
    if (message.seconds !== 0) {
      obj.seconds = Math.round(message.seconds);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_Phase_EstimatedRemainingTime>, I>>(
    base?: I,
  ): Status_Phase_EstimatedRemainingTime {
    return Status_Phase_EstimatedRemainingTime.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_Phase_EstimatedRemainingTime>, I>>(
    object: I,
  ): Status_Phase_EstimatedRemainingTime {
    const message = createBaseStatus_Phase_EstimatedRemainingTime();
    message.lastUpdate = object.lastUpdate ?? undefined;
    message.seconds = object.seconds ?? 0;
    return message;
  },
};

function createBaseStatus_Progress(): Status_Progress {
  return { startTime: undefined, phases: [], currentPhaseIndex: 0 };
}

export const Status_Progress = {
  encode(message: Status_Progress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.phases) {
      Status_Phase.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.currentPhaseIndex !== 0) {
      writer.uint32(24).int32(message.currentPhaseIndex);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_Progress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_Progress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phases.push(Status_Phase.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currentPhaseIndex = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_Progress {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      phases: globalThis.Array.isArray(object?.phases) ? object.phases.map((e: any) => Status_Phase.fromJSON(e)) : [],
      currentPhaseIndex: isSet(object.currentPhaseIndex) ? globalThis.Number(object.currentPhaseIndex) : 0,
    };
  },

  toJSON(message: Status_Progress): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.phases?.length) {
      obj.phases = message.phases.map((e) => Status_Phase.toJSON(e));
    }
    if (message.currentPhaseIndex !== 0) {
      obj.currentPhaseIndex = Math.round(message.currentPhaseIndex);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_Progress>, I>>(base?: I): Status_Progress {
    return Status_Progress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_Progress>, I>>(object: I): Status_Progress {
    const message = createBaseStatus_Progress();
    message.startTime = object.startTime ?? undefined;
    message.phases = object.phases?.map((e) => Status_Phase.fromPartial(e)) || [];
    message.currentPhaseIndex = object.currentPhaseIndex ?? 0;
    return message;
  },
};

function createBaseStatus_Error(): Status_Error {
  return { description: "", location: "", type: "", unit: "", mitigation: "" };
}

export const Status_Error = {
  encode(message: Status_Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.location !== "") {
      writer.uint32(18).string(message.location);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    if (message.unit !== "") {
      writer.uint32(34).string(message.unit);
    }
    if (message.mitigation !== "") {
      writer.uint32(42).string(message.mitigation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_Error {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_Error();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.location = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unit = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mitigation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_Error {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      location: isSet(object.location) ? globalThis.String(object.location) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      unit: isSet(object.unit) ? globalThis.String(object.unit) : "",
      mitigation: isSet(object.mitigation) ? globalThis.String(object.mitigation) : "",
    };
  },

  toJSON(message: Status_Error): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.location !== "") {
      obj.location = message.location;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.unit !== "") {
      obj.unit = message.unit;
    }
    if (message.mitigation !== "") {
      obj.mitigation = message.mitigation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_Error>, I>>(base?: I): Status_Error {
    return Status_Error.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_Error>, I>>(object: I): Status_Error {
    const message = createBaseStatus_Error();
    message.description = object.description ?? "";
    message.location = object.location ?? "";
    message.type = object.type ?? "";
    message.unit = object.unit ?? "";
    message.mitigation = object.mitigation ?? "";
    return message;
  },
};

function createBaseStatus_Warning(): Status_Warning {
  return { description: "" };
}

export const Status_Warning = {
  encode(message: Status_Warning, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Status_Warning {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatus_Warning();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Status_Warning {
    return { description: isSet(object.description) ? globalThis.String(object.description) : "" };
  },

  toJSON(message: Status_Warning): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Status_Warning>, I>>(base?: I): Status_Warning {
    return Status_Warning.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Status_Warning>, I>>(object: I): Status_Warning {
    const message = createBaseStatus_Warning();
    message.description = object.description ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
