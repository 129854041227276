import type { inferProcedureOutput } from "@trpc/server";
import { useParams } from "react-router-dom";

import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";

export type OrganizationProfile = inferProcedureOutput<
  AppRouter["account"]["organization"]["read"]
>;

export const useGetOrganizationProfile = () => {
  const { organizationId } = useParams();
  return useGetOrganizationFromOrganizationId(organizationId);
};

export const useGetOrganizationFromOrganizationId = (
  organizationId: string | undefined,
) => {
  return trpc.account.organization.read.useQuery(
    {
      id: organizationId ?? "",
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useGetSupportUsersOfOrganization = (organizationId: string) => {
  return trpc.account.organization.listSupportUsers.useQuery(
    {
      organizationId: organizationId ?? "",
    },
    {
      enabled: !!organizationId,
      initialData: [],
    },
  );
};
