import { useContext } from "react";

import type { Auth } from "./AuthContainer";
import { AuthContext } from "./AuthContainer";
import { UserContext } from "./contexts";

export const useAuth = (): Auth => {
  return useContext(AuthContext);
};

export const useUser = (): UserContext => {
  return useContext(UserContext);
};
