import { Heading } from "@radix-ui/themes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { OrganizationContext } from "./contexts";
import { useAuth } from "./hooks";

import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";
import { RootRoutes } from "../routes/routes";

const OrganizationProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data, refetch, isPending, error } =
    trpc.account.organization.readMine.useQuery(undefined, {
      enabled: !!token,
    });

  useEffect(() => {
    if (error) {
      toast({
        description: error.message,
        title: "Error loading organization",
        variant: "destructive",
      });
      navigate(RootRoutes.PICK_ORGANIZATION);
    }
  }, [error, navigate, toast]);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  if (!token) {
    return <Heading>Waiting for authentication...</Heading>;
  }

  if (isPending) {
    return <Heading>Loading organization...</Heading>;
  }

  return (
    <OrganizationContext.Provider value={data}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
