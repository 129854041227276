import { Download } from "lucide-react";

import { useGetMyOrganization } from "./hooks";

import DateRangePicker from "../../../components/logic/date-range-picker";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { useToast } from "../../../components/ui/use-toast";
import { trpc } from "../../../config/trpc";
import { useDownloadFile } from "../../../utils/useDownload";

export default function OrganizationAdmin() {
  const organization = useGetMyOrganization();
  const downloadFile = useDownloadFile();
  const { toast } = useToast();

  const { mutate: getAuditEventsUrl, isPending } =
    trpc.config.downloadEvents.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(url) {
        (async () => {
          try {
            await downloadFile(url, "audit.csv");
          } catch (error) {
            const message =
              "message" in (error as any) ? (error as any).message : "";
            toast({
              description: message,
              title: "Error when downloading audit logs",
              variant: "destructive",
            });
          }
        })();
      },
    });

  const handleAuditEvents = (data: { range: { end?: Date; start?: Date } }) => {
    if (!organization) {
      return;
    }
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const startDate = data.range.start
      ? new Date(data.range.start?.getTime() - tzOffset).toISOString()
      : undefined;
    const endDate = data.range.end
      ? new Date(data.range.end?.getTime() - tzOffset).toISOString()
      : undefined;

    getAuditEventsUrl({
      endDate,
      organizationId: organization?.id,
      startDate,
    });
  };

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle>Admin</CardTitle>
      </CardHeader>
      <CardContent>
        <DateRangePicker onSubmit={handleAuditEvents}>
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={isPending}
            type="submit"
            variant={"secondary"}
          >
            <span>Download events</span>
            <Download />
          </Button>
        </DateRangePicker>
      </CardContent>
    </Card>
  );
}
