import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { useToast } from "../../components/ui/use-toast";
import { trpc } from "../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../containers/user/hooks";
import { OrganizationRoutes } from "../organization/organization-routes";

const newInstrumentFormSchema = z.object({
  ipAddress: z.string().trim().min(1),
  name: z.string().trim().min(1),
  usbPort: z.string().trim().min(1),
});
type NewInstrumentForm = z.infer<typeof newInstrumentFormSchema>;

export default function NewHamiltonInstrument() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { hamiltonInstrument: canCreateHamiltonInstrument } =
    useGetUIAuthorizedCreations();
  const form = useForm<NewInstrumentForm>({
    defaultValues: {
      ipAddress: "",
      name: "",
    },
    resolver: zodResolver(newInstrumentFormSchema),
  });

  const { mutate: createInstrument } =
    trpc.instrument.hamilton.create.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Instrument creation failed",
          variant: "destructive",
        });
      },
      onSuccess(hamiltonId) {
        toast({
          title: "Instrument created",
          variant: "success",
        });
        navigate(
          OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
            ":instrumentId",
            hamiltonId,
          ),
        );
      },
    });

  function onSubmit(values: NewInstrumentForm) {
    createInstrument({
      ipAddress: values.ipAddress,
      name: values.name,
      usbPort: values.usbPort,
    });
  }

  if (!canCreateHamiltonInstrument) {
    navigate(OrganizationRoutes.INSTRUMENTS);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="bg-card space-y-4 rounded-lg border p-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ipAddress"
            render={({ field }) => (
              <FormItem>
                <FormLabel>IP address</FormLabel>
                <FormControl>
                  <Input placeholder="192.168.0.1" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="usbPort"
            render={({ field }) => (
              <FormItem>
                <FormLabel>USB port</FormLabel>
                <FormControl>
                  <Input placeholder="COM4" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row space-x-2">
          <Button type="submit">Submit</Button>
          <Button
            onClick={() => navigate(OrganizationRoutes.INSTRUMENTS)}
            variant={"secondary"}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
}
