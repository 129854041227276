import { useMemo } from "react";

import { useGetEnvironmentKits } from "../../../../admin/organizations/organization/components/useGetEnvironmentKits";

export const useGetCurrentKitConcentrations = (currentKit: string) => {
  const environmentKits = useGetEnvironmentKits();

  return useMemo(() => {
    const currentKitDetails = environmentKits.find(
      (kit) => kit.id === currentKit,
    );
    if (!currentKitDetails) {
      return { kitConcentrationMax: 0, kitConcentrationMin: 0 };
    }
    return {
      kitConcentrationMax: currentKitDetails.maxConcentration,
      kitConcentrationMin: currentKitDetails.minConcentration,
    };
  }, [currentKit, environmentKits]);
};
