import { DialogDescription } from "@radix-ui/react-dialog";
import { useState } from "react";

import { Button } from "../../../components/ui/button";
import CopyToClipboard from "../../../components/ui/clipboard";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useToast } from "../../../components/ui/use-toast";
import { trpc } from "../../../config/trpc";

export default function ResetMfaRecovery() {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const { data: mfaRecoveryCode, refetch } =
    trpc.account.user.mfa.generateRecoveryCode.useQuery(undefined, {
      enabled: open,
      initialData: "",
    });

  const { mutate: setMFARecoveryCode } =
    trpc.account.user.mfa.setRecoveryToken.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        setOpen(false);
        toast({
          title: "MFA recovery code was changed",
          variant: "success",
        });
      },
    });

  const handleReset = () => {
    setMFARecoveryCode({
      recoveryCode: mfaRecoveryCode,
    });
  };

  return (
    <div>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogTrigger asChild>
          <Button
            onClick={() => {
              refetch();
            }}
            variant={"outline"}
          >
            Regenerate recovery code
          </Button>
        </DialogTrigger>
        <DialogContent className="min-w-[600px] space-y-4">
          <DialogHeader>
            <DialogTitle>Regenerate recovery code</DialogTitle>
            <DialogDescription>
              If you want to reset MFA recovery code to value below please copy
              and save it wherever you keep other valuable information before
              submitting. <p />
              You can use it to unlock your account in case you lose your MFA
              device.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-row items-center space-x-1">
            <p>{mfaRecoveryCode}</p>
            <CopyToClipboard value={mfaRecoveryCode} />
          </div>
          <Button onClick={handleReset}>Submit</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
