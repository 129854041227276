import { LexicalComposer } from "@lexical/react/LexicalComposer";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import type { RootNode, ParagraphNode, EditorState } from "lexical";
import { TextNode, LineBreakNode } from "lexical";
import { Info } from "lucide-react";
import { useEffect, useState } from "react";

import FloatingTextFormatToolbarPlugin from "./floating-text";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";

function onError(error: Error) {
  // eslint-disable-next-line no-console
  console.error(error);
}

const SequenceLengthPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [sequence, setSequence] = useState<string>("");

  useEffect(() => {
    const removeListener = editor.registerUpdateListener(({ editorState }) => {
      const nodeList = transformEditorStateIntoNodes(editorState);
      setSequence(nodeList.map((n) => n.sequence ?? "").join(""));
    });
    return () => {
      removeListener();
    };
  }, [editor]);

  const length = sequence.length;

  return <p className="right-2 top-0">Length: {length}</p>;
};

const EnterPreventPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(LineBreakNode, (node) => {
      node.remove();
    });
  }, [editor]);

  return null;
};

const SelectionPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState<[number, number] | null>(null);

  useEffect(() => {
    const removeListener = editor.registerUpdateListener(({ editorState }) => {
      const [start, end] = editorState._selection?.getStartEndPoints() || [
        null,
        null,
      ];
      const nodeList = transformEditorStateIntoNodes(editorState);
      const startNode = nodeList.find((n) => n.id === start?.key);
      const endNode = nodeList.find((n) => n.id === end?.key);
      if (!start || !end || !startNode || !endNode) {
        setSelection(null);
        return;
      }
      const startOffset = startNode.start + start.offset;
      const endOffset = endNode.start + end.offset;
      setSelection(
        startOffset < endOffset
          ? [startOffset, endOffset]
          : [endOffset, startOffset],
      );
    });
    return () => {
      removeListener();
    };
  }, [editor]);

  if (!selection) {
    return <p>No selection</p>;
  }

  if (selection[0] === selection[1]) {
    return <p>Caret at {selection[0]}</p>;
  }

  return (
    <p>
      Selection from {selection[0]} to {selection[1]}
    </p>
  );
};

function SetStatePlugin({ value }: { value: string }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    try {
      const editorState = editor.parseEditorState(value);
      editor.setEditorState(editorState);
    } catch (error) {
      const editorState = editor.parseEditorState({
        root: {
          children: [
            {
              // @ts-expect-error Problem with lexical types
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: value,
                  type: "text",
                  version: 1,
                },
              ],
              direction: "ltr",
              format: "",
              indent: 0,
              type: "paragraph",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "root",
          version: 1,
        },
      });
      editor.setEditorState(editorState);
    }
  }, [editor, value]);

  return null;
}

const transformEditorStateIntoNodes = (
  state: EditorState,
): {
  end: number;
  id: string;
  sequence: string;
  start: number;
  style: string;
}[] => {
  const nodes: {
    end: number;
    id: string;
    sequence: string;
    start: number;
    style: string;
  }[] = [];
  const nodeMap = state._nodeMap;
  const paragraph = nodeMap.get(
    (nodeMap.get("root") as RootNode).__first ?? "",
  ) as ParagraphNode;
  let currentTextNode = nodeMap.get(paragraph.__first ?? "");
  let index = 0;
  while (currentTextNode) {
    if (currentTextNode instanceof TextNode) {
      const length = currentTextNode.__text.length;
      nodes.push({
        end: index + length,
        id: currentTextNode.__key,
        sequence: currentTextNode.__text,
        start: index,
        style: currentTextNode.__style,
      });
      index += length;
    }
    currentTextNode = nodeMap.get(currentTextNode.__next ?? "");
  }
  return nodes;
};

const initialConfig: InitialConfigType = {
  namespace: "MyEditor",
  onError,
  theme: {
    ltr: "font-mono",
    paragraph: "p-2 rounded-lg bg-slate-100 min-h-[40px]",
  },
};

export function Editor({
  initialValue,
  setValue,
}: {
  initialValue: string;
  setValue: (value: string) => void;
}) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onEditorStateChange = (state: EditorState) => {
    setValue(JSON.stringify(state));
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex-items flex space-x-1">
          <Tooltip delayDuration={0}>
            <TooltipTrigger
              className="flex flex-row items-center space-x-1"
              type="button"
            >
              <span className="text-sm">Legend</span>
              <Info size={16} />
            </TooltipTrigger>
            <TooltipContent>
              <div className="grid grid-cols-3 gap-2 ">
                <p
                  className="col-span-1"
                  style={{
                    color: "red",
                  }}
                >
                  CGACTAGCTGACTACGT
                </p>
                <p className="col-span-2 flex flex-col">
                  <span className="font-bold">Optimized region</span>
                  <span className="max-w-[400px]">
                    The highlighted nucleotide sequence will be optimized to
                    eliminate hard to synthesize codons. Feature currently
                    disabled.
                  </span>
                </p>
                <p
                  className="col-span-1"
                  style={{
                    color: "blue",
                  }}
                >
                  CGATCAAACACCC
                </p>
                <p className="col-span-2 flex flex-col">
                  <span className="font-bold">Block</span>
                  <span className="max-w-[400px]">
                    {`Those sequences won't be synthesized in the Syntax. You need to provide them at assembly time, in the Hamilton.`}
                  </span>
                </p>
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
        <SequenceLengthPlugin />
      </div>
      <PlainTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={
          <div className="relative" ref={onRef}>
            <ContentEditable />
          </div>
        }
        placeholder={<div>Enter gene sequence...</div>}
      />
      <SetStatePlugin value={initialValue} />
      <HistoryPlugin />
      {floatingAnchorElem && (
        <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
      )}
      <EnterPreventPlugin />
      <OnChangePlugin onChange={onEditorStateChange} />
      <SelectionPlugin />
    </LexicalComposer>
  );
}
