export const RackWidthReference = 720;
export const RackHeightReference = 312;
export const ReagentColors = {
  generic: { bg: "bg-reagent-generic", text: "text-black" },
  h20: { bg: "bg-reagent-h20", text: "text-black" },
  nez: { bg: "bg-reagent-nez", text: "text-white" },
  nucleotides: { bg: "bg-reagent-nucleotides", text: "text-black" },
  other: { bg: "bg-reagent-other", text: "text-black" },
  psp: { bg: "bg-reagent-psp", text: "text-black" },
  syn: { bg: "bg-reagent-syn", text: "text-white" },
  synth: { bg: "bg-reagent-synth", text: "text-black" },
  wsh: { bg: "bg-reagent-wsh", text: "text-white" },
};
export const getReagentColorsFromGroup = (group: string) => {
  const groupLowercase = (group.toLowerCase() ||
    "other") as keyof typeof ReagentColors;
  return ReagentColors[groupLowercase];
};
export const RackTrayBottlesSettings: Record<
  number,
  {
    height: number;
    left: number;
    top: number;
    width: number;
  }
> = {
  // W2
  0: {
    height: 120,
    left: 21,
    top: 26,
    width: 120,
  },
  // EB
  1: {
    height: 86,
    left: 153,
    top: 26,
    width: 86,
  },

  // W3
  10: {
    height: 65,
    left: 436,
    top: 120,
    width: 65,
  },

  // LB
  11: {
    height: 65,
    left: 512,
    top: 120,
    width: 65,
  },

  // dG
  12: {
    height: 65,
    left: 284,
    top: 198,
    width: 65,
  },

  // dT
  13: {
    height: 65,
    left: 359,
    top: 198,
    width: 65,
  },

  // W7
  14: {
    height: 65,
    left: 436,
    top: 198,
    width: 65,
  },

  // W4
  15: {
    height: 65,
    left: 512,
    top: 198,
    width: 65,
  },

  // EL
  16: {
    height: 95,
    left: 588,
    top: 172,
    width: 95,
  },

  // WB1
  2: {
    height: 96,
    left: 330,
    top: 16,
    width: 96,
  },

  // W6
  3: {
    height: 66,
    left: 436,
    top: 32,
    width: 66,
  },

  //
  4: {
    height: 65,
    left: 510,
    top: 45,
    width: 65,
  },

  // W1
  5: {
    height: 95,
    left: 590,
    top: 44,
    width: 95,
  },

  // DB
  6: {
    height: 120,
    left: 21,
    top: 160,
    width: 120,
  },

  // DB if needed
  7: {
    height: 120,
    left: 153,
    top: 160,
    width: 120,
  },

  // dA
  8: {
    height: 65,
    left: 284,
    top: 122,
    width: 65,
  },

  // dC
  9: {
    height: 65,
    left: 359,
    top: 122,
    width: 65,
  },
};
