import {
  ActivateMemberButton,
  DeactivateMFAButton,
  DeactivateMemberButton,
  ForcePasswordChangeButton,
  ResetPasswordButton,
} from "./organization-settings-users-table";

import { TableActions } from "../../../../components/ui/data-table/data-table-actions";
import { useUser } from "../../../../containers/hooks";
import type { OrganizationMemberInOrganization } from "../hooks";

export default function ActionsCell({
  member,
}: {
  member: OrganizationMemberInOrganization;
}) {
  const id = member.id;
  const canEdit = member.canEdit;
  const isMe = useUser()?.membership?.id === id;

  return (
    <TableActions
      items={[
        {
          children: <DeactivateMemberButton id={id} />,
          disabled: !canEdit || isMe,
          id: "DeactivateMemberButton",
          skip: !member.active,
        },
        {
          children: <ActivateMemberButton id={member.id} />,
          disabled: !canEdit,
          id: "ActivateMemberButton",
          skip: member.active,
        },
        {
          children: <DeactivateMFAButton userId={member.userId} />,
          disabled: !canEdit || isMe,
          id: "DeactivateMFAButton",
          skip: !member.mfaActivated,
        },
        {
          children: <ForcePasswordChangeButton userId={member.userId} />,
          id: "ForcePasswordChangeButton",
        },
        {
          children: <ResetPasswordButton email={member.email} />,
          id: "ResetPasswordButton",
        },
      ]}
    />
  );
}
