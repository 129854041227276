import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $patchStyleText } from "@lexical/selection";
import type { LexicalCommand } from "lexical";
import { $getSelection, COMMAND_PRIORITY_LOW, createCommand } from "lexical";
import { useEffect } from "react";

export const CHANGE_SEQUENCE_COMMAND: LexicalCommand<string> = createCommand(
  "OPTIMIZE_SEQUENCE_COMMAND",
);

type Payload = "optimize" | "block" | "reset";
const PAYLOAD_NEW_STYLE: Record<Payload, Record<string, string>> = {
  block: {
    color: "blue",
  },
  optimize: {
    color: "red",
  },
  reset: {
    color: "",
  },
};

export const useChangeSequence = () => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const removeUpdateListener = editor.registerCommand(
      CHANGE_SEQUENCE_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if (!selection) {
          return false;
        }
        const newStyle = PAYLOAD_NEW_STYLE?.[payload as Payload] || {};
        $patchStyleText(selection, newStyle);
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      removeUpdateListener();
    };
  }, [editor]);
};
