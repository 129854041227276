import { Button } from "../../../../../../components/ui/button";
import { useToast } from "../../../../../../components/ui/use-toast";
import { trpc } from "../../../../../../config/trpc";
import { useDownloadFile } from "../../../../../../utils/useDownload";

export const GenerateInstructionButton = ({ stepId }: { stepId: string }) => {
  const { toast } = useToast();
  const downloadFile = useDownloadFile();
  const { mutate: generateGeneInstructions } =
    trpc.assay.steps.gene.getInstructions.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess(url) {
        downloadFile(url, "instructions.zip");
      },
    });

  const handleGenerateInstructions = () => {
    generateGeneInstructions(stepId);
  };

  return (
    <Button onClick={handleGenerateInstructions}>Generate instructions</Button>
  );
};
