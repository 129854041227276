import Logo from "./header/logo";

import { TextGenerateEffect } from "../../components/ui/text-generate-effect";
import { WavyBackground } from "../../components/ui/wavy-background";

export default function PageWithWallPaper({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  return (
    <div className="flex h-screen w-full flex-row justify-center">
      <div className="absolute left-[100px] top-[100px] z-10 h-[200px] w-[300px]">
        <div className="p-4">
          <Logo scriptColor="#000000" />
        </div>
        <TextGenerateEffect
          className="font-dark pl-4"
          words={
            "We break down barriers to deliver faster results, more efficient workflows, and infinite possibilities."
          }
        />
      </div>
      <div className="flex h-screen flex-col items-center justify-center space-y-8">
        {/* Wavy background, color equivalent to --background css hsl variable */}
        <WavyBackground backgroundFill="#F7F7F7" className="w-full">
          {children}
        </WavyBackground>
      </div>
    </div>
  );
}
