export function getFormattedTimeFromMS(
  totalMSeconds: number,
  showSeconds = false,
  space = " ",
) {
  if (totalMSeconds <= 0) {
    return "--";
  }
  const hours = Math.floor(totalMSeconds / 1000 / 60 / 60);
  const min = Math.floor(totalMSeconds / 1000 / 60) - hours * 60;
  const sec = Math.floor(totalMSeconds / 1000) - min * 60 - hours * 60 * 60;
  let result = "";

  if (hours) {
    result += hours + "h" + space;
  }

  if (min) {
    result += min + "m" + space;
  }

  if (showSeconds) {
    result += sec + "s";
  } else if (!hours && !min) {
    result = sec + "s";
  }

  return result;
}

export function getFormattedTimeFromS(
  totalSeconds: number,
  showSeconds = false,
  space = " ",
) {
  return getFormattedTimeFromMS(totalSeconds * 1000, showSeconds, space);
}
