import { StepType } from "@backend/config/db/types";
import { Link } from "react-router-dom";

import type {
  GeneFromStep,
  PlateRun,
  WorkflowStepTRPC,
} from "./components/plate/types";
import RunProgress from "./run-progress";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Separator } from "../../../../components/ui/separator";
import { RunStateToBadge } from "../../../../routes/instrument/components/runs-table";
import { CheckIfTrueElseCross } from "../../../admin/organizations/components/ui";
import { OrganizationRoutes } from "../../organization-routes";

const GeneProgress = ({ gene }: { gene: GeneFromStep }) => {
  const {
    instrument,
    construct,
    oligoCount,
    oligosSynthesizedTotal,
    oligosSuccessfullySynthesized,
    oligosWithMoreThanOneSynthesis,
  } = gene;

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle>
          <span>Gene</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-2">
        <div className="flex flex-row items-center space-x-2">
          <div className="flex flex-col">
            <p className="italic">Gene length</p>
            <p className="text-right">{construct.sequence.length}</p>
          </div>
          <div className="flex flex-col">
            <p className="italic">Oligos</p>
            <p className="text-right">{oligoCount}</p>
          </div>
          <div className="flex flex-col">
            <p className="italic">Has vector ?</p>
            <p className="flex justify-end">
              <CheckIfTrueElseCross
                value={Boolean(construct.vector?.sequence)}
              />
            </p>
          </div>
        </div>
        <Separator />
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <p className="italic">Oligos successfully synthesized</p>
            <p className="text-right">{oligosSuccessfullySynthesized}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="italic">Oligos with at least one retry</p>
            <p className="text-right">{oligosWithMoreThanOneSynthesis}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="italic">Oligos remaining to be synthesized</p>
            <p className="text-right">
              {oligoCount - oligosSuccessfullySynthesized}
            </p>
          </div>
          <div className="flex flex-row justify-between">
            <p className="italic">Total oligos synthesized</p>
            <p className="text-right">{oligosSynthesizedTotal}</p>
          </div>
        </div>
        <Separator />
        <div className="flex h-14 flex-row items-center space-x-2">
          <div className="flex flex-col">
            <p className="italic">Instrument</p>
            <p className="text-right">
              {instrument ? (
                <Link
                  className="text-blue-500 hover:underline"
                  to={OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
                    ":instrumentId",
                    instrument.id,
                  )}
                >
                  {instrument.name}
                </Link>
              ) : (
                "No assigned instrument"
              )}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const RunProgressCard = ({ run }: { run: PlateRun }) => {
  const { state } = run;
  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle className="flex flex-row justify-between">
          <div className="flex flex-row items-center space-x-4">
            <span>Progress</span>
          </div>
          <div>{RunStateToBadge[state]}</div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-row justify-between">
        <RunProgress run={run} />
      </CardContent>
    </Card>
  );
};

export default function WorkflowProgress({ step }: { step: WorkflowStepTRPC }) {
  if (step.stepType === StepType.HamiltonAssembly) {
    return <GeneProgress gene={step.gene} />;
  }

  if (step.stepType === StepType.OligoSynthesis) {
    const {
      plate: { run },
    } = step;

    if (!run) {
      return null;
    }

    return <RunProgressCard run={run} />;
  }

  return null;
}
