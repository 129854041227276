import { zodResolver } from "@hookform/resolvers/zod";
import { Text, Heading } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useCanInviteUser } from "./hooks";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { RolesEditWithChange } from "../../../components/logic/roles";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { PasswordInput } from "../../../components/ui/password";
import { Switch } from "../../../components/ui/switch";
import { useToast } from "../../../components/ui/use-toast";
import { Role } from "../../../config/enums";
import { trpc } from "../../../config/trpc";

const newOrganizationMemberForm = z.object({
  email: z.string().trim().email(),
  firstname: z.string().trim().min(1).max(100),
  lastname: z.string().trim().min(1).max(100),
  password: z.string().trim().min(8).max(50).optional(),
  roles: z.array(z.nativeEnum(Role)),
});

type NewOrganizationMemberForm = z.infer<typeof newOrganizationMemberForm>;

export default function NewOrganizationMember() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [setPassword, setSetPassword] = useState(true);
  const canInviteUser = useCanInviteUser();
  const form = useForm<NewOrganizationMemberForm>({
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      roles: [],
    },
    resolver: zodResolver(newOrganizationMemberForm),
  });

  useEffect(() => {
    if (!canInviteUser) {
      toast({
        description: "You are not allowed to invite a new user",
        title: "Error",
        variant: "destructive",
      });
      navigate(OrganizationSettingsRoutes.USERS);
    }
  }, [canInviteUser, navigate, toast]);

  const utils = trpc.useUtils();

  const { mutate: inviteOrganizationMember } =
    trpc.account.organizationUser.invite.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        navigate(OrganizationSettingsRoutes.USERS);
        utils.account.organization.readMine.invalidate();
      },
    });

  function onSubmit(values: NewOrganizationMemberForm) {
    inviteOrganizationMember({
      email: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      password: values.password ?? null,
      roles: values.roles,
    });
  }

  const currentRoles = form.watch("roles");
  const handleChangeRoles = (values: Role[]) => {
    if (JSON.stringify(currentRoles) === JSON.stringify(values)) {
      return;
    }
    form.setValue("roles", values);
  };

  return (
    <div className="space-y-4">
      <Heading>New user</Heading>
      <Form {...form}>
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="bg-card rounded-lg border p-4">
            <Text size="4">Details</Text>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="john.smith@corp.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First name</FormLabel>
                  <FormControl>
                    <Input placeholder="John" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input placeholder="Smith" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <FormItem className="flex flex-row items-center space-x-2">
                <Switch
                  checked={setPassword}
                  onCheckedChange={(checked) => {
                    setSetPassword(checked);
                    if (!checked) {
                      form.setValue("password", undefined);
                    }
                  }}
                />
                <div>
                  <FormLabel>Set password</FormLabel>
                  <FormDescription>
                    Do you want to set a password, or should we send him a
                    notification to register ? He will have to change his
                    password on the first login.
                  </FormDescription>
                </div>
              </FormItem>
              {setPassword && (
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <PasswordInput
                          placeholder="Password"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
          <div className="bg-card space-y-2 rounded-lg border p-4">
            <Text size="4">Permissions</Text>
            <RolesEditWithChange
              handleChange={handleChangeRoles}
              initialRoles={currentRoles}
              possiblesRoles={[Role.OrganizationManager]}
            />
          </div>
          <div className="flex flex-row space-x-2">
            <Button type="submit">Submit</Button>
            <Button
              onClick={() => navigate(OrganizationSettingsRoutes.USERS)}
              variant={"secondary"}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
