import { Navigate } from "react-router-dom";

import { RootRoutes } from "./routes";

import { useAuth } from "../containers/hooks";

const ProtectedRoute = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const { token, parsedToken } = useAuth();

  const isMFAInvalid = parsedToken?.mfaEnabled && !parsedToken?.mfaValidated;
  if (!token || isMFAInvalid) {
    return <Navigate replace to={RootRoutes.LOGIN} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
