import { Heading } from "@radix-ui/themes";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { OrganizationRoutes } from "./organization-routes";

import { Button } from "../../components/ui/button";
import { useGetUIAuthorizedCreations } from "../../containers/user/hooks";
import { useGetMyOrganization } from "../settings/organization-settings/hooks";

export const PickInstrumentType = () => {
  const navigate = useNavigate();
  const data = useGetMyOrganization();
  const { hamiltonInstrument: canCreateHamilton, instrument: canCreateSyntax } =
    useGetUIAuthorizedCreations();
  const shouldNavigateToNewSyntax =
    data?.configuration && data?.configuration.geneEnabled === false;
  const isGeneOptionAvailable = data?.configuration.geneEnabled;

  if (shouldNavigateToNewSyntax) {
    return <Navigate to={OrganizationRoutes.INSTRUMENT_SYNTAX_NEW} />;
  }

  return (
    <div>
      <Heading size="4">Pick instrument type</Heading>
      <div className="flex w-full flex-col items-center justify-center space-y-8">
        <Button
          className="h-100 bg-card flex w-1/2 items-start justify-between space-x-4 rounded-lg border p-4"
          disabled={!canCreateSyntax}
          onClick={() => {
            navigate(OrganizationRoutes.INSTRUMENT_SYNTAX_NEW);
          }}
          variant={"ghost"}
        >
          <span className="text-xl font-bold uppercase">Syntax instrument</span>
          <p className="max-w-[300px]">The DNA Script printer</p>
        </Button>
        <Button
          className="h-100 bg-card flex w-1/2 items-start justify-between space-x-4 rounded-lg border p-4"
          disabled={!isGeneOptionAvailable || !canCreateHamilton}
          onClick={() => {
            navigate(OrganizationRoutes.HAMILTON_INSTRUMENT_NEW);
          }}
          variant={"ghost"}
        >
          <span className="text-xl font-bold uppercase">
            Hamilton liquid handler
          </span>
          <p className="max-w-[300px]">A liquid handler from Hamilton</p>
        </Button>
      </div>
    </div>
  );
};

export default function NewInstrument() {
  return (
    <div className="space-y-4">
      <Heading>Add instrument</Heading>
      <Outlet />
    </div>
  );
}
