import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import OligoSet from "./oligo-set";

import { Button } from "../../../../../components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "../../../../../components/ui/card";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { trpc } from "../../../../../config/trpc";
import type { ConstructFromTRPC } from "../constructTypes";
import { oligosSchemaWithValidation } from "../edit-construct";

const oligosFormSchema = z.object({
  oligos: oligosSchemaWithValidation,
});

type OligosForm = z.infer<typeof oligosFormSchema>;

export default function OligosEdit({
  construct,
}: {
  construct: ConstructFromTRPC;
}) {
  const form = useForm<OligosForm>({
    defaultValues: {
      oligos: construct.oligos.map((o) => ({
        id: o.id,
        locked: o.locked,
        name: o.name,
        sequence: o.sequence,
        wellHint: o.platePosition ?? null,
      })),
    },
    resolver: zodResolver(oligosFormSchema),
  });

  const utils = trpc.useUtils();
  const { mutate: updateOligosOfConstruct } =
    trpc.construct.updateOligos.useMutation({
      onSuccess() {
        utils.construct.get.invalidate({ id: construct.id });
      },
    });

  const handleUpdateConstruct = (data: OligosForm) => {
    updateOligosOfConstruct({
      constructId: construct.id,
      oligos: data.oligos,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Oligos</CardTitle>
        <CardDescription>Edit the oligos of the construct</CardDescription>
      </CardHeader>
      <Form {...form}>
        <form
          className="space-y-4"
          onSubmit={form.handleSubmit(handleUpdateConstruct)}
        >
          <CardContent className="flex flex-col space-y-4">
            <div className="flex items-center justify-end space-x-2">
              <Button type="submit">Submit</Button>
              <Button
                onClick={() => {
                  form.reset();
                }}
                type="button"
                variant={"secondary"}
              >
                Cancel
              </Button>
            </div>
            <FormField
              control={form.control}
              name="oligos"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OligoSet
                      data={field.value ?? []}
                      setData={(oligos) => {
                        const newOligos =
                          typeof oligos === "function"
                            ? oligos(field.value ?? [])
                            : oligos;
                        field.onChange(newOligos);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
        </form>
      </Form>
    </Card>
  );
}
