import { Heading } from "@radix-ui/themes";
import { Link, Outlet } from "react-router-dom";

import { Tabs, TabsList, TabsTrigger } from "../../../../components/ui/tabs";
import { useGetOrganizationProfile } from "../hooks";

const TAB_VALUES = [
  {
    label: "Members",
    value: "members",
  },
  {
    label: "Settings",
    value: "settings",
  },
  {
    label: "Support",
    value: "support",
  },
];

export default function Organization() {
  const { data, isPending } = useGetOrganizationProfile();
  const currentTab = window.location.pathname.split("/").pop() ?? "";

  if (isPending) {
    return <Heading>Loading...</Heading>;
  }

  if (!data) {
    throw new Error("Organization not found");
  }

  const { name } = data;

  return (
    <div className="space-y-4">
      <Heading>{`Organization ${name}`}</Heading>
      <Tabs className="w-[400px]" value={currentTab}>
        <TabsList>
          {TAB_VALUES.map((tab) => (
            <TabsTrigger asChild key={tab.value} value={tab.value}>
              <Link to={tab.value}>{tab.label}</Link>
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <Outlet />
    </div>
  );
}
