import { Text, Box, Flex, Heading } from "@radix-ui/themes";
import { Edit2Icon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import MemberShipList from "./components/memberships";
import { useUserData } from "./hooks";

import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import { useUser } from "../../../containers/hooks";
import { AdminRoutes } from "../adminRoutes";

export default function User() {
  const { userId } = useParams();
  const user = useUser();
  const { data, isPending, error } = useUserData(userId);
  const navigate = useNavigate();

  if (error) {
    return <Heading>User not found</Heading>;
  }

  if (isPending) {
    return <Heading>Loading...</Heading>;
  }

  if (!data) {
    return <Heading>User not found</Heading>;
  }

  const { isAdmin, email, firstname, status, lastname, memberships, name } =
    data;

  return (
    <div className="flex flex-col space-y-4">
      <Flex className="justify-between">
        <Heading>{`User ${name}`}</Heading>
        <Button
          disabled={!user?.isAdmin}
          onClick={() =>
            navigate(AdminRoutes.USER_UPDATE.replace(":userId", userId ?? ""))
          }
          variant={"secondary"}
        >
          <Edit2Icon />
        </Button>
      </Flex>
      <div className="bg-card flex flex-col space-y-2 rounded-lg border p-4">
        <Text size="4">Details</Text>
        <Box>
          <Badge>{status}</Badge>
        </Box>
        <div className="flex flex-row items-center space-x-2">
          <p className="font-bold">Email</p>
          <p>{email}</p>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <p className="font-bold">First name</p>
          <p>{firstname}</p>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <p className="font-bold">Last name</p>
          <p>{lastname}</p>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <p className="font-bold">Is admin</p>
          <Checkbox
            checked={isAdmin}
            className="border-secondary data-[state=checked]:bg-secondary"
          />
        </div>
      </div>
      <div className="bg-card rounded-lg border p-4">
        <Text size="4">Memberships</Text>
        <MemberShipList memberships={memberships} />
      </div>
    </div>
  );
}
