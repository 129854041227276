import type { HTMLProps } from "react";
import { useEffect, useRef } from "react";

import { Badge } from "../ui/badge";

export const IsActive = ({ isActive }: { isActive: boolean }) => {
  return isActive ? (
    <Badge variant={"secondary"}>Active</Badge>
  ) : (
    <Badge variant={"outline"}>Inactive</Badge>
  );
};

export const IsAdmin = ({ isAdmin }: { isAdmin: boolean }) => {
  return isAdmin ? (
    <Badge className="bg-red-400">Admin</Badge>
  ) : (
    <Badge className="bg-blue-400">Regular</Badge>
  );
};

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      className={className + " cursor-pointer"}
      ref={ref}
      type="checkbox"
      {...rest}
    />
  );
}
