import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { statusMap } from "./components/status-map";
import type { InstrumentInListTRPC } from "./useGetInstrument";

import { useBooleanSearchParam } from "../../components/search/useBooleanSearchParam";
import { Button } from "../../components/ui/button";
import { DataTable } from "../../components/ui/data-table/data-table";
import { TableActions } from "../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../components/ui/data-table/filters";
import { Label } from "../../components/ui/label";
import { SimpleTable } from "../../components/ui/simple-table";
import { Switch } from "../../components/ui/switch";
import type { AppRouter } from "../../config/trpc";
import { trpc } from "../../config/trpc";
import { useAuth } from "../../containers/hooks";
import { OrganizationRoutes } from "../organization/organization-routes";
import { useIsGeneEnabled } from "../settings/organization-settings/hooks";

const ActionCell = ({
  id,
  isHamilton,
  disabled,
}: {
  disabled: boolean;
  id: string;
  isHamilton: boolean;
}) => {
  const utils = trpc.useUtils();
  const { mutate: deleteSyntax } = trpc.instrument.syntax.delete.useMutation({
    onSuccess() {
      utils.instrument.syntax.list.invalidate();
    },
  });
  const { mutate: deleteHamilton } =
    trpc.instrument.hamilton.delete.useMutation({
      onSuccess() {
        utils.instrument.hamilton.list.invalidate();
      },
    });

  const handleDeleteInstrument = () => {
    if (isHamilton) {
      deleteHamilton({
        instrumentId: id,
      });
      return;
    }
    deleteSyntax({
      instrumentId: id,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message:
              "Are you sure you want to delete this instrument ? This action cannot be undone.",
            title: "Delete instrument",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash2 /> Delete
            </div>
          ),
          disabled,
          id: "Delete instrument",
          onClick: handleDeleteInstrument,
        },
      ]}
    />
  );
};

const columnHelper = createColumnHelper<InstrumentInListTRPC>();

const columns: ColumnDef<InstrumentInListTRPC>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "serialNumber",
    cell: ({ row }) => row.original.serialNumber,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Serial number",
    },
  },
  {
    accessorKey: "createdBy",
    cell: ({ row }) => row.original.createdBy,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Owner",
    },
  },
  {
    accessorKey: "softwareVersion",
    cell: ({ row }) => row.original.softwareVersion,
    header: "Software",
  },
  {
    accessorKey: "status",
    cell: ({ row }) => statusMap[row.original.status],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Status",
      uniqueFilter: true,
    },
  },
  columnHelper.display({
    cell: ({ row }) => (
      <ActionCell
        disabled={!row.original.canEdit}
        id={row.original.id}
        isHamilton={false}
      />
    ),
    enableHiding: false,
    id: "actions",
  }),
];

type HamiltonFromTRPC = inferProcedureOutput<
  AppRouter["instrument"]["hamilton"]["list"]
>[number];
const hamiltonTableColumnHelper = createColumnHelper<HamiltonFromTRPC>();

const hamiltonColumns = [
  hamiltonTableColumnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  hamiltonTableColumnHelper.accessor("createdAt", {
    cell: (info) => info.getValue(),
    header: "Creation date",
  }),
  hamiltonTableColumnHelper.display({
    cell: ({ row }) => (
      <ActionCell
        disabled={!row.original.canEdit}
        id={row.original.id}
        isHamilton
      />
    ),
    id: "actions",
  }),
];

const SyntaxInstruments = () => {
  const navigate = useNavigate();
  const { parsedToken } = useAuth();
  const organizationId = parsedToken?.organizationId;
  const { data: instruments } = trpc.instrument.syntax.list.useQuery(
    {
      organizationId: organizationId ?? "",
    },
    {
      enabled: !!organizationId,
    },
  );

  return (
    <DataTable
      columns={columns}
      data={instruments ?? []}
      onRowClick={(row) =>
        navigate(
          OrganizationRoutes.INSTRUMENT.replace(
            ":instrumentId",
            row.original.id,
          ),
        )
      }
    />
  );
};

const HamiltonInstruments = () => {
  const navigate = useNavigate();
  const { data: hamiltonData } = trpc.instrument.hamilton.list.useQuery();

  const hamiltonTable = useReactTable({
    columns: hamiltonColumns,
    data: hamiltonData ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="bg-card w-full rounded-md border shadow-md">
      <SimpleTable
        onRowClick={(row) =>
          navigate(
            OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
              ":instrumentId",
              row.original.id,
            ),
          )
        }
        table={hamiltonTable}
      />
    </div>
  );
};

export default function InstrumentHome() {
  const navigate = useNavigate();
  const {
    setSwitchValue: setShowHamiltonInstruments,
    isTrue: showHamiltonInstruments,
  } = useBooleanSearchParam("hamilton");
  const isGeneEnabled = useIsGeneEnabled();

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <Heading>Instruments</Heading>
        <Button
          className="space-x-1"
          onClick={() => {
            navigate(OrganizationRoutes.INSTRUMENT_NEW);
          }}
        >
          <span>Add instrument</span>
        </Button>
      </div>
      {isGeneEnabled && (
        <>
          <div className="mb-4 flex flex-row items-center space-x-2">
            <Label htmlFor="show-hamilton-instruments">
              Hamilton instruments
            </Label>
            <Switch
              checked={showHamiltonInstruments}
              id="show-hamilton-instruments"
              onCheckedChange={setShowHamiltonInstruments}
            />
          </div>
        </>
      )}
      {showHamiltonInstruments ? (
        <HamiltonInstruments />
      ) : (
        <SyntaxInstruments />
      )}
    </div>
  );
}
