import { Box, Heading } from "@radix-ui/themes";
import { Link, Outlet } from "react-router-dom";

import { ADMIN_TABS } from "./adminRoutes";

import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";

export default function Admin() {
  const currentTab = window.location.pathname.split("/").pop();

  return (
    <Box className="flex flex-col">
      <Box className="mb-4 flex flex-row justify-between">
        <Heading>Admin page</Heading>
        <Tabs className="bg-card rounded-lg" defaultValue={currentTab}>
          <TabsList aria-label="Manage your account">
            {ADMIN_TABS.map((tab) => (
              <TabsTrigger
                asChild
                key={tab.id}
                value={tab.path.split("/").pop() ?? ""}
              >
                <Link to={tab.path}>{tab.link}</Link>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
}
