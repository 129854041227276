import { Navigate } from "react-router-dom";

import { RootRoutes } from "./routes";

import DnaValidation from "../components/ui/icons/dnaValidation.svg?react";
import Freezer from "../components/ui/icons/freezer.svg?react";
import KitHighFidelity from "../components/ui/icons/kitHF.svg?react";
import Kit384 from "../components/ui/icons/kitHX.svg?react";
import KitStandard from "../components/ui/icons/kitStandard.svg?react";
import KitStandardLabels from "../components/ui/icons/kitStandardLabels.svg?react";
import Micropipette from "../components/ui/icons/micropipette.svg?react";
import Plate384 from "../components/ui/icons/plate384.svg?react";
import Plate96 from "../components/ui/icons/plate96.svg?react";
import Qpcr from "../components/ui/icons/qPCR.svg?react";
import SingleStrand from "../components/ui/icons/singleStranded.svg?react";
import Syntax from "../components/ui/icons/syntax.svg?react";
import Tube from "../components/ui/icons/tube.svg?react";
import { useIsDevelopment } from "../containers/contexts";

export default function Icons() {
  const isDevelopment = useIsDevelopment();
  if (!isDevelopment) {
    return <Navigate to={RootRoutes.ROOT} />;
  }

  return (
    <div className="grid h-screen w-screen grid-cols-4 gap-3">
      <div className="col-span-1">
        <Plate96 />
      </div>
      <div className="col-span-1">
        <Plate384 />
      </div>
      <div className="col-span-1">
        <Tube />
      </div>
      <div className="col-span-1">
        <Micropipette />
      </div>
      <div className="col-span-1">
        <SingleStrand />
      </div>
      <div className="col-span-1">
        <DnaValidation />
      </div>
      <div className="col-span-1">
        <Freezer />
      </div>
      <div className="col-span-1">
        <Qpcr />
      </div>
      <div className="col-span-1">
        <Kit384 />
      </div>
      <div className="col-span-1">
        <KitHighFidelity />
      </div>
      <div className="col-span-1">
        <KitStandard />
      </div>
      <div className="col-span-1">
        <KitStandardLabels />
      </div>
      <div className="col-span-1">
        <Syntax className="max-h-full max-w-full" />
      </div>
    </div>
  );
}

export {
  DnaValidation,
  Freezer,
  KitHighFidelity,
  Kit384,
  KitStandard,
  KitStandardLabels,
  Micropipette,
  Plate384,
  Plate96,
  Qpcr,
  SingleStrand,
  Syntax,
  Tube,
};
