import { ArrowDown } from "lucide-react";

import DownloadReport from "./components/download-report";
import type { PlateRun } from "./components/plate/types";
import ReportMetrics from "./components/report-metrics";

import { Button } from "../../../../components/ui/button";
import { getPercentage } from "../../../../utils/number";
import { getFormattedTimeFromS } from "../../../../utils/time";
import { RunProgressBar } from "../../../components/runProgress/runProgress";

type Progress = NonNullable<PlateRun["progress"]>;

const ProgressComponent = ({ progress }: { progress: Progress }) => {
  const { currentPhaseIndex } = progress;
  const phasesWithTotalTime = progress.phases.map((phase, index) => {
    const remainingTime = phase.estimatedRemainingTime.seconds;
    let totalTime = remainingTime;
    if (index <= currentPhaseIndex) {
      const alreadySpentTime =
        (new Date().getTime() - new Date(phase.startTime).getTime()) / 1000;
      totalTime = remainingTime + alreadySpentTime;
    }
    return {
      ...phase,
      totalTime,
    };
  });
  const currentPhase = phasesWithTotalTime[currentPhaseIndex];
  if (!currentPhase) {
    return null;
  }
  const elapsedTime =
    currentPhase.totalTime - currentPhase.estimatedRemainingTime.seconds;
  const currentProgress = getPercentage(currentPhase.totalTime, elapsedTime);
  const totalTime = phasesWithTotalTime.reduce(
    (acc, p) => acc + p.totalTime,
    0,
  );
  const totalRemaining = phasesWithTotalTime.reduce(
    (acc, p) => acc + p.estimatedRemainingTime.seconds,
    0,
  );
  const totalProgress = getPercentage(totalTime, totalTime - totalRemaining);

  return (
    <div className="flex w-full flex-col">
      <div className="flex aspect-square max-h-[400px] flex-1 justify-center">
        <RunProgressBar
          currentStep={currentPhaseIndex}
          progress={currentProgress}
          showStepProgress
          stepName={currentPhase.label}
          stepsLength={progress.phases.length}
          totalProgress={totalProgress}
        />
      </div>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-col items-center">
          <p className="font-bold">{totalProgress}%</p>
          <p>Job progress</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-bold">{getFormattedTimeFromS(totalRemaining)}</p>
          <p>Time remaining</p>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-bold">
            {getFormattedTimeFromS(elapsedTime, true)}
          </p>
          <p>Elapsed time</p>
        </div>
      </div>
    </div>
  );
};

export default function RunProgress({ run }: { run: PlateRun }) {
  const { progress, washStep, id } = run;

  const report = run?.report;

  return (
    <div className="flex w-full">
      {washStep && (
        <p className="space-x-1">
          <span>Washing</span>
          <span>{washStep}</span>
        </p>
      )}
      {progress && <ProgressComponent progress={progress} />}
      {report && (
        <div className="flex flex-col space-y-2">
          <ReportMetrics metrics={report.metrics} />
          <div className="flex flex-row space-x-2">
            <DownloadReport runId={id} />
            <Button
              onClick={() => {
                document
                  .getElementById("run-reagents")
                  ?.scrollIntoView({ behavior: "instant" });
              }}
              size={"sm"}
              variant={"ghost"}
            >
              <ArrowDown />
              <span>Go to consumables</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
