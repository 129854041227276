import type { FC } from "react";
import { useEffect, useRef } from "react";

import { drawRunProgress } from "./drawer";

export interface RunProgressBarProps {
  currentStep?: number;
  mainTextColor?: string;
  mainTextWeight?: "bold" | "normal";
  progress?: number;
  progressBgColor?: string;
  showStepProgress?: boolean;
  stepName?: string;
  stepProgressColor?: string;
  stepTextColor?: string;
  stepsLength?: number;
  totalProgress?: number;
  totalProgressColor?: string;
}

export const RunProgressBar: FC<RunProgressBarProps> = ({
  currentStep = 0,
  stepName = "",
  stepsLength = 0,
  progress = 0,
  totalProgress = 0,
  showStepProgress = false,
  totalProgressColor,
  mainTextColor,
  mainTextWeight,
  progressBgColor,
  stepProgressColor,
  stepTextColor,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    let callback = () => {};
    if (canvasRef.current instanceof HTMLCanvasElement) {
      const context = canvasRef.current.getContext("2d");

      if (context) {
        const parentNode = canvasRef.current.parentNode as HTMLElement | null;

        if (parentNode) {
          const canvas = canvasRef.current;

          callback = () => {
            const size = Math.min(
              parentNode.clientHeight,
              parentNode.clientWidth,
            );
            canvas.width = size;
            canvas.height = size;
            drawRunProgress(context, {
              currentStep,
              fullPercent: Math.min(totalProgress, 100),
              mainTextColor: mainTextColor,
              mainTextWeight,
              percent: Math.min(progress, 100),
              progressBgColor: progressBgColor,
              showStepProgress,
              size,
              stepName,
              stepProgressColor: stepProgressColor,
              stepTextColor: stepTextColor,
              stepsLength,
              totalProgressColor: totalProgressColor,
            });
          };

          callback();

          window.addEventListener("resize", callback);
        }
      }
    }

    return () => {
      window.removeEventListener("resize", callback);
    };
  }, [
    currentStep,
    progress,
    showStepProgress,
    stepsLength,
    stepName,
    totalProgress,
    totalProgressColor,
    mainTextColor,
    mainTextWeight,
    progressBgColor,
    stepProgressColor,
    stepTextColor,
  ]);

  return <canvas data-testid={"progress"} ref={canvasRef} />;
};
