import {
  minGeneLength,
  maxGeneLength,
  maxGenes,
  minOligoLength,
  maxOligoLength,
} from "@backend/config/clientRequest/requestFormSchema";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

export default function RequestInstructions() {
  return (
    <Accordion collapsible type="single">
      <AccordionItem value="instructions">
        <AccordionTrigger>Instructions</AccordionTrigger>
        <AccordionContent className="space-y-2">
          <ol className="list-decimal pl-4">
            <li>
              Copy-paste or upload you oligos or gene fragment sequences using
              templates below (.csv, .fasta, .excel)
            </li>
            <li>
              {`Check that your sequences are within DNA Script's specifications (see below) by clicking the "Check sequences" button.`}
              If an error is flagged, modify sequence accordingly.
            </li>
            <li>
              {`Once all your sequences are validated, download the excel request
              form by clicking on "Download excel request" and send it to your
              sales representative to get a quote.`}
            </li>
          </ol>
          <p>
            <h4 className="font-semibold">Gene fragment specifications</h4>
            <ul className="pl-4">
              <li>{`Sequence length range accepted: ${minGeneLength}bp - ${maxGeneLength / 1000}kbp`}</li>
              <li>{`Fewer than ${maxGenes} gene fragments per order`}</li>
              <li>Only DNA (no uracil, no amino acids)</li>
              <li>No degenerate bases accepted</li>
            </ul>
          </p>
          <p>
            <h4 className="font-semibold">Oligos specifications</h4>
            <ul className="pl-4">
              <li>{`Sequence length range accepted: ${minOligoLength}bp - ${maxOligoLength}bp`}</li>
              <li>{`Fewer than ${maxOligoLength} oligos per order`}</li>
              <li>{"Only DNA (no uracil, no amino acids)"}</li>
              <li>{"Up to 2 degenerate bases accepted"}</li>
            </ul>
          </p>
          <p>
            You can either manually enter the sequences or upload file(s)
            (download file templates below).
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
