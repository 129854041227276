import { Heading } from "@radix-ui/themes";
import { UserPlus } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components/ui/button";
import { useGetMembersOfOrganization } from "../../../settings/organization-settings/hooks";
import { OrganizationMembersTable } from "../../../settings/organization-settings/organization-members";
import { AdminRoutes } from "../../adminRoutes";
import { useGetOrganizationProfile } from "../hooks";

export default function OrganizationMembers() {
  const { data: organization, isPending } = useGetOrganizationProfile();
  const members = useGetMembersOfOrganization(organization?.id ?? "");
  const navigate = useNavigate();

  if (isPending) {
    return <Heading>Loading...</Heading>;
  }

  if (!organization) {
    throw new Error("Organization not found");
  }

  return (
    <div className="bg-card space-y-2 rounded-lg border p-4">
      <div className="flex justify-between">
        <h3 className="font-bold">Members</h3>
        <Button
          aria-label="add-to-organization"
          onClick={() => {
            navigate(
              AdminRoutes.ORGANIZATION_MEMBERS_INVITE.replace(
                ":organizationId",
                organization.id,
              ),
            );
          }}
        >
          <UserPlus />
        </Button>
      </div>
      <OrganizationMembersTable members={members} />
    </div>
  );
}
