import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { Download } from "lucide-react";

import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { useToast } from "../../../../../components/ui/use-toast";
import type { AppRouter } from "../../../../../config/trpc";
import { trpc } from "../../../../../config/trpc";
import { downloadBlob } from "../../../../../utils/useDownload";
import { CheckIfTrueElseCross } from "../../../../admin/organizations/components/ui";

type DesignTRPC = inferProcedureOutput<
  AppRouter["assay"]["steps"]["gene"]["listDesigns"]
>[0];

const DeleteDesignButton = ({ design }: { design: DesignTRPC }) => {
  const { toast } = useToast();

  const utils = trpc.useUtils();
  const { mutate: deleteDesign } =
    trpc.assay.steps.gene.removeDesign.useMutation({
      onSuccess() {
        toast({
          description: "Design deleted",
          title: "Success",
          variant: "success",
        });
        utils.construct.get.invalidate();
        utils.assay.steps.gene.listDesigns.invalidate();
      },
    });

  const handleClick = () => {
    deleteDesign({
      designId: design.id,
      geneId: design.geneId,
    });
  };

  return (
    <Button onClick={handleClick} type="button" variant={"outline"}>
      Delete
    </Button>
  );
};

const DownloadDesignButton = ({ design }: { design: DesignTRPC }) => {
  const { toast } = useToast();

  const { mutate: downloadDesign } =
    trpc.assay.steps.gene.downloadDesign.useMutation({
      onSuccess(data) {
        toast({
          description: "Design downloaded",
          title: "Success",
          variant: "success",
        });
        const blob = new Blob([data], { type: "text/tab-separated-values" });
        downloadBlob(blob, `${design.name}.tsv`);
      },
    });

  const handleClick = () => {
    downloadDesign({
      designId: design.id,
      geneId: design.geneId,
    });
  };

  return (
    <Button
      className="flex flex-row items-center space-x-1"
      onClick={handleClick}
      type="button"
      variant={"outline"}
    >
      <span>Download</span>
      <Download />
    </Button>
  );
};

const designColumns: ColumnDef<DesignTRPC>[] = [
  {
    accessorFn: (row) => row.name,
    cell: (info) => info.getValue(),
    header: "Name",
  },
  {
    accessorFn: (row) => row.createdAt,
    cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
    header: "Created at",
  },
  {
    accessorKey: "createdBy",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "createdBy",
    meta: {
      title: "Creator",
    },
  },
  {
    accessorKey: "metrics.numberOfBlocks",
    cell: (info) => info.getValue(),
    header: "Number of blocks",
  },
  {
    accessorKey: "metrics.numberOfOligos",
    cell: (info) => info.getValue(),
    header: "Number of oligos",
  },
  {
    accessorKey: "parameters.subblock_size",
    cell: (info) => info.getValue(),
    header: "Subblock size",
  },
  {
    accessorKey: "parameters.overlap_subblocks",
    cell: (info) => info.getValue(),
    header: "Subblock overlap",
  },
  {
    accessorKey: "parameters.oligo_size",
    cell: (info) => info.getValue(),
    header: "Oligo size",
  },
  {
    cell: ({ row }) => (
      <span>
        {row.original.parameters.lower_tm_threshold}-
        {row.original.parameters.upper_tm_threshold}
      </span>
    ),
    header: "TM",
  },
  {
    accessorKey: "parameters.priority",
    cell: (info) => info.getValue(),
    header: "Priority",
  },
  {
    accessorKey: "parameters.n_2_design",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "n-2 design",
  },
  {
    accessorKey: "parameters.add_stubs",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "Add stubs",
  },
  {
    accessorKey: "parameters.use_error_correction",
    cell: (info) => <CheckIfTrueElseCross value={info.getValue() as boolean} />,
    header: "Use error correction",
  },
  {
    cell: ({ row }) => {
      const design = row.original;
      return (
        <TableActions
          items={[
            {
              children: <DeleteDesignButton design={design} />,
              id: "Delete",
            },
            {
              children: <DownloadDesignButton design={design} />,
              id: "Download",
            },
          ]}
        />
      );
    },
    enableHiding: false,
    id: "actions",
  },
];

export default function OldDesigns({
  geneId,
}: {
  constructId: string;
  geneId: string;
}) {
  const { data: designs, isPending: isDesignListPending } =
    trpc.assay.steps.gene.listDesigns.useQuery({
      geneId,
    });

  return (
    <DataTable
      columns={designColumns}
      data={designs ?? []}
      disableQueryParams
      loading={isDesignListPending}
    />
  );
}
