import { useLocation } from "react-router-dom";

export default function useLocationAssayId(): string | undefined {
  const location = useLocation();
  return (
    location.state as {
      assayId?: string;
    }
  )?.assayId;
}
