import { ExternalLinkIcon } from "lucide-react";
import { useBlocker } from "react-router-dom";

import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";

export default function LeaveWarning({
  shouldDisplayWarning,
}: {
  shouldDisplayWarning: boolean;
}) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldDisplayWarning &&
      currentLocation.pathname !== nextLocation.pathname,
  );
  const isBlocked = blocker.state === "blocked";

  const handleConfirmLeave = () => {
    blocker.proceed?.();
  };

  const handleCancelLeave = () => {
    blocker.reset?.();
  };

  if (!isBlocked) {
    return null;
  }

  return (
    <AlertDialogWrapper
      description={`Are you sure you want to quit this page ? You have unsaved work`}
      isOpen
      onCancel={handleCancelLeave}
      onConfirm={handleConfirmLeave}
      title={`Unsaved work`}
    >
      <div className="flex flex-row items-center space-x-1">
        <ExternalLinkIcon />
        <span>Archive</span>
      </div>
    </AlertDialogWrapper>
  );
}
