import { Heading } from "@radix-ui/themes";
import { Link, Navigate, Outlet } from "react-router-dom";

import { useGetMyOrganization } from "./hooks";
import { ORGANIZATION_SETTINGS_TABS } from "./organization-settings-routes";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { useUser } from "../../../containers/hooks";

export default function OrganizationSettings() {
  const user = useUser();
  const data = useGetMyOrganization();
  const organizationName = data?.name;
  const currentTab = window.location.pathname.split("/").pop();

  const isManager = user?.membership?.isManager ?? false;

  if (!isManager) {
    return <Navigate to="/organization" />;
  }

  return (
    <div className="space-y-8">
      <div className="flex flex-row justify-between">
        <Heading>{`Organization ${organizationName}`}</Heading>
        <Tabs defaultValue={currentTab}>
          <TabsList>
            {ORGANIZATION_SETTINGS_TABS.map((tab) => (
              <TabsTrigger asChild key={tab.id} value={tab.id}>
                <Link to={tab.path}>{tab.link}</Link>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>
      <Outlet />
    </div>
  );
}
