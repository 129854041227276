import { Heading } from "@radix-ui/themes";
import { ReactFlowProvider } from "reactflow";

import WorkflowGraph from "./components/workflow/workflow-graph";

import type { AssayTRPC } from "../../../../config/trpc";
import WorkflowBuild from "../build/new-workflow/workflow-build";
import { WorkflowBuildProvider } from "../build/new-workflow/workflow-build-context";

export default function Workflow({ assay }: { assay: AssayTRPC }) {
  return (
    <div className="bg-card space-y-2 rounded-lg border p-4">
      <Heading size="4">Workflow</Heading>
      <WorkflowBuildProvider assayId={assay.id}>
        <WorkflowBuild assay={assay} />
        <ReactFlowProvider>
          <WorkflowGraph workflow={assay.workflow} />
        </ReactFlowProvider>
      </WorkflowBuildProvider>
    </div>
  );
}
