import { useState, useEffect } from "react";

import { Input } from "./input";

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  Component = Input,
  ...props
}: {
  Component?:
    | React.ComponentType<React.InputHTMLAttributes<HTMLInputElement>>
    | React.ComponentType<React.TextareaHTMLAttributes<HTMLTextAreaElement>>;
  debounce?: number;
  onChange: (value: string | number) => void;
  value: string | number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    // @ts-expect-error Component can either be an input or a textarea
    <Component
      {...props}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}
DebouncedInput.displayName = "DebouncedInput";

export { DebouncedInput };
