import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import { UserPlus2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import ActionsCell from "./components/organization-member-action-cell";
import type { OrganizationMemberInOrganization } from "./hooks";
import {
  useCanInviteUser,
  useGetMembersOfOrganization,
  useGetMyOrganization,
} from "./hooks";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { IsActive } from "../../../components/table/basic";
import { Button } from "../../../components/ui/button";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import {
  Tooltip,
  TooltipButtonWrapper,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { CheckIfTrueElseWarn } from "../../admin/organizations/components/ui";

const columns: ColumnDef<OrganizationMemberInOrganization>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => row.original.name,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "email",
    cell: ({ row }) => row.original.email,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Email",
    },
  },
  {
    accessorFn: (row) => row.active,
    cell: ({ row }) => <IsActive isActive={row.original.active} />,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "isActive",
    meta: {
      booleanFilter: true,
      title: "Active ?",
    },
  },
  {
    accessorKey: "user.mfaActivated",
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger>
          <CheckIfTrueElseWarn value={row.original.mfaActivated} />
        </TooltipTrigger>
        <TooltipContent>
          {row.original.mfaActivated
            ? "MFA is activated"
            : "User has no MFA setup"}
        </TooltipContent>
      </Tooltip>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Has MFA",
    },
  },
  {
    cell: ({ row }) => <ActionsCell member={row.original} />,
    enableHiding: false,
    id: "actions",
  },
];

export function OrganizationMembersTable({
  onRowClick,
  members,
}: {
  members: OrganizationMemberInOrganization[];
  onRowClick?: (row: { original: OrganizationMemberInOrganization }) => void;
}) {
  return <DataTable columns={columns} data={members} onRowClick={onRowClick} />;
}

export default function OrganizationMembers() {
  const data = useGetMyOrganization();
  const navigate = useNavigate();
  const canInviteUser = useCanInviteUser();

  const members = useGetMembersOfOrganization(data?.id ?? "");

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center justify-between">
        <Heading>Users</Heading>
        <TooltipButtonWrapper
          tooltipContent={
            canInviteUser
              ? "Invite a new user to the organization"
              : "Not enough rights to invite user"
          }
        >
          <Button
            onClick={() => {
              if (!canInviteUser) return;
              navigate(OrganizationSettingsRoutes.NEW_MEMBER);
            }}
          >
            <UserPlus2 aria-label="new-organization-user" />
          </Button>
        </TooltipButtonWrapper>
      </div>
      <OrganizationMembersTable
        members={members}
        onRowClick={(row) => {
          navigate(
            OrganizationSettingsRoutes.USER.replace(":userId", row.original.id),
          );
        }}
      />
    </div>
  );
}
