import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { BookPlus, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { RoleList } from "../../../components/logic/roles";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { SimpleTable } from "../../../components/ui/simple-table";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import type { AppRouter } from "../../../config/trpc";
import { trpc } from "../../../config/trpc";
import { useUser } from "../../../containers/hooks";
import { useGetUIAuthorizedCreations } from "../../../containers/user/hooks";

type TeamFromQuery = inferProcedureOutput<
  AppRouter["account"]["team"]["list"]
>[0];

const TeamActions = ({ team }: { team: TeamFromQuery }) => {
  const { mutate: deleteTeam } = trpc.account.team.delete.useMutation();

  const handleDeleteTeam = () => {
    deleteTeam({
      id: team.id,
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message: `Are you sure you want to delete ${team.name} team? This action cannot be undone.`,
            title: `Delete ${team.name}`,
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash /> Delete
            </div>
          ),
          id: "delete",
          onClick: handleDeleteTeam,
        },
      ]}
    />
  );
};

const columnHelper = createColumnHelper<TeamFromQuery>();

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    header: "Created at",
  }),
  columnHelper.accessor("updatedAt", {
    cell: (info) => new Date(info.getValue()).toLocaleString(),
    header: "Updated at",
  }),
  columnHelper.accessor("roles", {
    cell: (info) => <RoleList roles={info.getValue()} />,
    header: "Authorizations",
  }),
  columnHelper.display({
    cell: ({ row }) => <TeamActions team={row.original} />,
    id: "actions",
  }),
];

export default function Teams() {
  const user = useUser();
  const { team: canCreateTeam } = useGetUIAuthorizedCreations();
  const navigate = useNavigate();
  const organizationId = user?.membership?.organizationId;
  const { data: teams, isPending } = trpc.account.team.list.useQuery(
    undefined,
    {
      enabled: !!organizationId,
    },
  );

  const table = useReactTable({
    columns,
    data: teams ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader className="flex flex-row justify-between">
          <CardTitle>Teams</CardTitle>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                disabled={!canCreateTeam}
                onClick={() => {
                  navigate(OrganizationSettingsRoutes.NEW_TEAM);
                }}
              >
                <BookPlus />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Create a new team</TooltipContent>
          </Tooltip>
        </CardHeader>
        <CardContent className="space-y-2">
          {isPending ? (
            "Loading..."
          ) : (
            <SimpleTable
              onRowClick={(row) =>
                navigate(
                  OrganizationSettingsRoutes.TEAM.replace(
                    ":teamId",
                    row.original.id,
                  ),
                )
              }
              table={table}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
