import { zodResolver } from "@hookform/resolvers/zod";
import { Text } from "@radix-ui/themes";
import { PlusIcon, Trash } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import type { AllowedProcess } from "./useGetEnvironmentKits";
import { useGetEnvironmentKits } from "./useGetEnvironmentKits";

import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/ui/form";
import { Input } from "../../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { Switch } from "../../../../../components/ui/switch";

// eslint-disable-next-line react-refresh/only-export-components
export const organizationFormSchema = z.object({
  bioSecurityEnabled: z.boolean(),
  clicksEnabled: z.boolean(),
  customProcessEnabled: z.boolean(),
  degenerateBasesEnabled: z.boolean(),
  geneEnabled: z.boolean(),
  isActive: z.boolean(),
  kitConfiguration: z.array(
    z.object({
      kit: z.string(),
      maximumMersError: z.number().min(1).max(2000),
      maximumMersWarning: z.number().min(1).max(2000),
      maximumModifiersPlate: z.number(),
      maximumModifiersWell: z.number(),
      minimumMersError: z.number().min(1).max(2000),
      minimumMersWarning: z.number().min(1).max(2000),
      processes: z.array(z.string()),
    }),
  ),
  mfaMandatory: z.boolean(),
  modsEnabled: z.boolean(),
  name: z.string().trim().min(2).max(100),
  notificationsEnabled: z.boolean(),
  remoteRunTriggerEnabled: z.boolean(),
  serviceEnabled: z.boolean(),
});

export type OrganizationForm = z.infer<typeof organizationFormSchema>;

type OrganizationEditConfigurationProps = {
  defaultValues?: Partial<OrganizationForm>;
  handleSubmit: (values: OrganizationForm) => void;
  onCancel: () => void;
};

const DefaultOrganizationValues: OrganizationForm = {
  bioSecurityEnabled: false,
  clicksEnabled: false,
  customProcessEnabled: false,
  degenerateBasesEnabled: false,
  geneEnabled: false,
  isActive: false,
  kitConfiguration: [],
  mfaMandatory: false,
  modsEnabled: false,
  name: "",
  notificationsEnabled: false,
  remoteRunTriggerEnabled: false,
  serviceEnabled: false,
};

export const OrganizationEditConfiguration = ({
  onCancel,
  handleSubmit,
  defaultValues,
}: OrganizationEditConfigurationProps) => {
  const [kitToAdd, setKitToAdd] = useState<string | null>(null);

  const kits = useGetEnvironmentKits();
  const kitToInfo = kits.reduce(
    (acc, kit) => {
      acc[kit.id] = {
        defaultProcesses: kit.defaultProcessIds,
        label: kit.label,
        processes: kit.allowedProcesses,
      };
      return acc;
    },
    {} as Record<
      string,
      { defaultProcesses: string[]; label: string; processes: AllowedProcess[] }
    >,
  );

  const form = useForm<OrganizationForm>({
    defaultValues: DefaultOrganizationValues,
    resolver: zodResolver(organizationFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "kitConfiguration",
  });

  useEffect(() => {
    form.reset({ ...DefaultOrganizationValues, ...defaultValues });
  }, [defaultValues, form]);

  const selectedKits = form.watch("kitConfiguration");
  const remainingKits: string[] = useMemo(
    () =>
      kits
        .filter(
          (kit) =>
            selectedKits.length === 0 ||
            !selectedKits.map((k) => k.kit).includes(kit.id),
        )
        .map((k) => k.id),
    [kits, selectedKits],
  );

  useEffect(() => {
    const kitToAdd = remainingKits?.[0];
    if (!kitToAdd) {
      return;
    }
    setKitToAdd(kitToAdd);
  }, [remainingKits]);

  const errorMessage = form.formState.errors.root?.["graphql"]?.message;

  function onSubmit(values: OrganizationForm) {
    handleSubmit(values);
  }

  return (
    <div className="bg-card p-4">
      <Form {...form}>
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input placeholder="Corporation" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="space-y-2 rounded-lg border p-4">
            <FormField
              control={form.control}
              name="isActive"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Active ?</FormLabel>
                    <FormDescription>
                      Activate or deactivate organization
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="clicksEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Clicks enabled</FormLabel>
                    <FormDescription>
                      Allow clicks during printing
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="customProcessEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Custom processes enabled</FormLabel>
                    <FormDescription>
                      Custom processes description.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="degenerateBasesEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Degenerate bases enabled</FormLabel>
                    <FormDescription>
                      Allow to print degenerate bases.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="geneEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Genes enabled</FormLabel>
                    <FormDescription>
                      Allow to synthesize genes with our own workflow.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="modsEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Modifiers enabled</FormLabel>
                    <FormDescription>
                      Modifiers (non click) enabled.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="notificationsEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Notifications enabled</FormLabel>
                    <FormDescription>
                      Allow to send (email, sms) notifications. Needs to be
                      configured somewhere.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="remoteRunTriggerEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Remote run trigger enabled</FormLabel>
                    <FormDescription>
                      Allows to create instruments whose runs can be triggered
                      directly from the UI. Useful when you have different teams
                      loading runs and triggering runs.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bioSecurityEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Biosecurity enabled</FormLabel>
                    <FormDescription>
                      When saving plates, screen each sequence. The system will
                      prevent the organization from printing hazardous material.
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mfaMandatory"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>MFA enabled</FormLabel>
                    <FormDescription>
                      Multi factor authentication is required for all users
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="serviceEnabled"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Service enabled</FormLabel>
                    <FormDescription>
                      Does the organization support service workflows ?
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4 rounded-lg border p-4">
            <FormLabel>Kit configuration</FormLabel>
            <ul className="space-y-4">
              {fields.map((item, index) => (
                <li className="space-y-3 rounded-xl border p-2" key={item.id}>
                  <div className="flex flex-row items-center space-x-4">
                    <Text>{kitToInfo[item.kit]?.label}</Text>
                    <Button onClick={() => remove(index)} size="sm">
                      <Trash />
                    </Button>
                  </div>
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.maximumMersError`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Maximum number of mers</FormLabel>
                        <FormDescription>
                          An error will be raised if it is higher
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.maximumMersWarning`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Maximum number of mers</FormLabel>
                        <FormDescription>
                          A warning will be raised if it is higher
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.minimumMersError`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Minimum number of mers</FormLabel>
                        <FormDescription>
                          An error will be raised if it is lower
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.minimumMersWarning`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Minimum number of mers</FormLabel>
                        <FormDescription>
                          A warning will be raised if it is lower
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.maximumModifiersPlate`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Maximum modifiers per plate</FormLabel>
                        <FormDescription>
                          The maximum number of modifiers that can exist in a
                          plate
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.maximumModifiersWell`}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center space-x-2">
                        <FormControl className="mt-0 w-16">
                          <Input
                            type="number"
                            {...field}
                            onChange={(event) =>
                              field.onChange?.(parseInt(event.target.value, 10))
                            }
                          />
                        </FormControl>
                        <FormLabel>Maximum modifiers per well</FormLabel>
                        <FormDescription>
                          The maximum number of modifiers that can exist in a
                          well
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`kitConfiguration.${index}.processes`}
                    render={({ field }) => {
                      const kitProcesses =
                        kitToInfo?.[item.kit]?.processes ?? [];
                      const hasOneKit = kitProcesses.length <= 1;

                      return (
                        <FormItem className="flex flex-row items-center space-x-2">
                          <FormLabel>Allowed processes</FormLabel>
                          <div className="flex flex-row space-x-2">
                            {kitProcesses.map((process) => {
                              const checked =
                                field.value &&
                                field.value?.includes(process.process);
                              const disabled = hasOneKit && checked;
                              return (
                                <div
                                  className="flex flex-row items-center space-x-1"
                                  key={process.process}
                                >
                                  <FormControl>
                                    <Checkbox
                                      checked={checked}
                                      disabled={disabled}
                                      id={process.process}
                                      onCheckedChange={(checked) => {
                                        return checked
                                          ? field.onChange([
                                              ...field.value,
                                              process.process,
                                            ])
                                          : field.onChange(
                                              field.value?.filter(
                                                (value) =>
                                                  value !== process.process,
                                              ),
                                            );
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel htmlFor={process.process}>
                                    {process.label}
                                  </FormLabel>
                                </div>
                              );
                            })}
                          </div>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                </li>
              ))}
            </ul>
            <div className="flex flex-row items-center space-x-2">
              <Button
                disabled={kitToAdd === null}
                onClick={() => {
                  if (!kitToAdd) {
                    return;
                  }
                  const kit = kits.find((k) => k.id === kitToAdd);
                  if (!kit) {
                    return;
                  }

                  const newKit = {
                    kit: kitToAdd,
                    maximumMersError: kit.maximumMersError,
                    maximumMersWarning: kit.maximumMersWarning,
                    maximumModifiersPlate: kit.maximumModifiersPlate,
                    maximumModifiersWell: kit.maximumModifiersWell,
                    minimumMersError: kit.minimumMersError,
                    minimumMersWarning: kit.minimumMersWarning,
                    processes: kitToInfo[kitToAdd].defaultProcesses,
                  };
                  append(newKit);
                  setKitToAdd(null);
                }}
              >
                <PlusIcon className="mr-1" />
                Add a kit
              </Button>
              <Select
                defaultValue={kitToAdd ?? undefined}
                disabled={remainingKits.length === 0}
                onValueChange={setKitToAdd}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      kitToAdd
                        ? kitToInfo[kitToAdd]?.label
                        : "No kits remaining"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {remainingKits.map((kit) => (
                    <SelectItem key={kit} value={kit}>
                      {kitToInfo[kit]?.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <Button type="submit">Submit</Button>
            <Button onClick={onCancel} variant={"secondary"}>
              Cancel
            </Button>
          </div>

          {errorMessage && (
            <div>
              <Text className="text-xl text-red-600">{errorMessage}</Text>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};
